import React, { ReactNode, createContext, useState } from "react";

import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { AlertColor } from "@mui/material/Alert/Alert";

interface GlobalSnackBarProps {
  showSnackbar: (message: string, type?: AlertColor) => void;
}

interface GlobalSnackBarProviderProps {
  children: ReactNode;
}

export const GlobalSnackbarContext = createContext<GlobalSnackBarProps>({
  showSnackbar: () => console.error("Not ready"),
});

export function GlobalSnackBarProvider({
  children,
}: GlobalSnackBarProviderProps): React.JSX.Element {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<AlertColor | undefined>(undefined);

  function showSnackbar(message: string, type?: AlertColor): void {
    setType(type);
    setMessage(message);
  }

  return (
    <GlobalSnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {!type && (
        <Snackbar
          open={message !== ""}
          autoHideDuration={6000}
          onClose={() => setMessage("")}
          message={message}
        />
      )}
      {type && (
        <Snackbar
          open={message !== ""}
          autoHideDuration={6000}
          onClose={() => setMessage("")}
          message={message}
        >
          <Alert
            onClose={() => setMessage("")}
            severity={type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </GlobalSnackbarContext.Provider>
  );
}

import React, { useContext } from "react";

import RenameDialog from "../../../components/dialogs/RenameDialog";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import { DocumentObj } from "../../../models/Models";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  document: DocumentObj | null | undefined;
}

function DocumentRenameModal({ isOpen, onClose, document }: Props) {
  const { selectedOrg } = useContext(OrgContext);

  return (
    <RenameDialog<DocumentObj>
      isOpen={isOpen}
      onClose={onClose}
      title={"Rename Document"}
      originalName={document?.original_filename}
      collection={organization(selectedOrg).document(document?.id)}
      createMutation={(name) => ({ original_filename: name })}
    />
  );
}

export default DocumentRenameModal;

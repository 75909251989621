import React, { useMemo } from "react";

import { BadgeProps } from "@mui/base";
import HubIcon from "@mui/icons-material/Hub";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Project } from "../../models/Models";
import KnowledgeBoostPickerDialog from "./KnowledgeBoostPickerDialog";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function KnowledgeBoostButton(props: Props) {
  const [open, setOpen] = React.useState(false);

  function handleKnowledgeClick() {
    setOpen(true);
  }

  function handleBoostCallback(projects: string[]) {
    props.setSelected(projects);
  }

  const tip = useMemo(() => {
    if (props.selected.length === 0) {
      return "Boost not active. Click to add!";
    }
    return `Boost active with ${props.selected.length} knowledge hubs!`;
  }, [props.selected.length]);
  return (
    <>
      <Tooltip title={tip}>
        <IconButton onClick={handleKnowledgeClick}>
          <StyledBadge badgeContent={props.selected.length} color={"primary"}>
            <HubIcon
              sx={{
                color: props.selected.length > 0 ? "red" : "text.disabled",
              }}
            />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <KnowledgeBoostPickerDialog
        open={open}
        close={() => setOpen(false)}
        currentSelected={props.selected}
        callback={handleBoostCallback}
      />
    </>
  );
}

interface Props {
  readonly selected: string[];
  readonly setSelected: (value: string[]) => void;
}

export default KnowledgeBoostButton;

import React from "react";

import HistoryIcon from "@mui/icons-material/History";
import { Button, Divider, Stack, Typography } from "@mui/material";

import { ChatAgentType } from "../../models/Models";
import ChatAgentPickerCard from "./ChatAgentPickerCard";

interface ChatPickerDisplayProps {
  selectChatType: (chatType: ChatAgentType) => void;
  openHistory?: () => void;
}

function ChatPickerDisplay({
  selectChatType,
  openHistory,
}: ChatPickerDisplayProps): JSX.Element {
  return (
    <Stack
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Stack direction={"column"} spacing={2}>
        <ChatAgentPickerCard
          chatType={ChatAgentType.DEFAULT}
          onClick={selectChatType}
        />
        <ChatAgentPickerCard
          chatType={ChatAgentType.REGULATIONS}
          onClick={selectChatType}
        />
      </Stack>
      {openHistory && (
        <>
          <Divider sx={{ width: "50%", py: 2 }} />
          <Typography variant={"subtitle2"} color={"text.secondary"}>
            or pick back up where you left off in a previous Chat session
          </Typography>
          <Button
            startIcon={<HistoryIcon />}
            variant={"outlined"}
            onClick={openHistory}
          >
            Open Session History
          </Button>
        </>
      )}
    </Stack>
  );
}

export default ChatPickerDisplay;

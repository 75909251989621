import * as React from "react";
import { useContext } from "react";

import EmailIcon from "@mui/icons-material/Email";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { OrgContext } from "../../contexts/OrgContext";
import { UserRole } from "../../models/Models";
import { OrgUser } from "../../models/OrgUser";

interface UserMiniCardProps {
  user: OrgUser;
}

function UserMiniCard({ user }: UserMiniCardProps): JSX.Element {
  const { selectedOrgConfig } = useContext(OrgContext);

  const role: UserRole | undefined = selectedOrgConfig?.roles[user.id];

  return (
    <Card sx={{}}>
      <CardContent>
        <Stack
          spacing={1}
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            sx={{
              width: 56,
              height: 56,
              border: "0.1px solid lightgray",
            }}
            alt={`${user.name} profile picture`}
            src={user.avatar_url}
          />
          <Stack direction={"column"}>
            <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
              <PersonIcon sx={{ fontSize: 18, color: "primary.main" }} />
              <strong>{user.name ?? "Unknown"}</strong>
            </Stack>
            <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
              <EmailIcon sx={{ fontSize: 18, color: "primary.main" }} />
              <Typography>{user.email}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
              {role === UserRole.ADMIN && (
                <LocalPoliceIcon sx={{ fontSize: 18, color: "primary.main" }} />
              )}
              {role === UserRole.USER && (
                <VisibilityIcon sx={{ fontSize: 18, color: "primary.main" }} />
              )}
              <Typography sx={{ textTransform: "capitalize" }}>
                {(role ?? "???").toLowerCase()}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default UserMiniCard;

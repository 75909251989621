import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { AnalyzerType, CustomAnalyzer } from "../../models/Models";
import ErrorDisplay from "../utils/ErrorDisplay";

interface Props {
  open: boolean;
  onClose: () => void;
  initialQuestionText?: string;
  callback: (question: string) => void;
}

function CreateCustomAnalyzerDialog({
  open,
  onClose,
  callback,
  initialQuestionText = "",
}: Props) {
  const { selectedOrg, uid } = useContext(OrgContext);
  const [name, setName] = useState<string>("");
  const [question, setQuestion] = useState<string>(initialQuestionText);
  const [error, setError] = useState<Error | string | null>(null);
  const [working, setWorking] = useState<boolean>(false);

  function close() {
    setQuestion("");
    setName("");
    setError(null);
    setWorking(false);
    onClose();
  }

  const handleAddNewQuestion = async () => {
    if (working) {
      return;
    }

    if (name.trim() === "") {
      setError("Name Required");
      return;
    }

    if (question.trim() === "") {
      setError("Question Required");
      return;
    }

    setWorking(true);
    setError(null);

    try {
      const analyzer: CustomAnalyzer = {
        id: "",
        name: name.trim(),
        question: question.trim(),
        owner: uid!,
        readers: [uid!],
        writers: [uid!],
        allowOrgRead: false,
        allowOrgWrite: false,
        type: AnalyzerType.CUSTOM,
        created_at: Timestamp.now(),
      };
      await organization(selectedOrg).analyzers().create(analyzer);
      callback(question);
      close();
    } catch (error) {
      setError(error as Error);
    } finally {
      setWorking(false);
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>New Custom Analyzer</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          noValidate
          onSubmit={async (event) => {
            event.preventDefault();
            await handleAddNewQuestion();
          }}
          autoComplete="off"
        >
          <TextField
            label="Name"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Question"
            fullWidth
            required
            multiline
            rows={4}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} disabled={working}>
          Close
        </Button>
        <Button onClick={handleAddNewQuestion} disabled={working}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCustomAnalyzerDialog;

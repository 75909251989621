import { useCallback, useEffect, useMemo, useState } from "react";

import { useInterval } from "./utils/Utils";

function useTextSmooth(
  text: string,
  delayMs: number,
  increment = 1,
  enabled = true
): string {
  const [index, setIndex] = useState(0);
  const [frozenEnabled, setFrozenEnabled] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    // we don't want to disable for the last chunk of text so keep it enabled
    if (frozenEnabled) {
      return;
    }
    setFrozenEnabled(enabled);
  }, [enabled]);

  const callback = useCallback(() => {
    setIndex((prev) => {
      if (prev > text.length) {
        return prev;
      }
      return prev + increment;
    });
  }, [text]);

  useInterval(callback, delayMs);

  return useMemo(() => {
    if (frozenEnabled === undefined) {
      return "";
    }
    if (!frozenEnabled) {
      return text;
    }
    const slice = text.slice(0, index);
    const boldCount = slice.split("**").length;
    // close bold markdown if we're streaming and a bold tag is open because we haven't streamed the closing tag yet
    if (boldCount % 2 === 0) {
      return `${slice}**`;
    }
    return slice;
  }, [text, index, frozenEnabled]);
}

export default useTextSmooth;

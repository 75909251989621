import React from "react";

import { useNavigate } from "react-router-dom";

import { IFsModel } from "../../fs/FirestoreUtils";
import { FsCollectionRef } from "../../fs/FsCollectionRef";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

interface Props<T extends IFsModel> {
  readonly title: string;
  readonly message: string | JSX.Element;
  readonly collection: FsCollectionRef<T>;
  readonly rows: T[];
  readonly onClose: () => void;
  readonly redirect?: string;
}

function FsDeleteDialog<T extends IFsModel>(props: Props<T>) {
  const navigate = useNavigate();
  const handleDelete = async () => {
    await props.collection.deleteAll(props.rows);
    if (props.redirect) {
      navigate(props.redirect);
    }
  };

  return (
    <ConfirmDeleteDialog
      title={props.title}
      message={props.message}
      open={props.rows.length > 0}
      onDelete={handleDelete}
      onClose={props.onClose}
    />
  );
}

export default FsDeleteDialog;

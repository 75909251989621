import React, { useContext } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { where } from "firebase/firestore";

import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import useFsCol from "../../../fs/useFsCol";
import { DocumentObj } from "../../../models/Models";
import DocumentQuestionCard from "./DocumentQuestionCard";

interface AnalyzeQuestionsDisplayProps {
  document: DocumentObj | null;
}

function AnalyzeQuestionsDisplay({
  document,
}: AnalyzeQuestionsDisplayProps): JSX.Element {
  const { selectedOrg, uid } = useContext(OrgContext);

  // sub-collection of previous requests to this document
  const [requests, loading, error] = useFsCol(
    organization(selectedOrg).document(document?.id).requests(),
    {
      where: where("owner", "==", uid),
      orderBy: "created_at",
      orderDir: "desc",
    }
  );

  return (
    <>
      <ErrorDisplay error={error} />
      <Box
        sx={{
          mt: 2,
          flex: 1,
          overflow: "auto",
          overflowX: "auto",
          padding: 2,
        }}
      >
        {loading && <CircularProgress />}
        {requests?.length === 0 && (
          <Typography
            variant={"subtitle1"}
            align="center"
            sx={{ mt: 5 }}
            color={"text.secondary"}
          >
            No Questions yet.
          </Typography>
        )}
        {(requests ?? []).map((request) => (
          <DocumentQuestionCard
            key={request.id}
            document={document!}
            data={request}
          />
        ))}
      </Box>
    </>
  );
}

export default AnalyzeQuestionsDisplay;

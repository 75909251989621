import * as React from "react";
import { ReactNode } from "react";

import ChatIcon from "@mui/icons-material/Chat";
import DescriptionIcon from "@mui/icons-material/Description";
import GradingIcon from "@mui/icons-material/Grading";
import HubIcon from "@mui/icons-material/Hub";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ThreePIcon from "@mui/icons-material/ThreeP";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { ChatAgentType } from "../../models/Models";
import { CHAT_TYPE_PARAM_KEY } from "../../pages/chat/ChatPageV2";
import { fixHref } from "../utils/Utils";

interface NavItem {
  readonly name: string;
  readonly icon: ReactNode;
  readonly beta?: boolean;
  readonly link: string;
}

interface NavSection {
  readonly name: string;
  readonly html?: ReactNode;
  readonly items: NavItem[];
}

function NavBarItems() {
  const location = useLocation();

  const sections: NavSection[] = [
    {
      name: "",
      items: [
        {
          name: "Knowledge Hubs",
          link: "/projects",
          beta: true,
          icon: <HubIcon />,
        },
      ],
    },
    {
      name: "Document Analyzer",
      items: [
        {
          name: "Documents",
          link: "/documents",
          icon: <DescriptionIcon />,
        },
        {
          name: "Analyzers",
          link: "/analyzers",
          icon: <GradingIcon />,
        },
      ],
    },
    {
      name: "Assistants",
      items: [
        {
          name: "Generalist",
          link: `/chat?${CHAT_TYPE_PARAM_KEY}=${ChatAgentType.DEFAULT}`,
          icon: <ChatIcon />,
        },
        {
          name: "Marketing",
          link: `/chat?${CHAT_TYPE_PARAM_KEY}=${ChatAgentType.MARKETING}`,
          icon: <ThreePIcon />,
        },
        {
          name: "Proposal",
          link: `/chat?${CHAT_TYPE_PARAM_KEY}=${ChatAgentType.PROPOSAL}`,
          icon: <ChatIcon />,
        },
        {
          name: "Legal",
          link: `/chat?${CHAT_TYPE_PARAM_KEY}=${ChatAgentType.REGULATIONS}`,
          icon: <ChatIcon />,
        },
        {
          name: "Report",
          link: `/chat?${CHAT_TYPE_PARAM_KEY}=${ChatAgentType.REPORT}`,
          icon: <SpeakerNotesIcon />,
        },
      ],
    },
  ];

  return (
    <>
      {sections.map((section) => {
        return (
          <Box key={section.name}>
            <Divider sx={{ mb: section.name ? 2 : undefined }} />
            {section.name && (
              <Typography
                sx={{
                  fontWeight: 600,
                  ml: 2,
                  fontSize: "10pt",
                  textAlign: "left",
                }}
              >
                {section.name}
              </Typography>
            )}
            {section.html}
            <List id={"nav-bar-items"}>
              {section.items.map((navItem) => {
                const isActiveLink = location.pathname.startsWith(navItem.link);
                return (
                  <ListItem key={navItem.name} disablePadding>
                    <Link
                      to={fixHref(navItem.link)}
                      style={{ display: "block", width: "100%" }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          sx={{
                            color: isActiveLink ? "primary.main" : undefined,
                          }}
                        >
                          {navItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={navItem.name}
                          primaryTypographyProps={{
                            className: navItem.beta ? "beta-label" : undefined,
                            color: isActiveLink
                              ? "primary.main"
                              : "text.primary",
                            fontWeight: isActiveLink ? 600 : 400,
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        );
      })}
    </>
  );
}

export default NavBarItems;

import React, { FormEvent, useState } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import ErrorDisplay from "../../components/utils/ErrorDisplay";
import { auth } from "../../configs/firebase";

function ChangePasswordForm(): React.JSX.Element {
  const [user] = useAuthState(auth);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const minPasswordLength = 8;

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (working) {
      return;
    }

    setError(null);
    setWorking(true);
    setSuccess(false);

    if (newPassword.length < minPasswordLength) {
      setError(new Error("Password too short."));
      setWorking(false);
      return;
    } else if (newPassword !== confirmPassword) {
      setError(new Error("New passwords don't match."));
      setWorking(false);
      return;
    }

    try {
      if (!user || !user.email) {
        throw new Error("User not authenticated or email not available.");
      }

      const credential = EmailAuthProvider.credential(user.email, oldPassword);

      // reauthenticate because credentials must be fresh in order to change password
      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);
      setSuccess(true);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (e) {
      console.error(e);
      setError(e as Error);
    } finally {
      setWorking(false);
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      sx={{ width: "100%", maxWidth: 600 }}
    >
      <Stack spacing={3}>
        <Typography variant="h6">Change Password</Typography>
        <Alert severity="info">{`Password must be at least ${minPasswordLength} characters long. Only works if you're signed in using email and password.`}</Alert>
        <ErrorDisplay error={error} />
        <TextField
          label="Old Password"
          value={oldPassword}
          type={"password"}
          onChange={(e) => setOldPassword(e.target.value)}
          fullWidth
        />
        <TextField
          label="New Password"
          value={newPassword}
          type={"password"}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
        />
        <TextField
          label="Confirm Password"
          value={confirmPassword}
          type={"password"}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={working}
          fullWidth
        >
          {working ? <CircularProgress size={24} /> : "Change Password"}
        </Button>
      </Stack>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        message={"Password successfully changed!"}
      />
    </Box>
  );
}

export default ChangePasswordForm;

import React, { ReactNode, createContext, useEffect } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { useSearchParams } from "react-router-dom";

import { auth } from "../configs/firebase";
import { RootUsersCol, organization } from "../fs/FirestoreUtils";
import useFsDoc from "../fs/useFsDoc";
import {
  OrgId,
  Organization,
  UserId,
  UserRole,
  makeUserId,
} from "../models/Models";
import { RootUser } from "../models/RootUser";

interface OrgContextProps {
  readonly userPermissions: string[];
  //Overridable UID for super admin access
  readonly uid: UserId;
  readonly isOverrideUid: boolean;
  readonly selectedOrg: OrgId;
  readonly selectedOrgConfig: Organization | null;
  readonly userRole: UserRole | null;
  readonly loading: boolean;
  readonly handleOrgChange: (newOrgId: string) => void;
}

export const OrgContext = createContext<OrgContextProps>({
  loading: true,
  userPermissions: [],
  // @ts-ignore
  uid: null,
  isOverrideUid: false,
  selectedOrg: "" as OrgId,
  userRole: null,
  selectedOrgConfig: null,
  handleOrgChange: () => console.error("Not ready"),
});

const LAST_USER_ORG_ID_KEY = "lastUserOrgId";

interface Props {
  readonly children: ReactNode;
}

export function OrgProvider({ children }: Props) {
  const [user] = useAuthState(auth);
  const [searchParams, setSearchParams] = useSearchParams();

  const overrideUid = searchParams.get("uid");

  const uidString = overrideUid ?? user?.uid;
  const uid = uidString ? makeUserId(uidString) : undefined;

  const isOverrideUid = user && uid !== user?.uid;

  const [userDoc, loading] = useFsDoc<RootUser>(
    RootUsersCol.instance.get(uid!)
  );

  const searchParamOrgId: OrgId | null = searchParams.get(
    "orgId"
  ) as OrgId | null;
  const lastUsedOrgId: OrgId | null = localStorage.getItem(
    LAST_USER_ORG_ID_KEY
  ) as OrgId | null;

  const userPermissions: OrgId[] = userDoc
    ? (Object.keys(userDoc.roles) as OrgId[])
    : []; // Extract org IDs from roles

  let selectedOrg: OrgId | null = searchParamOrgId;
  if (
    !selectedOrg &&
    lastUsedOrgId &&
    userPermissions.includes(lastUsedOrgId)
  ) {
    selectedOrg = lastUsedOrgId;
  } else if (!selectedOrg && userPermissions.length > 0) {
    selectedOrg = userPermissions[0];
  }

  // Get the users role in this org so we can display admin options to admins
  const userRole: UserRole | null =
    userDoc && selectedOrg ? (userDoc.roles[selectedOrg] ?? null) : null;

  // sync the search param
  useEffect(() => {
    if (selectedOrg && searchParamOrgId !== selectedOrg) {
      searchParams.set("orgId", selectedOrg);
      setSearchParams(searchParams, { replace: true });
    }
  }, [selectedOrg, searchParamOrgId, searchParams, setSearchParams]);

  // save last used orgId, but only if it was valid
  useEffect(() => {
    if (selectedOrg && userPermissions.includes(selectedOrg)) {
      localStorage.setItem(LAST_USER_ORG_ID_KEY, selectedOrg as string);
    }
  }, [selectedOrg, userPermissions]);

  function handleOrgChange(newOrgId: string): void {
    searchParams.set("orgId", newOrgId);
    setSearchParams(searchParams, { replace: true });
  }

  const [selectedOrgConfig, loadingOrg] = useFsDoc(organization(selectedOrg));

  return (
    <OrgContext.Provider
      value={{
        userPermissions,
        loading: loading || loadingOrg,
        selectedOrg: selectedOrg as OrgId,
        userRole,
        handleOrgChange,
        uid: uid!,
        isOverrideUid: isOverrideUid ?? false,
        selectedOrgConfig: selectedOrgConfig ?? null,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
}

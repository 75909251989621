import * as React from "react";

import { ListItem, ListItemButton, Stack, Typography } from "@mui/material";

import { IFsModel } from "../fs/FirestoreUtils";
import { FsCollectionRef } from "../fs/FsCollectionRef";
import { createFavoriteId } from "../models/OrgUser";
import ToggleFavoriteButton from "./ToggleFavoriteButton";

function FavoriteableListItemButton<T extends IFsModel>(props: Props<T>) {
  const { value } = props;

  function handleClick() {
    props.onClick(value);
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleClick}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%", mb: 2 }}
        >
          <Stack direction={"column"} spacing={1}>
            <Stack direction={"row"} spacing={1}>
              <ToggleFavoriteButton
                id={createFavoriteId(value.id, props.collection.resourceType!)}
              />
              <Typography
                variant={"h6"}
                sx={{ fontSize: "12pt" }}
                color={"text.primary"}
              >
                {props.primaryText}
              </Typography>
            </Stack>
            <Typography variant={"subtitle2"} color={"text.secondary"}>
              {props.secondaryText}
            </Typography>
          </Stack>
          {props.endContent}
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}

interface Props<T extends IFsModel> {
  readonly value: T;
  readonly endContent?: React.ReactNode;
  readonly onClick: (value: T) => void;
  readonly collection: FsCollectionRef<T>;
  readonly primaryText: string;
  readonly secondaryText: string;
}

export default FavoriteableListItemButton;

import React, { useContext } from "react";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { fixHref } from "../../components/utils/Utils";
import { CurrentProjectContext } from "./CurrentProjectContext";

function ProjectNavBarName() {
  const { project, projectId } = useContext(CurrentProjectContext);

  return (
    <Link to={fixHref(`/projects/${projectId}`)}>
      <Typography
        noWrap
        variant={"h6"}
        sx={{
          fontWeight: 800,
          maxWidth: "300px",
          textDecoration: "none",
          textOverflow: "ellipsis",
          color: "primary.contrastText",
        }}
      >
        {project?.name} Hub
      </Typography>
    </Link>
  );
}

export default ProjectNavBarName;

import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { useWorkload } from "../../hooks/useWorkload";
import ErrorDisplay from "../utils/ErrorDisplay";

interface DuplicateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  originalName: string | undefined;
  onSave: (name: string) => Promise<void>;
}

function DuplicateDialog({
  isOpen,
  onClose,
  title,
  originalName,
  onSave,
}: DuplicateDialogProps): JSX.Element {
  const [working, error, runWorkload] = useWorkload([isOpen]);
  const [name, setName] = useState("");

  useEffect(() => {
    setName(`${originalName} Copy` ?? "");
  }, [originalName]);

  async function save() {
    runWorkload(async () => {
      await onSave(name.trim());
      onClose();
    });
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          noValidate
          onSubmit={async (event) => {
            event.preventDefault();
            await save();
          }}
          autoComplete="off"
        >
          <DialogContentText>Enter a new name:</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            disabled={working}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={save} disabled={working}>
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;

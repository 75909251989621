import * as React from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Chip, Link, SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";

interface CitationSource {
  uri: string;
  title: string;
}

interface ChatCitationChipProps {
  citationSource: CitationSource;
  index: number;
  sx?: SxProps<Theme>;
  onHover: (index: number) => void;
}

function ChatCitationChip({
  citationSource,
  index,
  sx,
  onHover,
}: ChatCitationChipProps): JSX.Element {
  function onHoverEnter() {
    onHover(index);
  }

  function onHoverExit() {
    onHover(-1);
  }

  return (
    <Link
      href={citationSource.uri}
      target="_blank"
      rel="noopener"
      sx={sx}
      onMouseEnter={onHoverEnter}
      onMouseLeave={onHoverExit}
    >
      <Chip
        label={
          <Typography>
            <strong>{index + 1}. </strong>
            {citationSource.title}
          </Typography>
        }
        clickable
        deleteIcon={<OpenInNewIcon />}
        onDelete={() => {}}
      />
    </Link>
  );
}

export default ChatCitationChip;

import React, { ReactNode } from "react";

import { Box, Divider, Stack, Typography } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../configs/firebase";
import AppPage from "../navbar/AppPage";
import PasswordSignIn from "./PasswordSignIn";
import SignInWithGoogleButton from "./SignInWithGoogleButton";

interface SignInGuardProps {
  children: ReactNode;
}

function SignInGuard({ children }: SignInGuardProps): JSX.Element {
  const [user, loading] = useAuthState(auth);

  if (user != null) {
    return <>{children}</>;
  }

  return (
    <AppPage pageType={"minimalist"}>
      {!loading && (
        <Stack
          sx={{ justifyContent: "center", alignItems: "center", mt: 8 }}
          spacing={3}
        >
          <Typography variant={"h3"}>Welcome!</Typography>
          <Typography>Please log in to access Mysticetus AI.</Typography>
          <PasswordSignIn />
          <Box />
          <Divider sx={{ width: 400 }} />
          <SignInWithGoogleButton />
        </Stack>
      )}
    </AppPage>
  );
}

export default SignInGuard;

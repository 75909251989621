import React from "react";

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";

import { getChatConfig } from "../../components/chat/AiChatType";
import { ChatAgentType } from "../../models/Models";

interface ChatAgentPickerCardProps {
  chatType: ChatAgentType;
  onClick: (chatType: ChatAgentType) => void;
}

function ChatAgentPickerCard({
  chatType,
  onClick,
}: ChatAgentPickerCardProps): JSX.Element {
  const config = getChatConfig(chatType);

  return (
    <Card
      variant={"outlined"}
      sx={{ backgroundColor: `${config.color}.faded` }}
    >
      <CardActionArea onClick={() => onClick(chatType)}>
        <CardContent sx={{ justifyContent: "center", alignItems: "center" }}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent="center"
          >
            {config.icon}
            <Typography variant={"h6"} color={`${config.color}.main`}>
              {config.name}
            </Typography>
          </Stack>
          <Box sx={{ mt: 1 }} />
          <Typography
            variant={"subtitle2"}
            align="center"
            fontWeight={700}
            color={`${config.color}.main`}
          >
            {config.useCase}
          </Typography>
          <Box sx={{ mt: 2 }} />
          <Typography
            variant={"subtitle2"}
            align="center"
            fontWeight={400}
            color={"text.secondary"}
          >
            {config.description}
          </Typography>
          <Box sx={{ height: "100%" }} />
        </CardContent>
        <CardActions
          sx={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant={"contained"}
            color={config.color}
            fullWidth
            onClick={() => onClick(chatType)}
          >
            Select
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default ChatAgentPickerCard;

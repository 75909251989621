import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { OrgContext } from "../../contexts/OrgContext";
import { OrgId } from "../../models/Models";
import { fixHref, sleep } from "../utils/Utils";
import { uploadFile } from "./uploadUtils";

export enum UploadState {
  IDLE = "IDLE",
  UPLOADING = "UPLOADING",
  DONE = "DONE",
}

export interface FileState {
  id: string;
  org: OrgId;
  projectId: string | undefined;
  file: File;
  state: UploadState;
  progress: number;
  error: Error | null;
  isUploaded: boolean;
}

export interface FileUploaderState {
  setFiles: (files: File[]) => void;
  upload: () => void;
  working: boolean;
  fileStates: FileState[];
  error: Error | null;
}

function useFileUploader(projectId: string | undefined): FileUploaderState {
  const { selectedOrg } = useContext(OrgContext);
  const [fileStates, setFileStates] = useState<{ [key: string]: FileState }>(
    {}
  );
  const [working, setWorking] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();

  function setFiles(files: File[]): void {
    console.log(`Setting ${files.length} files...`);
    const states: { [key: string]: FileState } = {};
    for (const file of files) {
      const fileState: FileState = {
        id: uuidv4(),
        org: selectedOrg!,
        projectId: projectId,
        file: file,
        state: UploadState.IDLE,
        error: null,
        isUploaded: false,
        progress: 0,
      };
      states[fileState.id] = fileState;
    }
    setError(null);
    setFileStates(states);
  }

  async function doUploadFile(fileState: FileState) {
    if (fileState.state === UploadState.DONE) {
      return;
    }

    setFileStates((prev) => ({
      ...prev,
      [fileState.id]: {
        ...prev[fileState.id],
        state: UploadState.UPLOADING,
        progress: 0,
      },
    }));

    const updateProgress = (progress: number): void => {
      setFileStates((prev) => ({
        ...prev,
        [fileState.id]: {
          ...prev[fileState.id],
          state: UploadState.UPLOADING,
          progress: progress * 0.8,
        },
      }));
    };

    await uploadFile(fileState, updateProgress);

    setFileStates((prev) => ({
      ...prev,
      [fileState.id]: {
        ...prev[fileState.id],
        state: UploadState.DONE,
        progress: 100,
      },
    }));
  }

  async function upload(): Promise<void> {
    if (Object.keys(fileStates).length === 0 || working) {
      return;
    }

    console.log(
      `Starting upload of ${Object.keys(fileStates).length} files...`
    );
    setError(null);
    setWorking(true);

    await sleep(500);

    try {
      await Promise.all(Object.values(fileStates).map(doUploadFile));

      if (Object.keys(fileStates).length === 1 && !projectId) {
        const id = Object.keys(fileStates)[0];
        console.log(`Redirecting to document ${id}`);
        navigate(fixHref(`/documents/${id}`));
      }
    } catch (e) {
      setError(e as Error);
    } finally {
      setWorking(false);
    }
  }

  useEffect(() => {
    if (Object.keys(fileStates).length > 0) {
      upload();
    }
  }, [Object.keys(fileStates).join(",")]);

  return {
    setFiles,
    upload,
    working,
    fileStates: Object.values(fileStates),
    error,
  };
}

export default useFileUploader;

import * as React from "react";

import { Stack, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";

import { Project, ResourceType } from "../models/Models";
import { createFavoriteId } from "../models/OrgUser";
import ToggleFavoriteButton from "./ToggleFavoriteButton";
import NiceDateAgo from "./utils/NiceDateAgo";
import { fixHref } from "./utils/Utils";

const characterLimit = 80;

interface ProjectCardProps {
  project: Project;
}

function ProjectCard({ project }: ProjectCardProps): JSX.Element {
  const originalDescription = project.description || "No description given.";
  const trimmedDescription =
    originalDescription.length > characterLimit
      ? originalDescription.substring(0, characterLimit) + "..."
      : originalDescription;

  return (
    <Box sx={{ m: 1 }}>
      <Card>
        <CardContent sx={{ width: "260px" }}>
          <Stack direction={"column"} spacing={1}>
            <Stack direction={"row"} spacing={1}>
              <ToggleFavoriteButton
                id={createFavoriteId(project.id, ResourceType.PROJECT)}
              />
              <Tooltip title={project.name}>
                <Typography
                  variant={"h6"}
                  color={"primary.main"}
                  sx={{ textOverflow: "ellipsis" }}
                  noWrap
                >
                  {project.name}
                </Typography>
              </Tooltip>{" "}
            </Stack>

            <Typography
              variant={"body2"}
              sx={{ textOverflow: "ellipsis" }}
              noWrap
            >
              {trimmedDescription}
            </Typography>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography
                variant={"subtitle2"}
                sx={{ color: "text.secondary", fontSize: 12, mt: 1 }}
              >
                Created{" "}
                <NiceDateAgo
                  date={project.created_at}
                  sx={{ display: "inline" }}
                />
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Link to={fixHref(`/projects/${project.id}/ai`)}>
            <Button size="small">Open</Button>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
}

export default ProjectCard;

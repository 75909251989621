import { useEffect, useState } from "react";

import { getDownloadURL, ref } from "firebase/storage";

import { storage } from "../configs/firebase";

interface DownloadUrlState {
  loading: boolean;
  error: Error | null;
  downloadUrl: string | null;
}

function useDownloadUrl(blob: string | null | undefined): DownloadUrlState {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      if (blob) {
        setLoading(true);
        setError(null);
        setPdfUrl(null);
        try {
          const storageRef = ref(storage, blob);
          const downloadUrl = await getDownloadURL(storageRef);
          setPdfUrl(downloadUrl);
        } catch (error) {
          console.error("Error getting download URL:", error);
          setError(error as Error);
        } finally {
          setLoading(false);
        }
      } else {
        setPdfUrl(null);
      }
    };

    fetchPdfUrl();
  }, [blob]);

  return {
    downloadUrl: pdfUrl,
    loading,
    error,
  };
}

export default useDownloadUrl;

import React, { ChangeEvent, MouseEvent, useContext, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GridColDef } from "@mui/x-data-grid";
import { formatDistanceToNow } from "date-fns";
import { Timestamp } from "firebase/firestore";

import UserAvatar from "../../components/UserAvatar";
import MemTable from "../../components/tables/MemTable";
import UploadFileButton from "../../components/upload/UploadFileButton";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { ProjectDocument, UserId } from "../../models/Models";
import { CurrentProjectContext } from "./CurrentProjectContext";

const formatUploadedAt = (timestamp: Timestamp | null): JSX.Element => {
  if (timestamp && timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    const timeAgo = formatDistanceToNow(date, { addSuffix: true });
    return <span>{timeAgo}</span>;
  } else {
    return <span>N/A</span>;
  }
};

const statusChip = (status: string): JSX.Element => {
  switch (status) {
    case "PENDING":
      return <Chip label="Pending" variant="outlined" />;
    case "PROCESSING":
      return (
        <Chip
          label="Processing..."
          variant="outlined"
          icon={<CircularProgress size={18} />}
        />
      );
    case "INGESTING":
      return (
        <Chip
          label="Training..."
          variant="outlined"
          icon={<CircularProgress size={18} />}
        />
      );
    case "READY":
      return (
        <Chip
          label="Active"
          variant="filled"
          icon={<CheckCircleOutlineIcon />}
          color="success"
        />
      );
    case "FAILED":
      return (
        <Chip
          label="Failed"
          variant="filled"
          icon={<CancelIcon />}
          color="error"
        />
      );
    default:
      return <Chip label="Pending" variant="outlined" />;
  }
};

const columns: GridColDef[] = [
  { field: "original_filename", headerName: "Name", flex: 1, width: 300 },
  {
    field: "owner",
    headerName: "Owner",
    renderCell: (params) => {
      return (
        <Box sx={{ mt: 1 }}>
          <UserAvatar uid={params.value as UserId} />
        </Box>
      );
    },
  },
  {
    field: "datastore_status",
    headerName: "Status",
    width: 200,
    renderCell: (params) => {
      return statusChip(params.value);
    },
  },
  {
    field: "uploaded_at",
    headerName: "Uploaded At",
    width: 200,
    renderCell: (params) => {
      return formatUploadedAt(params.value);
    },
  },
];

function ProjectDocumentsTable(): JSX.Element {
  const { selectedOrg } = useContext(OrgContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newFilename, setNewFilename] = useState("");
  const [selectedDocument, setSelectedDocument] =
    useState<ProjectDocument | null>(null);
  const { projectId, project, documents, error, loadingDocs } = useContext(
    CurrentProjectContext
  );

  const handleMenuClick = (
    event: MouseEvent<HTMLElement>,
    document: ProjectDocument
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocument(document);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRenameClick = () => {
    handleMenuClose();
    setRenameDialogOpen(true);
    setNewFilename(selectedDocument!.original_filename);
  };

  const handleRenameDialogClose = () => {
    setRenameDialogOpen(false);
    setNewFilename("");
  };

  const handleRenameConfirm = async () => {
    try {
      await organization(selectedOrg)
        .project(projectId)
        .document(selectedDocument?.id)
        .update({ original_filename: newFilename });
    } catch (error) {
      console.error("Error renaming document:", error);
      // todo: handle error
    }
    handleRenameDialogClose();
  };

  const actionsColumn = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    getActions: (params: { row: ProjectDocument }) => [
      <IconButton
        key={params.row.id}
        aria-label="more"
        onClick={(e) => handleMenuClick(e, params.row)}
      >
        <MoreVertIcon />
      </IconButton>,
    ],
  };

  const menu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleRenameClick}>Rename</MenuItem>
    </Menu>
  );

  const renameDialog = (
    <Dialog open={renameDialogOpen} onClose={handleRenameDialogClose}>
      <DialogTitle>Rename Document</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the new name for the document:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="New Filename"
          type="text"
          fullWidth
          value={newFilename}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewFilename(e.target.value)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenameDialogClose}>Cancel</Button>
        <Button onClick={handleRenameConfirm}>Rename</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <MemTable
        items={documents}
        columns={[...columns, actionsColumn]}
        error={error}
        enableSelection={false}
        extraControls={
          <Stack direction={"row"} spacing={3}>
            <UploadFileButton project={project!} />
          </Stack>
        }
        enableControls={true}
        loading={loadingDocs}
      />
      {menu}
      {renameDialog}
    </Box>
  );
}

export default ProjectDocumentsTable;

import React, { ReactNode, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import { fixHref } from "./utils/Utils";

export interface IconMenuItem {
  name: string;
  icon: ReactNode;
  // if string, treated as link
  action: string | (() => void);
}

interface Props {
  id: string;
  menuItems: (IconMenuItem | null)[];
  children?: ReactNode;
}

function IconMenu({ id, menuItems, children }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (menuItems.length === 0) {
    return <></>;
  }

  return (
    <>
      <IconButton
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={openMenu}
      >
        {children ?? <MoreVertIcon />}
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {renderMenuItems(menuItems, closeMenu)}
      </Menu>
    </>
  );
}

export function renderMenuItems(
  menuItems: (IconMenuItem | null)[],
  closeMenu: () => void
): React.JSX.Element[] {
  return menuItems.map((item, index) => {
    if (item == null) {
      return <Divider key={`divider-${index}`} />;
    }
    if (typeof item.action === "string") {
      return (
        <a
          key={item.name}
          href={fixHref(item.action)}
          target={"_blank"}
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <MenuItem
            onClick={() => {
              closeMenu();
            }}
            sx={{
              color: "text.primary",
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        </a>
      );
    } else {
      return (
        <MenuItem
          key={index}
          onClick={() => {
            closeMenu();
            (item.action as () => void)();
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText>{item.name}</ListItemText>
        </MenuItem>
      );
    }
  });
}

export default IconMenu;

import React, { useContext } from "react";

import { Typography } from "@mui/material";

import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";
import { sendApiRequest } from "../../components/utils/FetchUtils";
import { OrgContext } from "../../contexts/OrgContext";
import { Project } from "../../models/Models";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedProjects: Project[];
}

function DeleteProjectDialog(props: Props): React.JSX.Element {
  const { selectedOrg } = useContext(OrgContext);

  async function handleConfirmDelete() {
    await Promise.all(
      props.selectedProjects.map(async (project) => {
        return await sendApiRequest("/projects/delete", {
          org_id: selectedOrg,
          project_id: project.id,
        });
      })
    );
  }

  return (
    <ConfirmDeleteDialog
      title={"Confirm Delete"}
      message={
        <Typography sx={{ mt: 2 }}>
          {`Are you sure you want to delete ${props.selectedProjects.length} projects?`}
        </Typography>
      }
      open={props.isOpen}
      onClose={props.onClose}
      onDelete={handleConfirmDelete}
    />
  );
}

export default DeleteProjectDialog;

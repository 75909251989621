import React, { ReactNode, useContext } from "react";

import { Typography } from "@mui/material";

import { OrgContext } from "../../contexts/OrgContext";
import ErrorPage from "../utils/ErrorPage";

interface OrgRequiredGuardProps {
  children: ReactNode;
}

function OrgRequiredGuard({ children }: OrgRequiredGuardProps): JSX.Element {
  const { selectedOrg, loading, userPermissions, isOverrideUid } =
    useContext(OrgContext);

  if (loading) {
    return <div></div>;
  }

  if (userPermissions.length === 0) {
    return (
      <ErrorPage
        title={"No Organizations Found"}
        body={
          <Typography>
            You need to be invited to your company&apos;s organization by your
            company&apos;s administrators. If you think this was a mistake,
            contact <a href="mailto:info@mysticetus.org">info@mysticetus.org</a>
            .
          </Typography>
        }
      />
    );
  }

  if (!selectedOrg) {
    return (
      <ErrorPage
        title={"No Organizations Selected"}
        body={"No org selected."}
      />
    );
  }

  if (!userPermissions.includes(selectedOrg) && !isOverrideUid) {
    return (
      <ErrorPage
        title={"Unauthorized Access"}
        body={
          <Typography>
            You don&apos;t have access to the <strong>{selectedOrg}</strong>. If
            you think this was a mistake, contact{" "}
            <a href="mailto:info@mysticetus.org">info@mysticetus.org</a>.
          </Typography>
        }
      />
    );
  }

  return <>{children}</>;
}

export default OrgRequiredGuard;

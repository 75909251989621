import React, { useContext, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { Button, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { GlobalSnackbarContext } from "../../../components/GlobalSnackbarContext";
import FsDeleteDialog from "../../../components/dialogs/FsDeleteDialog";
import FsTable, {
  TABLE_COL_CREATED_AT,
} from "../../../components/tables/FsTable";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import { Invite } from "../../../models/Models";
import { sendInviteEmail } from "../../../utils/FunctionsClient";
import { TableAction } from "../../documents/list/DocumentGridControls";
import InviteUserDialog from "./InviteUserDialog";

function InvitesTable() {
  const { selectedOrg } = useContext(OrgContext);
  const [rowsToDelete, setRowsToDelete] = useState<Invite[]>([]);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const { showSnackbar } = useContext(GlobalSnackbarContext);

  const collection = useMemo(() => {
    return organization(selectedOrg).invites();
  }, []);

  async function handleInvite(rows: Invite[]) {
    await Promise.all(
      rows.map((r) => sendInviteEmail({ org_id: selectedOrg, invite_id: r.id }))
    );
    showSnackbar("Invite emails sent!");
  }

  const actions = useMemo<TableAction<Invite>[]>(() => {
    const result: TableAction<Invite>[] = [
      {
        name: "Resend Invite",
        tooltip: "Resend invite email",
        supportsMultipleRows: true,
        icon: <SendIcon />,
        action: (rows: Invite[]) => handleInvite(rows),
      },
      {
        name: "Delete",
        tooltip: "Delete invite",
        supportsMultipleRows: true,
        icon: <DeleteIcon />,
        action: (rows: Invite[]) => setRowsToDelete(rows),
      },
    ];
    return result;
  }, []);

  return (
    <>
      <FsTable<Invite>
        columns={columns}
        dataCollection={collection}
        actions={actions}
        extraControls={
          <Stack direction={"row"}>
            <Button
              variant={"contained"}
              onClick={() => setShowInviteDialog(true)}
            >
              Invite User
            </Button>
          </Stack>
        }
        orderByField={"created_at"}
        orderByDir={"desc"}
      />
      <FsDeleteDialog
        title={"Delete Invites"}
        message={`Are you sure you want to delete ${rowsToDelete.length} invites?`}
        collection={collection}
        rows={rowsToDelete}
        onClose={() => setRowsToDelete([])}
      />
      <InviteUserDialog
        open={showInviteDialog}
        close={() => setShowInviteDialog(false)}
      />
    </>
  );
}

const TABLE_EMAIL: GridColDef = {
  field: "email",
  headerName: "Email",
  flex: 1,
  width: 400,
};

const TABLE_ROLE: GridColDef = {
  field: "role",
  headerName: "Role",
  width: 200,
};

const columns: GridColDef[] = [TABLE_EMAIL, TABLE_ROLE, TABLE_COL_CREATED_AT];

export default InvitesTable;

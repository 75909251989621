import React, { useContext } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
} from "@mui/material";
import { where } from "firebase/firestore";

import ErrorDisplay from "../../components/utils/ErrorDisplay";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import ChatSessionItem from "./ChatSessionItem";

function ChatSessionPickerDialog({ open, onClose }: Props) {
  const { selectedOrg, uid } = useContext(OrgContext);

  const [sessions, loading, error] = useFsCol(
    organization(selectedOrg).sessions(),
    {
      where: where("owner", "==", uid),
      orderBy: "created_at",
      orderDir: "desc",
    }
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>Chat Session History</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error} />
        {loading && <CircularProgress />}
        {(sessions ?? []).length === 0 && (
          <Typography
            variant={"subtitle2"}
            textAlign={"center"}
            sx={{ mt: 4 }}
            color={"text.secondary"}
          >
            No Sessions
          </Typography>
        )}
        <List>
          {(sessions ?? []).map((session) => (
            <ChatSessionItem
              key={session.id}
              session={session}
              onClick={onClose}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  readonly open: boolean;
  readonly onClose: () => void;
}

export default ChatSessionPickerDialog;

import { useMemo } from "react";

import {
  Query,
  QueryConstraint,
  QuerySnapshot,
  endAt,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { FsQuery } from "../components/tables/FsQuery";
import { IFsModel } from "./FirestoreUtils";
import { FsCollectionRef } from "./FsCollectionRef";

function useFsCol<T extends IFsModel>(
  colRef: FsCollectionRef<T>,
  q: FsQuery | undefined = undefined
): [T[], boolean, Error | null, QuerySnapshot<T> | null] {
  // denoise the colRef to avoid unnecessary re-renders because React only does a shallow comparison and FsCollectionRef is an object
  const denoisedQuery = useMemo<Query<T> | null>(() => {
    if (colRef.isAnyIdUndefined()) {
      return null;
    }

    if (!q) {
      return query(colRef.getCollection());
    }

    const clauses: QueryConstraint[] = [];

    if (q.where) {
      // @ts-ignore
      clauses.push(q.where);
    }

    clauses.push(orderBy(q.orderBy, q.orderDir));

    if (q.startAfter) {
      clauses.push(startAfter(q.startAfter), limit(q.limit ?? 10000));
    } else if (q.endAt) {
      clauses.push(endAt(q.endAt), limitToLast(q.limit ?? 10000));
    } else if (q.limit) {
      clauses.push(limit(q.limit));
    }

    return query(colRef.getCollection(), ...clauses);
  }, [
    colRef.getPath(),
    JSON.stringify(q?.where),
    q?.endAt,
    q?.startAfter,
    q?.orderBy,
    q?.orderDir,
    q?.limit,
  ]);

  const [data, loading, error, snapshot] = useCollectionData(denoisedQuery);

  return [data ?? [], loading, error ?? null, snapshot ?? null];
}

export default useFsCol;

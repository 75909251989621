import React, { useContext, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";

import ToggleFavoriteButton from "../../components/ToggleFavoriteButton";
import SharingDialog from "../../components/acls/SharingDialog";
import RenameDialog from "../../components/dialogs/RenameDialog";
import AppPage from "../../components/navbar/AppPage";
import FsTable, {
  TABLE_COL_CREATED_AT,
  TABLE_COL_OWNER,
  TABLE_COL_WRITERS,
} from "../../components/tables/FsTable";
import { fixHref } from "../../components/utils/Utils";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { Project, ResourceType } from "../../models/Models";
import { createFavoriteId } from "../../models/OrgUser";
import { whereReadAccessible } from "../../utils/AclUtils";
import { TableAction } from "../documents/list/DocumentGridControls";
import CreateProjectDialog from "./CreateProjectDialog";
import DeleteProjectDialog from "./DeleteProjectDialog";

function ProjectListPage() {
  const { selectedOrg, uid } = useContext(OrgContext);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [rowsToDelete, setRowsToDelete] = useState<Project[]>([]);
  const [rowToShare, setRowToShare] = useState<Project | null>(null);
  const [rowToRename, setRowToRename] = useState<Project | null>(null);

  const actions = useMemo(() => {
    const result: TableAction<Project>[] = [
      {
        name: "Rename",
        tooltip: "rename hub",
        supportsMultipleRows: false,
        icon: <EditIcon />,
        action: (rows: Project[]) => setRowToRename(rows[0]),
      },
      {
        name: "Share",
        tooltip: "share hub",
        supportsMultipleRows: false,
        icon: <PersonAddIcon />,
        action: (rows) => setRowToShare(rows[0]),
      },
      {
        name: "Delete",
        tooltip: "Delete Knowledge Hub",
        supportsMultipleRows: true,
        icon: <DeleteIcon />,
        action: (rows) => setRowsToDelete(rows),
      },
    ];
    return result;
  }, []);

  return (
    <AppPage pageType={"flat"}>
      <Typography variant="h4" gutterBottom>
        Knowledge Hubs
      </Typography>
      <Box sx={{ mb: 3, maxWidth: "800px" }}>
        <Typography variant={"body2"}>
          Get insightful answers from your documents with your Knowledge Hub
          Specific AI. Each Knowledge Hub can support up to 10,000 documents!
          Create a Knowledge Hub, add your documents, and wait for them to be
          Ready. Once ready, click the Knowledge Hub AI button to ask your AI
          any question and get answers that synthesize and cite information
          directly from your documents. Manage Knowledge Hub access with ease.
        </Typography>
      </Box>
      <Box sx={{ mb: 1 }} />
      <FsTable<Project>
        columns={[
          PROJECTS_TABLE_COL_NAME,
          TABLE_COL_OWNER,
          TABLE_COL_WRITERS,
          TABLE_COL_CREATED_AT,
        ]}
        enableSelection={true}
        enableControls={true}
        orderByDir={"desc"}
        actions={actions}
        extraControls={
          <Button
            variant="outlined"
            onClick={() => setCreateModalOpen(true)}
            startIcon={<AddIcon />}
          >
            New Knowledge Hub
          </Button>
        }
        dataCollection={organization(selectedOrg).projects()}
        whereClause={whereReadAccessible(uid)}
        orderByField={"created_at"}
        onCellDoubleClick={(row) => {
          navigate(`/projects/${row.id}`);
        }}
      />
      <CreateProjectDialog
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
      <DeleteProjectDialog
        isOpen={rowsToDelete.length > 0}
        onClose={() => setRowsToDelete([])}
        selectedProjects={rowsToDelete}
      />
      <SharingDialog
        open={rowToShare !== null}
        docRef={organization(selectedOrg).project(rowToShare?.id)}
        resourceName={rowToShare?.name}
        onClose={() => setRowToShare(null)}
      />
      <RenameDialog<Project>
        isOpen={rowToRename != null}
        onClose={() => setRowToRename(null)}
        title={"Rename Knowledge Hub"}
        originalName={rowToRename?.name}
        collection={organization(selectedOrg).project(rowToRename?.id)}
        createMutation={(name) => ({ name: name })}
      />
    </AppPage>
  );
}

export default ProjectListPage;

export const PROJECTS_TABLE_COL_NAME: GridColDef = {
  field: "name",
  headerName: "Name",
  flex: 1,
  width: 400,
  renderCell: (params) => {
    return (
      <Stack direction={"row"} spacing={1}>
        <ToggleFavoriteButton
          id={createFavoriteId(params.row.id, ResourceType.PROJECT)}
        />
        <Link to={fixHref(`/projects/${params.row.id}`)}>{params.value}</Link>
      </Stack>
    );
  },
};

export const BASE_PROJECTS_TABLE_COL_ACTIONS: GridColDef = {
  field: "actions",
  type: "actions",
  width: 80,
};

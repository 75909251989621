import { createTheme } from "@mui/material/styles";

const main = "#2c7870";

declare global {
  interface SimplePaletteColorOptions {
    faded?: string;
  }
}

// @ts-ignore
export const theme = createTheme({
  cssVariables: true,
  palette: {
    // https://www.canva.com/colors/color-wheel/
    primary: {
      main: main,
      light: "#56a39b",
      // @ts-ignore
      faded: "#c4f3ee",
    },
    secondary: {
      main: "#2C5A78",
      light: "#2C5A78",
      // @ts-ignore
      faded: "#d2eafa",
    },
    warning: {
      main: "#fad676",
      // @ts-ignore
      text: "#aa8c3f",
    },
    error: {
      main: "#ca4050",
      // @ts-ignore
      text: "#ca4050",
    },
    background: {
      default: "rgb(248, 250, 253)",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          "ul .Mui-selected": {
            borderLeftStyle: "solid!important",
            borderLeft: "3px",
            borderLeftColor: main,
          },
          ".beta-label:after": {
            content: '"BETA"',
            color: "red",
            position: "fixed",
            fontSize: "8pt",
            fontWeight: 800,
          },
        },
        "#app-nav-bar a": {
          textDecoration: "none!important",
        },
        "#nav-bar-items a": {
          textDecoration: "none!important",
          color: "primary.contrastText",
        },
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "rgb(255, 255, 255)",
        },
        root: {
          backgroundColor: "rgb(255, 255, 255)",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        },
      },
    },
  },
});

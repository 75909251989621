import * as React from "react";
import { useState } from "react";

import { Alert, Button, Link, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { sendPasswordResetEmail } from "firebase/auth";

import { auth } from "../../configs/firebase";
import ErrorDisplay from "../utils/ErrorDisplay";

interface PasswordResetFormProps {
  onCancel: () => void;
  email: string;
  setEmail: (email: string) => void;
}

function PasswordResetForm({
  onCancel,
  email,
  setEmail,
}: PasswordResetFormProps): JSX.Element {
  const [error, setError] = useState<Error | null>(null);
  const [didResetPassword, setDidResetPassword] = useState(false);

  function goBack() {
    setDidResetPassword(false);
    setError(null);
    onCancel();
  }

  async function doResetPassword() {
    setError(null);
    try {
      await sendPasswordResetEmail(auth, email);
      setDidResetPassword(true);
    } catch (e) {
      setError(e as Error);
    }
  }

  return (
    <Stack spacing={2}>
      {didResetPassword && (
        <Alert severity="success">
          Password reset email sent. Please check your inbox.
        </Alert>
      )}
      {!didResetPassword && (
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1, width: "50ch" } }}
          onSubmit={async (e) => {
            e.preventDefault();
            await doResetPassword();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={2}>
            <ErrorDisplay error={error} />
            <TextField
              name={"email"}
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" type="submit">
              Reset Password
            </Button>
          </Stack>
        </Box>
      )}
      <Link onClick={goBack}>Go back</Link>
    </Stack>
  );
}

export default PasswordResetForm;

import { ChatAgentType, OrgId } from "../../models/Models";
import { sendApiRequest } from "../utils/FetchUtils";

export async function startNewChat(
  selectedOrg: OrgId,
  selectedType: ChatAgentType
): Promise<string> {
  const response = await sendApiRequest("/new-chat", {
    org_id: selectedOrg,
    agent_id: selectedType,
  });

  const data = await response.json();
  console.log("new session", data);
  return data.session_id; // Get the new sessionId
}

export async function sendMessage(
  selectedOrg: OrgId,
  sessionId: string,
  inputMessage: string,
  context: string[]
): Promise<void> {
  await sendApiRequest("/streaming-chat", {
    org_id: selectedOrg,
    session_id: sessionId,
    message: inputMessage,
    context: context,
  });
}

import React, { useContext, useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import ReactMarkdown from "react-markdown";

import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import { sendApiRequest } from "../../../components/utils/FetchUtils";
import { OrgContext } from "../../../contexts/OrgContext";
import { ProjectDocument } from "../../../models/Models";
import { CurrentProjectContext } from "../CurrentProjectContext";

function ProjectDocumentAccordian({ document, expanded, toggle }: Props) {
  const { selectedOrg } = useContext(OrgContext);
  const { projectId } = useContext(CurrentProjectContext);

  const [loadingSummary, setLoadingSummary] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (document.summary) {
      setLoadingSummary(false);
    }
  }, [document.summary]);

  async function summarize() {
    if (loadingSummary) {
      return;
    }

    setLoadingSummary(true);
    setError(null);

    try {
      await sendApiRequest("/projects/summarize-document", {
        org_id: selectedOrg,
        project_id: projectId,
        document_id: document.id,
      });
    } catch (e) {
      setError(e as Error);
      setLoadingSummary(false);
    }
  }

  async function handleToggle() {
    toggle(document.id);

    if (!expanded && !document.summary) {
      await summarize();
    }
  }

  return (
    <Accordion expanded={expanded} onChange={handleToggle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{document.original_filename}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ErrorDisplay error={error} />
        {loadingSummary && (
          <ThreeDots height="20" width="30" color="#000" ariaLabel="loading" />
        )}
        {!loadingSummary && !error && (
          <ReactMarkdown>
            {document.summary || "No summary available."}
          </ReactMarkdown>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

interface Props {
  readonly document: ProjectDocument;
  readonly expanded: boolean;
  readonly toggle: (docId: string) => void;
}

export default ProjectDocumentAccordian;

import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  addDoc,
  collection,
} from "firebase/firestore";

import { db } from "../configs/firebase";
import { ResourceType } from "../models/Models";
import { IFsModel } from "./FirestoreUtils";
import { FsDocRef } from "./FsDocRef";

export abstract class FsCollectionRef<
  TData extends IFsModel,
  TDocRef extends FsDocRef<TData> = FsDocRef<TData>,
> implements FirestoreDataConverter<TData, DocumentData>
{
  public readonly parent: FsDocRef<any> | null;
  public readonly name: string;
  public readonly resourceType: ResourceType | null;

  protected constructor(
    parent: FsDocRef<any> | null,
    name: string,
    resourceType: ResourceType | null
  ) {
    this.parent = parent;
    this.name = name;
    this.resourceType = resourceType;
  }

  public abstract get(id: string): TDocRef;

  public isAnyIdUndefined(): boolean {
    if (this.parent == null) {
      return false;
    }
    return this.parent.isAnyIdUndefined();
  }

  public getPath(): string {
    if (this.parent == null) {
      return "/" + this.name;
    }
    return this.parent!.getPath() + "/" + this.name;
  }

  public getCollection(): CollectionReference<TData, DocumentData> {
    if (this.isAnyIdUndefined()) {
      // @ts-ignore
      return null;
    }
    return collection(db, this.getPath()).withConverter(this);
  }

  public toFirestore(data: TData): DocumentData {
    const result: DocumentData = { ...data };
    // unset id
    delete result.id;
    return result;
  }

  public fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
    options: SnapshotOptions
  ): TData {
    const data = snapshot.data(options);
    return {
      ...data,
      id: snapshot.id,
    } as TData;
  }

  async create(value: TData): Promise<DocumentReference<TData>> {
    value = {
      ...value,
      // unset id so server does it
      id: undefined,
    };
    return await addDoc(this.getCollection(), value);
  }

  async deleteAll(docs: TData[]): Promise<void> {
    await Promise.all(docs.map((doc) => this.get(doc.id).delete()));
  }
}

import React, { ReactNode, createContext, useContext, useEffect } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../configs/firebase";
import { organization } from "../fs/FirestoreUtils";
import useFsCol from "../fs/useFsCol";
import { UserId } from "../models/Models";
import { OrgUser } from "../models/OrgUser";
import { OrgContext } from "./OrgContext";

interface OrgUserProfilesProps {
  readonly getUser: (id: UserId | null | undefined) => OrgUser;
  readonly user: OrgUser | undefined;
  readonly users: OrgUser[];
  readonly loading: boolean;
}

const UNKNOWN_USER: OrgUser = {
  id: "UNKNOWN" as UserId,
  name: "Unknown",
  avatar_url: "",
  email: "Unknown",
  favorites: [],
};

export function isUnknownUser(user: OrgUser | undefined): boolean {
  return user?.email === UNKNOWN_USER.email;
}

export const OrgUserProfilesContext = createContext<OrgUserProfilesProps>({
  user: UNKNOWN_USER,
  users: [],
  getUser: (id) => {
    return {
      ...UNKNOWN_USER,
      id: id ?? ("unknown" as UserId),
    };
  },
  loading: true,
});

interface Props {
  readonly children: ReactNode;
}

/**
 * Caches all user profile docs
 */
export function OrgUserProfilesProvider({ children }: Props) {
  const [user] = useAuthState(auth);

  const { selectedOrg, loading } = useContext(OrgContext);

  const [users, userDocsLoading] = useFsCol<OrgUser>(
    organization(selectedOrg).users()
  );

  const self = (users ?? []).find((u) => u.id === user?.uid);

  function getUser(id: UserId | null | undefined): OrgUser {
    const user = (users ?? []).find((u) => u.id === id);
    return (
      user ?? {
        ...UNKNOWN_USER,
        id: id ?? ("unknown" as UserId),
      }
    );
  }

  // sync user info
  useEffect(() => {
    async function run() {
      if (
        self &&
        user &&
        (self.name !== user.displayName ||
          self.email !== user.email ||
          self.avatar_url !== user.photoURL)
      ) {
        console.log("Syncing user info...");
        await organization(selectedOrg).user(self.id).update({
          name: user.displayName!,
          email: user.email!,
          avatar_url: user.photoURL!,
        });
      }
    }

    run().then();
  }, [
    userDocsLoading,
    user?.displayName,
    user?.email,
    user?.photoURL,
    self?.id,
    self?.name,
    self?.email,
    self?.avatar_url,
  ]);

  return (
    <OrgUserProfilesContext.Provider
      value={{
        user: self,
        users: users ?? [],
        loading: loading || userDocsLoading,
        getUser,
      }}
    >
      {children}
    </OrgUserProfilesContext.Provider>
  );
}

import React, { ReactNode, useState } from "react";

import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../configs/firebase";
import AppPage from "../navbar/AppPage";
import ErrorDisplay from "../utils/ErrorDisplay";
import { useInterval } from "../utils/Utils";

interface VerifyEmailGuardProps {
  children: ReactNode;
}

function VerifyEmailGuard({ children }: VerifyEmailGuardProps): JSX.Element {
  const [user, userLoading] = useAuthState(auth);
  const [error, setError] = useState<Error | null>(null);
  const [sending, setSending] = useState<boolean>(false);
  const [sendSuccessful, setSendSuccessful] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(
    user?.emailVerified ?? false
  );

  async function checkEmailVerified(): Promise<void> {
    if (isVerified) {
      return;
    }

    if (auth.currentUser !== null) {
      console.log("polling if emailVerified...");
      await auth.currentUser.reload();
      setIsVerified(auth.currentUser?.emailVerified ?? false);
    }
  }

  useInterval(checkEmailVerified, 1000);

  async function sendVerificationEmail(): Promise<void> {
    try {
      setSendSuccessful(false);
      setSending(true);
      setError(null);
      if (user) {
        await sendEmailVerification(user);
        setSendSuccessful(true);
      }
    } catch (e) {
      setError(e as Error);
    } finally {
      setSending(false);
    }
  }

  if (isVerified || user?.emailVerified) {
    return <>{children}</>;
  }

  const loading = userLoading || sending;

  return (
    <AppPage pageType={"minimalist"}>
      {userLoading && <CircularProgress />}
      {!userLoading && (
        <Stack
          sx={{ justifyContent: "center", alignItems: "center", mt: 8 }}
          spacing={3}
        >
          <Typography variant={"h3"}>Email Verification Required</Typography>
          <Typography>
            Please verify your email to access Mysticetus AI.
          </Typography>
          <ErrorDisplay error={error} />
          {sendSuccessful && (
            <Alert severity="success">
              Verification email sent. Please check your inbox.
            </Alert>
          )}
          {loading && <CircularProgress />}
          {!loading && (
            <Button variant="contained" onClick={sendVerificationEmail}>
              Send Email Verification Link
            </Button>
          )}
        </Stack>
      )}
    </AppPage>
  );
}

export default VerifyEmailGuard;

import React, { useContext } from "react";

import { Grid, Typography } from "@mui/material";

import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";
import { sendApiRequest } from "../../components/utils/FetchUtils";
import UserMiniCard from "../../components/utils/UserMiniCard";
import { OrgContext } from "../../contexts/OrgContext";
import { OrgUserProfilesContext } from "../../contexts/OrgUserProfilesContext";
import { UserId } from "../../models/Models";
import { OrgUser } from "../../models/OrgUser";

interface ConfirmDeleteUserDialogProps {
  uid: UserId | null;
  onClose: () => void;
}

function ConfirmDeleteUserDialog({
  uid,
  onClose,
}: ConfirmDeleteUserDialogProps): JSX.Element {
  const { selectedOrg } = useContext(OrgContext);
  const { getUser } = useContext(OrgUserProfilesContext);

  const targetUser: OrgUser = getUser(uid);

  async function handleDeleteUser() {
    await sendApiRequest("/admin/delete-user", {
      org_id: selectedOrg,
      user_id: uid,
    });
  }

  return (
    <ConfirmDeleteDialog
      title={`Delete User ${targetUser?.name}?`}
      message={
        <>
          <Typography gutterBottom>
            Are you sure you want to delete this user? This cannot be undone.
          </Typography>
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={{
              m: 2,
            }}
          >
            <Grid item xs={6}>
              <UserMiniCard user={targetUser} />
            </Grid>
          </Grid>
        </>
      }
      open={uid != null}
      onClose={onClose}
      onDelete={handleDeleteUser}
    />
  );
}

export default ConfirmDeleteUserDialog;

import React, { useContext } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useWorkload } from "../../hooks/useWorkload";
import { GlobalSnackbarContext } from "../GlobalSnackbarContext";
import ErrorDisplay from "../utils/ErrorDisplay";

interface Props {
  readonly title: string;
  readonly message: string | JSX.Element;
  readonly open: boolean;
  readonly onDelete: () => Promise<void>;
  readonly onClose: () => void;
}

function ConfirmDeleteDialog({
  title,
  message,
  open,
  onDelete,
  onClose,
}: Props) {
  const [working, error, runWorkload] = useWorkload([open]);

  const { showSnackbar } = useContext(GlobalSnackbarContext);

  const handleDeleteUser = async () => {
    runWorkload(async () => {
      await onDelete();
      showSnackbar("Delete successful!");
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error} />
        {working && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!working && message}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="error" onClick={handleDeleteUser} disabled={working}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteDialog;

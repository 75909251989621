import React, { useContext } from "react";

import { Alert, Box, Typography } from "@mui/material";

import CopyButton from "../../components/utils/CopyButton";
import { getUrl } from "../../components/utils/Utils";
import { OrgContext } from "../../contexts/OrgContext";
import InvitesTable from "./invites/InvitesTable";

function InvitesTab(): JSX.Element {
  const { selectedOrg } = useContext(OrgContext);

  const acceptInviteUrl = `${getUrl()}/accept-invitation/${selectedOrg}`;
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h6" gutterBottom>
        Pending Invitations
      </Typography>
      <Alert severity={"info"} sx={{ mb: 3 }}>
        Have your coworkers open{" "}
        <a href={acceptInviteUrl} target={"_blank"} rel="noreferrer">
          {acceptInviteUrl}
        </a>
        <CopyButton value={acceptInviteUrl} /> in their browser to accept the
        invitation.
      </Alert>
      <InvitesTable />
    </Box>
  );
}

export default InvitesTab;

import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";

import ErrorDisplay from "../../components/utils/ErrorDisplay";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import {
  AnalyzerType,
  CustomAnalyzer,
  LegalAnalyzer,
} from "../../models/Models";

interface CreateNewAnalyzerModalProps {
  open: boolean;
  onClose: () => void;
  onCreateCallback: (id: string) => void;
}

function CreateNewAnalyzerModal({
  open,
  onClose,
  onCreateCallback,
}: CreateNewAnalyzerModalProps): JSX.Element {
  const [name, setName] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);
  const [analyzerType, setAnalyzerType] = useState<AnalyzerType>(
    AnalyzerType.LEGAL
  );
  const [error, setError] = useState<Error | null>(null);
  const { selectedOrg, uid } = useContext(OrgContext);

  function handleClose() {
    setName("");
    setAnalyzerType(AnalyzerType.LEGAL);
    onClose();
  }

  async function handleCreateNewType() {
    if (working) {
      return;
    }

    setWorking(true);
    setError(null);

    try {
      const analyzer: LegalAnalyzer | CustomAnalyzer = {
        id: "",
        name: name.trim(),
        owner: uid,
        readers: [uid],
        writers: [uid],
        allowOrgRead: false,
        allowOrgWrite: false,
        type: analyzerType,
        created_at: Timestamp.now(),
        ...(analyzerType === AnalyzerType.LEGAL
          ? { risk_factors: [] }
          : { question: "" }),
      };
      const result = await organization(selectedOrg)
        .analyzers()
        .create(analyzer);
      onCreateCallback(result.id);
      handleClose();
    } catch (error) {
      setError(error as Error);
    } finally {
      setWorking(false);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>New Analyzer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a name and select a type for the new analyzer.
        </DialogContentText>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          noValidate
          onSubmit={async (event) => {
            event.preventDefault();
            await handleCreateNewType();
          }}
          autoComplete="off"
        >
          <Grid container alignItems="center" spacing={2} sx={{ my: 2 }}>
            <Grid item xs={12} md={8}>
              <TextField
                autoFocus
                required
                label="Name"
                name={"name"}
                data-test-id="analyzer-name"
                disabled={working}
                type="text"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  labelId="type-label"
                  name={"type"}
                  data-test-id="analyzer-type-select"
                  required
                  disabled={working}
                  value={analyzerType}
                  onChange={(e) =>
                    setAnalyzerType(e.target.value as AnalyzerType)
                  }
                  label="Type"
                >
                  <MenuItem value={AnalyzerType.CUSTOM}>Custom</MenuItem>
                  <MenuItem value={AnalyzerType.LEGAL}>Legal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          data-test-id="analyzer-create-button"
          onClick={handleCreateNewType}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewAnalyzerModal;

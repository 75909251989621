import React, { useContext, useEffect } from "react";

import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import LoadingDialog from "../../components/LoadingDialog";
import DuplicateDialog from "../../components/dialogs/DuplicateDialog";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { useWorkload } from "../../hooks/useWorkload";
import { Analyzer } from "../../models/Models";

interface DuplicateAnalyzerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  analyzers: Analyzer[];
}

function DuplicateAnalyzerDialog({
  isOpen,
  onClose,
  analyzers,
}: DuplicateAnalyzerDialogProps): JSX.Element {
  const { selectedOrg, uid } = useContext(OrgContext);
  const navigate = useNavigate();
  const [working, error, runWorkload] = useWorkload([analyzers]);

  function handleAll() {
    runWorkload(async () => {
      await Promise.all(
        analyzers.map((analyzer) =>
          handleCreate(analyzer, analyzer.name + " Copy", false)
        )
      );
    });
  }

  // if more than one analyzer is selected just copy all of them
  useEffect(() => {
    if (analyzers.length > 1) {
      handleAll();
    }
  }, [analyzers]);

  async function handleCreate(
    analyzer: Analyzer,
    name: string,
    redirect: boolean
  ) {
    const result = await organization(selectedOrg)
      .analyzers()
      .create({
        ...analyzer,
        name: name,
        owner: uid,
        readers: [uid],
        writers: [uid],
        allowOrgRead: false,
        allowOrgWrite: false,
        created_at: Timestamp.now(),
      });

    if (redirect) {
      navigate(`/analyzers/${result.id}`);
    }
  }

  // if more than one analyzer is selected just copy all of them
  if (analyzers.length > 1) {
    return (
      <LoadingDialog
        title={"Copying Analyzers"}
        working={working}
        error={error}
        open={true}
        onClose={onClose}
      />
    );
  }

  return (
    <DuplicateDialog
      isOpen={isOpen}
      onClose={onClose}
      title={"Copy Analyzer"}
      originalName={analyzers.length === 1 ? analyzers[0].name : undefined}
      onSave={(name) => handleCreate(analyzers[0], name, true)}
    />
  );
}

export default DuplicateAnalyzerDialog;

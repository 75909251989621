import React, { ReactNode } from "react";

import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import ErrorDisplay from "../utils/ErrorDisplay";
import FlatPage from "./FlatPage";
import MinimalistPage from "./MinimalistPage";

export interface Props {
  children: ReactNode;
  pageType: "flat" | "minimalist";
  secondaryHome?: ReactNode | string;
  loading?: boolean;
  loadingMessage?: string | null;
  bottomBar?: ReactNode;
  error?: Error | string | null;
}

function AppPage({
  children,
  pageType = "flat",
  secondaryHome = undefined,
  loading = false,
  loadingMessage = "",
  bottomBar = undefined,
  error = null,
}: Props) {
  const content = (
    <>
      {loading && (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
          spacing={3}
        >
          <CircularProgress />
          <Typography>{loadingMessage}</Typography>
        </Stack>
      )}
      {error && <ErrorDisplay error={error} />}
      {!loading && !error && children}
    </>
  );

  if (pageType === "flat") {
    return (
      <FlatPage
        pageType={"flat"}
        secondaryHome={secondaryHome}
        bottomBar={bottomBar}
      >
        {content}
      </FlatPage>
    );
  } else if (pageType === "minimalist") {
    return (
      <MinimalistPage pageType={"minimalist"} secondaryHome={secondaryHome}>
        {content}
      </MinimalistPage>
    );
  } else {
    console.warn(`Unknown page type '${pageType}'`);
    return (
      <FlatPage
        pageType={"flat"}
        secondaryHome={secondaryHome}
        bottomBar={bottomBar}
      >
        {content}
      </FlatPage>
    );
  }
}

export default AppPage;

import React, { useContext, useState } from "react";

import { Tab, Tabs, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import AppPage from "../../components/navbar/AppPage";
import { OrgContext } from "../../contexts/OrgContext";
import InvitesTab from "./InvitesTab";
import ManageUsersTab from "./ManageUsersTab";

function AdminPage(): React.JSX.Element {
  const { selectedOrgConfig, loading } = useContext(OrgContext);
  const [tab, setTab] = useState<number>(0); // State for the active tab index

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const roles = selectedOrgConfig?.roles ?? {};

  return (
    <AppPage pageType={"minimalist"}>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Users" />
            <Tab label="Invitations" />
          </Tabs>

          {tab === 0 && <ManageUsersTab roles={roles} />}
          {tab === 1 && <InvitesTab />}
        </>
      )}
    </AppPage>
  );
}

export default AdminPage;

import * as React from "react";
import { useState } from "react";

import PasswordResetForm from "./PasswordResetForm";
import SignInOptions from "./PasswordSignInForm";
import PasswordSignUpForm from "./PasswordSignUpForm";

function PasswordSignIn(): React.JSX.Element {
  const [email, setEmail] = useState<string>("");
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showSignUp, setShowSignUp] = useState<boolean>(false);

  if (showResetPassword) {
    return (
      <PasswordResetForm
        email={email}
        setEmail={setEmail}
        onCancel={() => setShowResetPassword(false)}
      />
    );
  } else if (showSignUp) {
    return (
      <PasswordSignUpForm
        email={email}
        setEmail={setEmail}
        setShowSignUp={setShowSignUp}
      />
    );
  } else {
    return (
      <SignInOptions
        email={email}
        setEmail={setEmail}
        setShowResetPassword={setShowResetPassword}
        setShowSignUp={setShowSignUp}
      />
    );
  }
}

export default PasswordSignIn;

import * as React from "react";

import { Box, Divider, Stack, Typography } from "@mui/material";

import AppPage from "../../components/navbar/AppPage";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeProfileInfoForm from "./ChangeProfileInfoForm";

function ProfilePage() {
  return (
    <AppPage pageType={"minimalist"}>
      <Stack
        spacing={3}
        sx={{ justifyContent: "center", alignItems: "center", mt: 8, mb: 8 }}
      >
        <Box sx={{ justifyContent: "start", width: "100%", maxWidth: 600 }}>
          <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
            Profile
          </Typography>
        </Box>
        <ChangeProfileInfoForm />
        <Box />
        <Divider sx={{ width: "100%", maxWidth: 600 }} />
        <Box />
        <ChangePasswordForm />
      </Stack>
    </AppPage>
  );
}

export default ProfilePage;

import * as React from "react";
import { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { GlobalSnackbarContext } from "../../../components/GlobalSnackbarContext";
import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import { useWorkload } from "../../../hooks/useWorkload";
import { CustomAnalyzer } from "../../../models/Models";

function EditCustomAnalyzerDialog(props: Props) {
  const { analyzer } = props;

  const { selectedOrg } = useContext(OrgContext);
  const { showSnackbar } = useContext(GlobalSnackbarContext);
  const [working, error, runWorkload] = useWorkload([analyzer]);

  const [question, setQuestion] = useState<string>(analyzer?.question ?? "");

  useEffect(() => {
    setQuestion(analyzer?.question ?? "");
  }, [analyzer, props.open]);

  async function handleSave() {
    runWorkload(async () => {
      await organization(selectedOrg)
        .analyzer(analyzer!.id)
        .update<CustomAnalyzer>({ question: question });
      showSnackbar("Analyzer saved!");
      props.close();
    });
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>Edit {analyzer?.name}</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          onSubmit={async (e) => {
            e.preventDefault();
            await handleSave();
          }}
          sx={{ mt: 2 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Analysis Instructions"
            multiline={true}
            minRows={4}
            maxRows={8}
            disabled={working}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} disabled={working}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={working}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  open: boolean;
  close: () => void;
  analyzer: CustomAnalyzer | null;
}

export default EditCustomAnalyzerDialog;

import React, { RefObject, useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, CircularProgress } from "@mui/material";

import { OrgContext } from "../../contexts/OrgContext";
import { Project } from "../../models/Models";
import { canWrite, canWriteOrDie } from "../../utils/AclUtils";
import UploadFileModal from "./UploadFileModal";
import useFileUploader from "./useFileUploader";

const ALLOWED_TYPES: { [key: string]: string } = {
  "application/pdf": ".pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "text/markdown": ".md",
};

interface FileUploadProps {
  project?: Project;
  visible?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
}

function UploadFileButton({
  project = undefined,
  visible = true,
  inputRef = undefined,
}: FileUploadProps): JSX.Element {
  const { uid } = useContext(OrgContext);
  const fileUploaderState = useFileUploader(project?.id);
  const { setFiles, working } = fileUploaderState;
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (project) {
      canWriteOrDie(project, uid);
    }

    if (e.target.files) {
      const files: File[] = [];
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }
      setFiles(files);
    }
  };

  useEffect(() => {
    if (working) {
      setOpenDialog(true);
    }
  }, [working]);

  const canUpload = project ? canWrite(project, uid) : true;

  return (
    <Box>
      <input
        type="file"
        accept={Object.values(ALLOWED_TYPES).join(", ")}
        ref={inputRef}
        multiple
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="upload-button-file"
      />
      {visible && (
        <label htmlFor="upload-button-file">
          <Button
            variant="outlined"
            color="secondary"
            component="span"
            disabled={working || !canUpload}
            startIcon={working ? <CircularProgress size={18} /> : <AddIcon />}
          >
            Upload Document
          </Button>
        </label>
      )}
      <UploadFileModal
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        fileUploaderState={fileUploaderState}
      />
    </Box>
  );
}

export default UploadFileButton;

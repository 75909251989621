import * as React from "react";

import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

// @ts-ignore
import logo from "../public/logo-alpha-dark.png";
import { fixHref } from "./utils/Utils";

function Branding(): JSX.Element {
  const navigate = useNavigate();
  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Link to={fixHref("/")}>
        <img alt="logo" src={logo} style={{ width: "48px", height: "48px" }} />
      </Link>
      <Typography
        variant="h6"
        component="div"
        color={"primary.light"}
        sx={{ cursor: "pointer", mb: 1, pl: 1 }}
        onClick={() => navigate(fixHref("/"))}
      >
        <Box sx={{ color: "primary.main", display: "inline", fontWeight: 400 }}>
          Mysticetus
        </Box>{" "}
        AI
      </Typography>
    </Stack>
  );
}

export default Branding;

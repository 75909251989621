import { useContext, useEffect, useMemo, useState } from "react";

import { Timestamp } from "firebase/firestore";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import useFsDoc from "../../fs/useFsDoc";
import {
  ChatAgentType,
  ChatMessage,
  ChatSessionStatus,
  StatusEnum,
} from "../../models/Models";
import { sendMessage, startNewChat } from "./ChatClient";

interface ChatState {
  messages: ChatMessage[];
  sendMessage: (message: string) => Promise<string>;
  loading: boolean;
  agentType: ChatAgentType;
  error: Error | null;
  sessionId: string | undefined;
  sessionStatus: ChatSessionStatus;
}

function useAiChat(
  initialSessionId: string | undefined,
  chatType: ChatAgentType | undefined,
  context: string[]
): ChatState {
  const { selectedOrg, uid } = useContext(OrgContext);

  const [isSending, setIsSending] = useState(false);
  const [pendingMessage, setPendingMessage] = useState("");
  const [isCreatingNewSession, setIsCreatingNewSession] = useState(false);

  const [sessionId, setSessionId] = useState<string | undefined>(
    initialSessionId
  );

  useEffect(() => {
    setSessionId(initialSessionId);
  }, [chatType, initialSessionId]);

  const [session, sessionLoading, sessionError] = useFsDoc(
    organization(selectedOrg).session(sessionId)
  );

  const [messages, loadingMessages, errorMessages] = useFsCol(
    organization(selectedOrg).session(sessionId).messages(),
    {
      orderBy: "created_at",
      orderDir: "asc",
    }
  );

  const lastMessageId =
    (messages ?? []).length > 0 ? messages![messages!.length - 1].id : "";

  useEffect(() => {
    setPendingMessage("");
  }, [lastMessageId]);

  const sessionStatus: ChatSessionStatus = useMemo(() => {
    const sessionStatus = session?.message_status ?? ChatSessionStatus.READY;
    if (sessionStatus === ChatSessionStatus.READY) {
      if (isCreatingNewSession) {
        return ChatSessionStatus.CREATING;
      } else if (isSending) {
        return ChatSessionStatus.SENDING;
      }
    }
    return sessionStatus;
  }, [session, isCreatingNewSession, isSending]);

  async function handleSendMessage(message: string): Promise<string> {
    message = message.trim();
    if (message.length === 0) {
      return ""; // Don't send empty messages
    }

    setPendingMessage(message);
    setIsSending(true);

    try {
      const currentSessionId = sessionId ?? initialSessionId;

      if (!currentSessionId) {
        setIsCreatingNewSession(true);
        const newSessionId = await startNewChat(
          selectedOrg!,
          ChatAgentType.DEFAULT
        );
        setSessionId(newSessionId);
        setIsCreatingNewSession(false);
        await sendMessage(selectedOrg!, newSessionId, message, context);
        return newSessionId;
      } else {
        await sendMessage(selectedOrg!, currentSessionId, message, context);
        return currentSessionId;
      }
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    } finally {
      setIsSending(false);
    }
  }

  const agentType: ChatAgentType =
    session?.agent_id ?? chatType ?? ChatAgentType.DEFAULT;

  const resultMessages: ChatMessage[] = useMemo(() => {
    if (pendingMessage) {
      const fake: ChatMessage = {
        id: "fake",
        user_message: pendingMessage,
        model_message: null,
        status: StatusEnum.PENDING,
        citation_sources: [],
        citations: [],
        error: null,
        created_at: Timestamp.now(),
        allowOrgRead: false,
        allowOrgWrite: false,
        owner: uid,
        readers: [uid],
        writers: [uid],
      };
      return [...(messages ?? []), fake];
    }

    return messages ?? [];
  }, [pendingMessage, messages]);

  const backendError = useMemo(() => {
    return sessionStatus === ChatSessionStatus.ERROR
      ? new Error("Something went wrong. Please try again.")
      : null;
  }, [sessionStatus]);

  return {
    sessionStatus,
    messages: resultMessages,
    agentType,
    sessionId,
    loading: sessionLoading || loadingMessages,
    error: sessionError ?? errorMessages ?? backendError,
    sendMessage: handleSendMessage,
  };
}

export default useAiChat;

import * as React from "react";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import ErrorDisplay from "./utils/ErrorDisplay";

function LoadingDialog(props: Props) {
  function handleClose() {
    if (props.working) {
      console.log("can't close while working");
      return;
    }
    props.onClose();
  }

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={props.error} />
        {props.working && <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
}

interface Props {
  error: Error | null;
  working: boolean;
  onClose: () => void;
  open: boolean;
  title: string;
}

export default LoadingDialog;

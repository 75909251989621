import React, { useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Snackbar } from "@mui/material";
import { Property } from "csstype";

interface Props {
  value: string;
  visibility?: Property.Visibility;
}

function CopyButton(props: Props): React.JSX.Element {
  const [success, setSuccess] = useState<boolean>(false);

  async function updateClipboard(): Promise<void> {
    // Copy the text inside the text field
    await navigator.clipboard.writeText(props.value);
    setSuccess(true);
  }

  return (
    <>
      <IconButton
        onClick={updateClipboard}
        size="small"
        sx={{ visibility: props.visibility }}
      >
        <ContentCopyIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        message={"Copied!"}
      />
    </>
  );
}

export default CopyButton;

import React, { useContext, useMemo, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import IconMenu, { IconMenuItem } from "../../../components/IconMenu";
import SharingDialog from "../../../components/acls/SharingDialog";
import FsDeleteDialog from "../../../components/dialogs/FsDeleteDialog";
import RenameDialog from "../../../components/dialogs/RenameDialog";
import AppPage from "../../../components/navbar/AppPage";
import ErrorPage from "../../../components/utils/ErrorPage";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import useFsDoc from "../../../fs/useFsDoc";
import {
  Analyzer,
  AnalyzerType,
  CustomAnalyzer,
  LegalAnalyzer,
} from "../../../models/Models";
import DuplicateAnalyzerDialog from "../DuplicateAnalyzerDialog";
import EditCustomAnalyzerPage from "./custom/EditCustomAnalyzerPage";
import EditLegalAnalyzer from "./legal/EditLegalAnalyzer";

function EditAnalyzerPage(): JSX.Element {
  const { configId } = useParams();
  const { selectedOrg } = useContext(OrgContext);

  const [rowsToDelete, setRowsToDelete] = useState<Analyzer[]>([]);
  const [rowsToDuplicate, setRowsToDuplicate] = useState<Analyzer[]>([]);
  const [rowToRename, setRowToRename] = useState<Analyzer | null>(null);
  const [rowToShare, setRowToShare] = useState<Analyzer | null>(null);

  const [analyzer, loading, error] = useFsDoc(
    organization(selectedOrg).analyzer(configId)
  );

  let analyzerType = "Analyzer";
  switch (analyzer?.type) {
    case AnalyzerType.LEGAL:
      analyzerType = "Legal Analyzer";
      break;
    case AnalyzerType.CUSTOM:
      analyzerType = "Custom Analyzer";
      break;
    default:
      break;
  }

  const options = useMemo<IconMenuItem[]>(() => {
    return [
      {
        name: "Rename",
        icon: <EditIcon />,
        action: () => setRowToRename(analyzer),
      },
      {
        name: "Share",
        icon: <PersonAddIcon />,
        action: () => setRowToShare(analyzer),
      },
      {
        name: "Duplicate",
        icon: <FileCopyIcon />,
        action: () => setRowsToDuplicate([analyzer!]),
      },
      {
        name: "Delete",
        icon: <DeleteIcon />,
        action: () => setRowsToDelete([analyzer!]),
      },
    ];
  }, [analyzer]);

  if (!loading && !analyzer) {
    return <ErrorPage title={"Analyzer not found"} />;
  }

  return (
    <AppPage pageType={"flat"} error={error} loading={loading}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4" gutterBottom>
          {analyzer?.name}
        </Typography>
        <IconMenu id={"analyzer-menu"} menuItems={options} />
      </Stack>
      <Typography variant="subtitle2" gutterBottom>
        {analyzerType}
      </Typography>
      <Box sx={{ mb: 3 }} />
      {analyzer?.type === AnalyzerType.LEGAL && (
        <EditLegalAnalyzer legalAnalyzer={analyzer as LegalAnalyzer} />
      )}
      {analyzer?.type === AnalyzerType.CUSTOM && (
        <EditCustomAnalyzerPage customAnalyzer={analyzer as CustomAnalyzer} />
      )}
      <FsDeleteDialog<Analyzer>
        title={"Delete Analyzers"}
        rows={rowsToDelete}
        onClose={() => setRowsToDelete([])}
        collection={organization(selectedOrg).analyzers()}
        message={`Are you sure you want to delete ${rowsToDelete.length} analyzers?`}
        redirect={"/analyzers"}
      />
      <RenameDialog<Analyzer>
        isOpen={rowToRename != null}
        onClose={() => setRowToRename(null)}
        title={"Rename Analyzer"}
        originalName={rowToRename?.name}
        collection={organization(selectedOrg).analyzer(rowToRename?.id)}
        createMutation={(name) => ({ name: name })}
      />
      <DuplicateAnalyzerDialog
        isOpen={rowsToDuplicate.length > 0}
        onClose={() => setRowsToDuplicate([])}
        analyzers={rowsToDuplicate}
      />
      <SharingDialog
        open={rowToShare !== null}
        onClose={() => setRowToShare(null)}
        resourceName={rowToShare?.name ?? ""}
        docRef={organization(selectedOrg).analyzer(rowToShare?.id)}
      />
    </AppPage>
  );
}

export default EditAnalyzerPage;

import { useEffect, useRef } from "react";

export function formatEnumName(enumName: string): string {
  const whitespaceFixes = enumName
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2");
  return (
    whitespaceFixes.charAt(0).toUpperCase() +
    whitespaceFixes.slice(1).toLowerCase()
  );
}

// Validation logic for email format
export function isEmailValid(email: string): boolean {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
}

export function dateToNiceTimeAgo(date: Date): string {
  const nowSeconds = Math.max(
    (new Date().getTime() - date.getTime()) / 1000,
    0
  );
  return secondsToNiceTimeAgo(nowSeconds);
}

export function secondsToNiceTimeAgo(seconds: number): string {
  seconds = Math.round(seconds);

  if (seconds < 60) {
    return seconds === 1 ? `${seconds} second ago` : `${seconds} seconds ago`;
  } else if (seconds < 60 * 60) {
    const minutes = Math.round(seconds / 60);
    return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
  } else if (seconds < 60 * 60 * 24) {
    const hours = Math.round(seconds / 60 / 60);
    return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
  } else if (seconds < 60 * 60 * 24 * 400) {
    const days = Math.round(seconds / 60 / 60 / 24);
    return days === 1 ? `${days} day ago` : `${days} days ago`;
  } else {
    const years = Math.round(seconds / 60 / 60 / 24 / 365);
    return years === 1 ? `${years} year ago` : `${years} years ago`;
  }
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function parseFilenameExt(filename: string): string {
  const filenameSplit = filename.split(".");
  return filenameSplit[filenameSplit.length - 1];
}

export function maybeDie(): void {
  const d = Math.random();
  if (d < 0.5) {
    throw new Error("Random crash!");
  }
}

export function useInterval(callback: () => void, delayMs: number): void {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
    callback();
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    const id = setInterval(tick, delayMs);
    return () => clearInterval(id);
  }, [delayMs]);
}

export function fixHref(href: string): string {
  const { pathname, search } = fixLink(href);
  return `${pathname}${search}`;
}

export function getUrl(): string {
  const url = new URL(window.location.href);

  let host = url.protocol + "//" + url.host;
  if (url.host === "localhost") {
    host = host + ":" + url.port;
  }
  return host;
}

function fixLink(path: string): { search: string; pathname: string } {
  const currentHref = window.location.href.split("?");
  const result = new URL(currentHref[0]);
  const currentParams = new URLSearchParams(window.location.search);

  const split = path.split("?");
  if (split.length === 1) {
    result.pathname = path;
  } else {
    result.pathname = split[0];
    const params = new URLSearchParams(split[1]);
    // @ts-ignore
    for (const key of params.keys()) {
      result.searchParams.append(key, params.get(key) as string);
    }
  }

  const keepers = ["orgId", "uid"];

  for (const keeper of keepers) {
    if (currentParams.has(keeper) && !result.searchParams.has(keeper)) {
      result.searchParams.set(keeper, currentParams.get(keeper) as string);
    }
  }

  return {
    pathname: result.pathname,
    search: result.search,
  };
}

import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import RoundedBar from "../../../components/RoundedBar";
import { IFsModel } from "../../../fs/FirestoreUtils";

export interface TableAction<T extends IFsModel> {
  readonly name: string;
  readonly tooltip: string;
  readonly icon: JSX.Element;
  readonly supportsMultipleRows: boolean;
  readonly action: (rows: T[]) => Promise<void> | void;
}

interface Props<T extends IFsModel> {
  readonly selectedValues: T[];
  readonly clearSelection: () => void;
  readonly actions: TableAction<T>[];
}

function DocumentGridControls<T extends IFsModel>({
  selectedValues,
  clearSelection,
  actions,
}: Props<T>) {
  return (
    <RoundedBar
      sx={{
        visibility: selectedValues.length > 0 ? undefined : "hidden",
        mb: 2,
      }}
    >
      <Stack direction="row" spacing={1}>
        <Tooltip title="Clear selection">
          <IconButton aria-label="clear" onClick={clearSelection}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Typography
          sx={{ pt: 1 }}
        >{`${selectedValues.length} selected`}</Typography>
        {actions
          .filter(
            (action) =>
              selectedValues.length === 1 || action.supportsMultipleRows
          )
          .map((action) => {
            return (
              <Tooltip key={action.name} title={action.tooltip}>
                <IconButton
                  aria-label={action.name}
                  onClick={() => action.action(selectedValues)}
                >
                  {action.icon}
                </IconButton>
              </Tooltip>
            );
          })}
      </Stack>
    </RoundedBar>
  );
}

export default DocumentGridControls;

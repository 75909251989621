import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { UpdateData } from "firebase/firestore";

import { IFsModel } from "../../fs/FirestoreUtils";
import { FsDocRef } from "../../fs/FsDocRef";
import { useWorkload } from "../../hooks/useWorkload";
import ErrorDisplay from "../utils/ErrorDisplay";

interface Props<T extends IFsModel> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  originalName: string | undefined;
  collection: FsDocRef<T>;
  createMutation: (name: string) => UpdateData<T>;
}

function RenameDialog<T extends IFsModel>(props: Props<T>) {
  const [working, error, runWorkload] = useWorkload([props.isOpen]);
  const [name, setName] = useState(props.originalName ?? "");

  // sync the name
  useEffect(() => {
    setName(props.originalName ?? "");
  }, [props.originalName]);

  async function save() {
    runWorkload(async () => {
      const mut = props.createMutation(name.trim());
      await props.collection.update(mut);
      props.onClose();
    });
  }

  return (
    <Dialog fullWidth open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          noValidate
          onSubmit={async (event) => {
            event.preventDefault();
            await save();
          }}
          autoComplete="off"
        >
          <DialogContentText>Enter the new name:</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            disabled={working}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={save} disabled={working}>
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RenameDialog;

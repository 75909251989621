import { useMemo } from "react";

import { DocumentSnapshot } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { IFsModel } from "./FirestoreUtils";
import { FsDocRef } from "./FsDocRef";

function useFsDoc<T extends IFsModel>(
  doc: FsDocRef<T>
): [T | null, boolean, Error | null, DocumentSnapshot<T> | null] {
  // denoise the docRef to avoid unnecessary re-renders because react only does a shallow comparison and FsDocRef is an object
  const denoised = useMemo(() => {
    return doc.docRef;
  }, [doc.getPath()]);

  const [data, loading, error, snap] = useDocumentData(denoised);

  return [data ?? null, loading, error ?? null, snap ?? null];
}

export default useFsDoc;

import React, { useContext } from "react";

import { Box } from "@mui/material";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useParams } from "react-router-dom";

import PdfViewer from "../../../components/PdfViewer";
import AppPage from "../../../components/navbar/AppPage";
import useDownloadUrl from "../../../components/useDownloadUrl";
import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import ErrorPage from "../../../components/utils/ErrorPage";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import useFsDoc from "../../../fs/useFsDoc";
import AnalyzePanel from "./AnalyzePanel";

function AnalyzePage(): React.JSX.Element {
  const { docId } = useParams();
  const { selectedOrg } = useContext(OrgContext);

  const [document, loading, error, snapshot] = useFsDoc(
    organization(selectedOrg).document(docId)
  );

  const { downloadUrl, error: urlError } = useDownloadUrl(document?.blob);

  if (snapshot && !snapshot.exists()) {
    return (
      <ErrorPage
        title={"Document not found."}
        body={
          "We couldn't find the specified document. Please go back and try another."
        }
      />
    );
  }

  return (
    <AppPage
      pageType={"minimalist"}
      loading={loading}
      secondaryHome={document?.original_filename}
      loadingMessage={loading ? "Almost done!" : null}
      error={error}
    >
      <Box sx={{ mb: 2, height: "90vh" }}>
        <PanelGroup direction="horizontal">
          <Panel collapsible={true} defaultSize={30} minSize={20}>
            <ErrorDisplay error={urlError} />
            {downloadUrl && <PdfViewer pdfUrl={downloadUrl} />}
          </Panel>
          <PanelResizeHandle style={{ width: "6px" }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "grey",
                width: "100%",
                height: "100%",
                "&:hover": {
                  backgroundColor: "lightgrey",
                },
              }}
            ></Box>
          </PanelResizeHandle>
          <Panel minSize={30}>
            <AnalyzePanel document={document ?? null} />
          </Panel>
        </PanelGroup>
      </Box>
    </AppPage>
  );
}

export default AnalyzePage;

export function createAnalyzePath(documentId: string): string {
  return `/documents/${documentId}`;
}

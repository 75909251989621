import React, { useContext, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Button, Chip, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import type { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { Link, useNavigate } from "react-router-dom";

import ToggleFavoriteButton from "../../components/ToggleFavoriteButton";
import SharingDialog from "../../components/acls/SharingDialog";
import FsDeleteDialog from "../../components/dialogs/FsDeleteDialog";
import RenameDialog from "../../components/dialogs/RenameDialog";
import FsTable, {
  TABLE_COL_CREATED_AT,
  TABLE_COL_OWNER,
} from "../../components/tables/FsTable";
import { fixHref } from "../../components/utils/Utils";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { Analyzer, AnalyzerType, ResourceType } from "../../models/Models";
import { createFavoriteId } from "../../models/OrgUser";
import { whereReadAccessible } from "../../utils/AclUtils";
import { TableAction } from "../documents/list/DocumentGridControls";
import CreateNewAnalyzerModal from "./CreateNewAnalyzerModal";
import DuplicateAnalyzerDialog from "./DuplicateAnalyzerDialog";

function AnalyzersTable(): JSX.Element {
  const { selectedOrg, uid } = useContext(OrgContext);
  const navigate = useNavigate();
  const [showCreateNewModal, setShowCreateNewModal] = useState<boolean>(false);
  const [rowsToDelete, setRowsToDelete] = useState<Analyzer[]>([]);
  const [rowsToDuplicate, setRowsToDuplicate] = useState<Analyzer[]>([]);
  const [rowToRename, setRowToRename] = useState<Analyzer | null>(null);
  const [rowToShare, setRowToShare] = useState<Analyzer | null>(null);

  function onConfigCreated(configId: string) {
    navigate(`/analyzers/${configId}`);
  }

  const actions = useMemo<TableAction<Analyzer>[]>(() => {
    const result: TableAction<Analyzer>[] = [
      {
        name: "Rename",
        tooltip: "rename document",
        supportsMultipleRows: false,
        icon: <EditIcon />,
        action: (rows: Analyzer[]) => setRowToRename(rows[0]),
      },
      {
        name: "Share",
        tooltip: "share project",
        supportsMultipleRows: false,
        icon: <PersonAddIcon />,
        action: (rows: Analyzer[]) => setRowToShare(rows[0]),
      },
      {
        name: "Duplicate",
        tooltip: "Duplicate analyzer",
        supportsMultipleRows: true,
        icon: <FileCopyIcon />,
        action: (rows: Analyzer[]) => setRowsToDuplicate(rows),
      },
      {
        name: "Delete",
        tooltip: "Delete analyzer",
        supportsMultipleRows: true,
        icon: <DeleteIcon />,
        action: (rows: Analyzer[]) => setRowsToDelete(rows),
      },
    ];
    return result;
  }, []);

  return (
    <>
      <FsTable<Analyzer>
        columns={[
          ANALYZER_TABLE_COL_NAME,
          ANALYZER_TABLE_COL_TYPE,
          TABLE_COL_OWNER,
          TABLE_COL_CREATED_AT,
        ]}
        whereClause={whereReadAccessible(uid)}
        enableSelection={true}
        dataCollection={organization(selectedOrg).analyzers()}
        enableControls={true}
        extraControls={
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              variant="outlined"
              data-test-id="new-analyzer-button"
              startIcon={<AddIcon />}
              onClick={() => setShowCreateNewModal(true)}
            >
              New Analyzer
            </Button>
          </Stack>
        }
        actions={actions}
        onCellDoubleClick={(row: Analyzer) => {
          navigate(fixHref(`/analyzers/${row.id}`));
        }}
        orderByField={"created_at"}
        orderByDir={"desc"}
      />
      <CreateNewAnalyzerModal
        open={showCreateNewModal}
        onClose={() => setShowCreateNewModal(false)}
        onCreateCallback={onConfigCreated}
      />
      <FsDeleteDialog<Analyzer>
        title={"Delete Analyzers"}
        rows={rowsToDelete}
        onClose={() => setRowsToDelete([])}
        collection={organization(selectedOrg).analyzers()}
        message={`Are you sure you want to delete ${rowsToDelete.length} analyzers?`}
      />
      <RenameDialog<Analyzer>
        isOpen={rowToRename != null}
        onClose={() => setRowToRename(null)}
        title={"Rename Analyzer"}
        originalName={rowToRename?.name}
        collection={organization(selectedOrg).analyzer(rowToRename?.id)}
        createMutation={(name) => ({ name: name })}
      />
      <DuplicateAnalyzerDialog
        isOpen={rowsToDuplicate.length > 0}
        onClose={() => setRowsToDuplicate([])}
        analyzers={rowsToDuplicate}
      />
      <SharingDialog
        open={rowToShare !== null}
        onClose={() => setRowToShare(null)}
        resourceName={rowToShare?.name ?? ""}
        docRef={organization(selectedOrg).analyzer(rowToShare?.id)}
      />
    </>
  );
}

export default AnalyzersTable;

export const ANALYZER_TABLE_COL_NAME: GridColDef = {
  field: "name",
  headerName: "Name",
  flex: 1,
  width: 400,
  renderCell: (params) => {
    return (
      <Stack direction={"row"} spacing={1}>
        <ToggleFavoriteButton
          id={createFavoriteId(params.row.id, ResourceType.ANALYZER)}
        />
        <Link to={fixHref(`/analyzers/${params.row.id}`)}>{params.value}</Link>
      </Stack>
    );
  },
};

export const ANALYZER_TABLE_COL_TYPE: GridColDef<
  GridValidRowModel,
  AnalyzerType
> = {
  field: "type",
  headerName: "Type",
  width: 200,
  renderCell: (params) => {
    if (params.value === AnalyzerType.LEGAL) {
      return <Chip label="LEGAL" color={"secondary"} variant={"outlined"} />;
    } else if (params.value === AnalyzerType.CUSTOM) {
      return <Chip label="CUSTOM" color={"primary"} variant={"outlined"} />;
    }
    return null;
  },
};

import React, { ReactNode, useContext, useMemo, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { QueryFieldFilterConstraint } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { FsCollectionRef } from "../../fs/FsCollectionRef";
import { DocumentObj, ResourceType } from "../../models/Models";
import { createFavoriteId } from "../../models/OrgUser";
import { createAnalyzePath } from "../../pages/documents/analyze/AnalyzePage";
import { TableAction } from "../../pages/documents/list/DocumentGridControls";
import DocumentRenameModal from "../../pages/documents/list/DocumentRenameModal";
import { whereReadAccessible } from "../../utils/AclUtils";
import ToggleFavoriteButton from "../ToggleFavoriteButton";
import SharingDialog from "../acls/SharingDialog";
import FsDeleteDialog from "../dialogs/FsDeleteDialog";
import StatusChip from "../projects/SourceStatusChip";
import NiceDateAgo from "../utils/NiceDateAgo";
import { fixHref } from "../utils/Utils";
import FsTable from "./FsTable";

interface Props {
  readonly columns: GridColDef[];
  readonly enableSelection: boolean;
  readonly extraControls?: ReactNode;
  readonly pageSizeOptions?: number[];
  readonly defaultPageSize?: number;
  readonly enableControls: boolean;
  readonly dataGridProps?: any;
  readonly projectId?: string | null;
  readonly whereClause?: QueryFieldFilterConstraint[];
}

function DocumentsTable({
  columns,
  enableSelection = true,
  pageSizeOptions = [5, 10, 25, 100],
  enableControls = true,
  extraControls,
  defaultPageSize = 10,
  projectId = null,
  dataGridProps = {},
}: Props) {
  const { selectedOrg, uid } = useContext(OrgContext);
  const navigate = useNavigate();

  const [rowsToDelete, setRowsToDelete] = useState<DocumentObj[]>([]);
  const [rowToRename, setRowToRename] = useState<DocumentObj | null>(null);
  const [rowToShare, setRowToShare] = useState<DocumentObj | null>(null);

  const documentsCollection: FsCollectionRef<DocumentObj> = projectId
    ? organization(selectedOrg).project(projectId).documents()
    : organization(selectedOrg).documents();

  /**
   * @type {TableAction[]}
   */
  const actions = useMemo(() => {
    const result: TableAction<DocumentObj>[] = [];
    result.push({
      name: "Rename",
      tooltip: "rename document",
      supportsMultipleRows: false,
      icon: <EditIcon />,
      action: (rows) => setRowToRename(rows[0]),
    });
    result.push({
      name: "Share",
      tooltip: "share project",
      supportsMultipleRows: false,
      icon: <PersonAddIcon />,
      action: (rows) => setRowToShare(rows[0]),
    });
    result.push({
      name: "Delete",
      tooltip: "Delete documents",
      supportsMultipleRows: true,
      icon: <DeleteIcon />,
      action: (rows) => setRowsToDelete(rows),
    });
    return result;
  }, []);

  return (
    <>
      <FsTable<DocumentObj>
        columns={columns}
        enableSelection={enableSelection}
        dataCollection={documentsCollection}
        enableControls={enableControls}
        extraControls={extraControls}
        defaultPageSize={defaultPageSize}
        pageSizeOptions={pageSizeOptions}
        actions={actions}
        whereClause={whereReadAccessible(uid)}
        onCellDoubleClick={(row) => {
          navigate(createAnalyzePath(row.id));
        }}
        orderByDir={"desc"}
        orderByField={"uploaded_at"}
        dataGridProps={dataGridProps}
      />
      <FsDeleteDialog<DocumentObj>
        title={"Delete Documents"}
        rows={rowsToDelete}
        onClose={() => setRowsToDelete([])}
        collection={organization(selectedOrg).documents()}
        message={`Are you sure you want to delete ${rowsToDelete.length} documents?`}
      />
      <DocumentRenameModal
        document={rowToRename}
        isOpen={rowToRename != null}
        onClose={() => setRowToRename(null)}
      />
      <SharingDialog
        open={rowToShare !== null}
        onClose={() => setRowToShare(null)}
        resourceName={rowToShare?.original_filename}
        docRef={organization(selectedOrg).document(rowToShare?.id)}
      />
    </>
  );
}

export default DocumentsTable;

export const DOCUMENT_TABLE_COL_NAME: GridColDef = {
  field: "original_filename",
  headerName: "Name",
  flex: 1,
  width: 400,
  renderCell: (params) => {
    return (
      <Stack direction={"row"} spacing={1}>
        <ToggleFavoriteButton
          id={createFavoriteId(params.row.id, ResourceType.DOCUMENT)}
        />
        <Link to={fixHref(createAnalyzePath(params.row.id))}>
          {params.value}
        </Link>
      </Stack>
    );
  },
};

export const PROJECT_SOURCE_TABLE_COL_STATUS: GridColDef = {
  field: "status",
  headerName: "status",
  flex: 1,
  width: 400,
  renderCell: (params) => {
    return <StatusChip status={params.value} />;
  },
};

export const DOCUMENT_TABLE_COL_UPLOADED_AT: GridColDef = {
  field: "uploaded_at",
  headerName: "Uploaded At",
  width: 200,
  renderCell: (params) => {
    const date = params.value?.toDate();
    return <NiceDateAgo date={date} />;
  },
};

import React, { useContext } from "react";

import { Typography } from "@mui/material";
import { arrayRemove } from "firebase/firestore";

import ConfirmDeleteDialog from "../../../../components/dialogs/ConfirmDeleteDialog";
import { OrgContext } from "../../../../contexts/OrgContext";
import { organization } from "../../../../fs/FirestoreUtils";
import { LegalAnalyzer, RiskFactor } from "../../../../models/Models";

interface DeleteRiskFactorDialogProps {
  legalAnalyzer: LegalAnalyzer;
  riskFactor: RiskFactor;
  open: boolean;
  onClose: () => void;
}

function DeleteRiskFactorDialog({
  legalAnalyzer,
  riskFactor,
  open,
  onClose,
}: DeleteRiskFactorDialogProps): JSX.Element {
  const { selectedOrg } = useContext(OrgContext);

  const handleDelete = async () => {
    await organization(selectedOrg)
      .analyzer(legalAnalyzer.id)
      .update<LegalAnalyzer>({ risk_factors: arrayRemove(riskFactor) });
  };

  return (
    <ConfirmDeleteDialog
      title={`Delete Risk Factor ${riskFactor.name}?`}
      message={
        <Typography gutterBottom>
          Are you sure you want to delete this risk factor?
        </Typography>
      }
      open={open}
      onClose={onClose}
      onDelete={handleDelete}
    />
  );
}

export default DeleteRiskFactorDialog;

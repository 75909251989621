import { Timestamp } from "firebase/firestore";
import { StorageReference, ref, uploadBytesResumable } from "firebase/storage";

import { auth, storage } from "../../configs/firebase";
import { organization } from "../../fs/FirestoreUtils";
import { DocumentObj, ProjectDocument, UserId } from "../../models/Models";
import { sendApiRequest } from "../utils/FetchUtils";
import { FileState } from "./useFileUploader";

export const MAX_FILE_SIZE_MB = 50;

/**
 *
 * @param {FileState} fileState
 * @param {(progress: number) => void} progressCallback
 * @returns {Promise<void>}
 */
export async function uploadFile(
  fileState: FileState,
  progressCallback: (progress: number) => void
): Promise<void> {
  if (fileState.file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
    const error = new Error("File size too large. Max size is 50 MB.");
    error.code = "app/file-size-too-large";
    return Promise.reject(error);
  }

  const fileExtension = fileState.file.name.split(".").pop();
  const storagePath = `${fileState.org}/${
    fileState.projectId
      ? `projects/${fileState.projectId}/${fileState.id}`
      : `documents/${fileState.id}`
  }/file.${fileExtension}`;

  const storageRef = ref(storage, storagePath);
  // Create file metadata to update
  const metadata = {
    // 1 year cache because these files don't change
    cacheControl: "private,max-age=31536000",
  };

  const uploadTask = uploadBytesResumable(storageRef, fileState.file, metadata);

  console.log(`Uploading file ${fileState.file.name} with id ${fileState.id}`);

  await new Promise<void>((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progressPercent =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback(progressPercent);
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          await onFileUploadComplete(fileState, storageRef);
          resolve();
        } catch (e) {
          reject(e);
        }
      }
    );
  });
  console.log(`Done uploadFile ${fileState.file.name}`);
}

/**
 *
 * @param {FileState} fileState
 * @param {StorageReference} storageRef
 * @returns {Promise<void>}
 */
async function onFileUploadComplete(
  fileState: FileState,
  storageRef: StorageReference
): Promise<void> {
  const user = auth.currentUser;
  const uid = user!.uid as UserId;

  try {
    if (fileState.projectId) {
      const uploadedFileData: ProjectDocument = {
        id: fileState.id,
        owner: uid,
        bucket: storageRef.bucket,
        blob: storageRef.fullPath,
        original_filename: fileState.file.name,
        uploaded_at: Timestamp.now(),
        readers: [uid],
        writers: [uid],
        allowOrgRead: false,
        allowOrgWrite: false,
      };

      await organization(fileState.org)
        .project(fileState.projectId)
        .document(fileState.id)
        .set(uploadedFileData);
    } else {
      const uploadedFileData: DocumentObj = {
        id: fileState.id,
        owner: uid,
        bucket: storageRef.bucket,
        blob: storageRef.fullPath,
        original_filename: fileState.file.name,
        uploaded_at: Timestamp.now(),
        readers: [uid],
        writers: [uid],
        allowOrgRead: false,
        allowOrgWrite: false,
      };

      await organization(fileState.org)
        .document(fileState.id)
        .set(uploadedFileData);
    }

    await sendApiRequest("/handle-upload", {
      org_id: fileState.org,
      document_id: fileState.id,
      project_id: fileState.projectId,
    });
  } catch (error) {
    return Promise.reject(error);
  }
  console.log(`Done onFileUploadComplete ${fileState.file.name}`);
}

import { EnvType, getEnv } from "./firebase";

interface Config {
  apiUrl: string;
}

const dev: Config = {
  apiUrl: "http://0.0.0.0:8000",
};
const staging: Config = {
  apiUrl: "https://api-162371902297.us-central1.run.app",
};
const prod: Config = {
  apiUrl: "https://api-117160743997.us-central1.run.app",
};

function getConfig() {
  switch (getEnv()) {
    case EnvType.DEV:
      return dev;
    case EnvType.STAGING:
      return staging;
    case EnvType.PROD:
      return prod;
  }
}

export const config: Config = getConfig();

console.info("api url:", config.apiUrl);

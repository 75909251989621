import React, { useEffect } from "react";

import { Alert } from "@mui/material";

import { MAX_FILE_SIZE_MB } from "../upload/uploadUtils";

interface Props {
  error: Error | string | null | undefined;
}

function ErrorDisplay({ error }: Props) {
  let msg = error ? "Something went wrong." : "";

  if (typeof error === "string") {
    msg = error;
  } else if (error?.safeMessage) {
    msg = error.safeMessage;
  } else if (error) {
    switch (error.code) {
      case "permission-denied":
        msg = "Permission denied.";
        break;
      case "auth/invalid-email":
        msg = "Invalid email.";
        break;
      case "auth/invalid-credential":
        msg = "Invalid credentials.";
        break;
      case "auth/missing-password":
        msg = "Missing password.";
        break;
      case "auth/email-already-in-use":
        msg = "Email already in use.";
        break;
      case "auth/user-disabled":
        msg = "Your account has been disabled.";
        break;
      case "storage/unauthorized":
        msg = "File access not allowed.";
        break;
      // this is a code we invented arbitrarily for our backend API
      case "api/error":
        msg = "Unable to reach API.";
        break;
      case "api/400":
        msg = "API Error: bad request.";
        break;
      case "api/401":
        msg = "API Error: unauthorized.";
        break;
      case "api/403":
        msg = "API Error: forbidden.";
        break;
      case "api/404":
        msg = "API Error: resource not found.";
        break;
      case "api/405":
        msg = "API Error: not allowed.";
        break;
      case "api/408":
        msg = "API Error: request timed out. Please try again.";
        break;
      case "api/412":
        msg = "API Error: failed precondition.";
        break;
      case "api/413":
        msg = "API Error: content too large.";
        break;
      case "api/422":
        msg = "API Error: Unprocessable content.";
        break;
      case "api/429":
        msg = "API Error: too many requests. Please try again shortly.";
        break;
      case "api/500":
        msg = "API Error: server error.";
        break;
      case "api/503":
        msg = "API Error: server unavailable.";
        break;
      case "api/504":
        msg = "API Error: gateway timeout.";
        break;
      case "app/file-size-too-large":
        msg = `File size must be less than ${MAX_FILE_SIZE_MB} MB.`;
        break;
      default:
        if (typeof error.code === "string" && error.code.startsWith("api/")) {
          msg = `API Error: code: ${error.code.substring(4)}`;
        } else {
          console.error(`unexpected error.code: ${error}`);
          msg = "Something went wrong.";
        }
    }
  }

  // log the error
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <>
      {msg && (
        <Alert severity="error" sx={{ mt: 3, mb: 3 }}>
          {msg}
        </Alert>
      )}
    </>
  );
}

export default ErrorDisplay;

import React, { useContext, useMemo } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { OrgContext } from "../../contexts/OrgContext";
import { OrgUserProfilesContext } from "../../contexts/OrgUserProfilesContext";
import { FsDocRef } from "../../fs/FsDocRef";
import { AclResource } from "../../models/AclResource";
import { UserId } from "../../models/Models";
import {
  AclType,
  canWrite,
  getExplicitAcl,
  setUserAcl,
} from "../../utils/AclUtils";
import { GlobalSnackbarContext } from "../GlobalSnackbarContext";
import UserAvatar from "../UserAvatar";

interface UserAclDisplayProps {
  firestorePath: FsDocRef<AclResource>;
  aclResource: AclResource;
  uid: UserId;
  disabled: boolean;
}

function UserAclDisplay({
  firestorePath,
  aclResource,
  uid,
  disabled,
}: UserAclDisplayProps) {
  const { getUser } = useContext(OrgUserProfilesContext);
  const { uid: currentUser } = useContext(OrgContext);
  const { showSnackbar } = useContext(GlobalSnackbarContext);

  const user = useMemo(() => {
    return getUser(uid);
  }, [uid, getUser]);

  async function handleChange(acl: AclType | null) {
    await setUserAcl(aclResource, firestorePath, uid, acl);
    showSnackbar("Access updated");
  }

  let disabledReason = "";
  if (!canWrite(aclResource, currentUser)) {
    disabledReason = "You don't have permission to change access.";
  } else if (aclResource.owner === uid) {
    disabledReason = "Cannot remove owner access.";
  }

  return (
    <Box>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <UserAvatar uid={uid} />
            <Typography>
              {user.name}
              {aclResource.owner === uid ? " (Owner)" : ""}
            </Typography>
          </Stack>
        </Box>

        <Box>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Tooltip title={disabledReason}>
              <Select
                labelId="org-acl-label"
                id="org-acl-select"
                value={getExplicitAcl(aclResource, uid)}
                label="Organization"
                disabled={disabled || disabledReason !== ""}
                onChange={(e) => handleChange(e.target.value as AclType)}
              >
                <MenuItem value={AclType.READER}>Viewer</MenuItem>
                <MenuItem value={AclType.WRITER}>Editor</MenuItem>
              </Select>
            </Tooltip>
            <Box>
              <Tooltip title={disabledReason ?? "Remove access"}>
                <span>
                  <IconButton
                    onClick={() => handleChange(null)}
                    disabled={disabled || disabledReason !== ""}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default UserAclDisplay;

import React, { ReactNode, useContext } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";

import { auth } from "../../configs/firebase";
import { OrgContext } from "../../contexts/OrgContext";
// @ts-ignore
import logo from "../../public/logo-alpha.png";
import Branding from "../Branding";
import { fixHref } from "../utils/Utils";
import NavBarAvatar from "./NavBarAvatar";
import NavBarItems from "./NavBarItems";
import NavBarOrgPicker from "./NavBarOrgPicker";

const drawerWidth = 240;

interface Props {
  readonly secondaryHome?: ReactNode | string;
}

function NavBar({ secondaryHome }: Props) {
  const [user] = useAuthState(auth);
  const { selectedOrg } = useContext(OrgContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const showControls = user !== null && selectedOrg !== null;

  if (typeof secondaryHome === "string") {
    secondaryHome = (
      <Typography
        noWrap
        variant={"h6"}
        sx={{
          fontWeight: 800,
          maxWidth: "300px",
          textDecoration: "none",
          textOverflow: "ellipsis",
          color: "primary.contrastText",
        }}
      >
        {secondaryHome}
      </Typography>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" id={"app-nav-bar"}>
        <Toolbar>
          {showControls && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link to={fixHref("/")}>
            <img alt="logo" src={logo} style={{ width: "48px" }} />
          </Link>
          {secondaryHome && (
            <Box sx={{ flexShrink: 0, ml: 3 }}>{secondaryHome}</Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <NavBarOrgPicker color={"white"} />
          <NavBarAvatar />
        </Toolbar>
      </AppBar>
      {showControls && (
        <nav>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
              <Branding />
              <NavBarItems />
            </Box>
          </Drawer>
        </nav>
      )}
    </Box>
  );
}

export default NavBar;

import React from "react";

import GoogleIcon from "@mui/icons-material/Google";
import { Button } from "@mui/material";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { auth } from "../../configs/firebase";

function SignInWithGoogleButton(): JSX.Element {
  const handleGoogleSignIn = async (): Promise<void> => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handleGoogleSignIn}
      startIcon={<GoogleIcon />}
      sx={{
        backgroundColor: "#4285F4",
        color: "white",
        "&:hover": {
          backgroundColor: "#3367D6",
        },
      }}
    >
      Sign in with Google
    </Button>
  );
}

export default SignInWithGoogleButton;

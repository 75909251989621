import { useEffect, useMemo, useState } from "react";

import {
  QueryCompositeFilterConstraint,
  QueryFieldFilterConstraint,
} from "@firebase/firestore";
import { OrderByDirection, orderBy } from "firebase/firestore";

import { IFsModel } from "../../fs/FirestoreUtils";
import { FsCollectionRef } from "../../fs/FsCollectionRef";
import useFsCol from "../../fs/useFsCol";
import { FsQuery } from "./FsQuery";

interface PaginationState {
  readonly type: "prev" | "next";
  readonly item: any;
}

export interface PaginatedCollectionState<T> {
  readonly pageNum: number;
  readonly error: Error | null;
  readonly loading: boolean;
  readonly totalCount: number;
  // undefined if we don't know
  readonly hasNextPage: boolean | undefined;
  readonly items: T[];
  readonly pageSize: number;
  readonly setPageSize: (size: number) => void;
  readonly prevPage: () => void;
  readonly nextPage: () => void;
}

// RIP: https://github.com/zen-send/zen-send-app/blob/c4c8536b54b71396cb970438987ed7a10c463311/app/src/hooks/usePaginatedCollection.ts
function usePaginatedCollection<T extends IFsModel>(
  collection: FsCollectionRef<T>,
  whereClause:
    | QueryCompositeFilterConstraint
    | QueryFieldFilterConstraint
    | undefined,
  defaultPageSize: number,
  orderByField: string,
  orderByDir: OrderByDirection
): PaginatedCollectionState<T> {
  const [paginationState, setPaginationState] =
    useState<PaginationState | null>(null);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    if (pageNum > 0) {
      setPageNum(0);
      setPaginationState(null);
    }
  }, [pageSize, orderBy, orderByDir]);

  const q: FsQuery = {
    where: whereClause,
    orderBy: orderByField,
    orderDir: orderByDir,
    endAt: paginationState?.type === "prev" ? paginationState.item : undefined,
    startAfter:
      paginationState?.type === "next" ? paginationState.item : undefined,
    limit: pageSize + 1,
  };

  const [, loading, error, collectionSnapshot] = useFsCol(collection, q);

  const snapshotSize = collectionSnapshot?.docs.length;

  const items = useMemo(() => {
    // drop the extra item we took for next page detection
    return (collectionSnapshot?.docs ?? [])
      .map((d) => d.data())
      .slice(0, pageSize);
  }, [collectionSnapshot]);

  const hasNextPage: boolean | undefined = !loading
    ? snapshotSize === pageSize + 1
    : undefined;

  const totalCount: number =
    hasNextPage === false ? pageSize * pageNum + items.length : -1;

  function nextPage() {
    console.log("nextPage");
    // -2 because we got an extra document for nextPage detection
    const doc = collectionSnapshot!.docs[collectionSnapshot!.docs!.length - 2];
    setPaginationState({
      type: "next",
      // @ts-ignore
      item: doc.data()[orderByField],
    });
    setPageNum(pageNum + 1);
  }

  function prevPage() {
    console.log("prevPage");
    const doc = collectionSnapshot!.docs[0];
    setPaginationState({
      type: "prev",
      // @ts-ignore
      item: doc.data()[orderByField],
    });
    setPageNum(pageNum - 1);
  }

  return {
    totalCount,
    prevPage,
    nextPage,
    loading,
    error,
    pageNum,
    pageSize,
    setPageSize,
    hasNextPage,
    items,
  };
}

export default usePaginatedCollection;

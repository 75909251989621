import React, { useContext, useMemo, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { OrgContext } from "../../contexts/OrgContext";
import { OrgUserProfilesContext } from "../../contexts/OrgUserProfilesContext";
import { FsDocRef } from "../../fs/FsDocRef";
import useFsDoc from "../../fs/useFsDoc";
import { AclResource } from "../../models/AclResource";
import { UserId, makeUserId } from "../../models/Models";
import {
  AclType,
  canWrite,
  getOrgAcl,
  setUserAcl,
  updateOrgAcl,
} from "../../utils/AclUtils";
import { GlobalSnackbarContext } from "../GlobalSnackbarContext";
import ErrorDisplay from "../utils/ErrorDisplay";
import UserAclDisplay from "./UserAclDisplay";

const RestrictedOrgAcl = "RESTRICTED";

interface SharingDialogProps {
  open: boolean;
  onClose: () => void;
  resourceName: string | undefined;
  docRef: FsDocRef<AclResource>;
}

function SharingDialog({
  open,
  onClose,
  resourceName,
  docRef,
}: SharingDialogProps) {
  const { uid } = useContext(OrgContext);

  const [aclResource, , docError] = useFsDoc<AclResource>(docRef);
  const { users, getUser } = useContext(OrgUserProfilesContext);
  const { showSnackbar } = useContext(GlobalSnackbarContext);

  const [error, setError] = useState<Error | null>(null);
  const [working, setWorking] = useState(false);

  const orgAcl = useMemo(() => {
    return aclResource
      ? (getOrgAcl(aclResource) ?? RestrictedOrgAcl)
      : RestrictedOrgAcl;
  }, [aclResource, open]);

  const uniqueUsers = useMemo<UserId[]>(() => {
    if (!aclResource) {
      return [];
    }

    const result = new Set<UserId>();

    for (const uid of (aclResource.readers ?? []).concat(
      aclResource.writers ?? []
    )) {
      if (!result.has(uid)) {
        result.add(uid);
      }
    }
    return Array.from(result)
      .map(getUser)
      .sort((a, b) => b.id.localeCompare(a.id))
      .map((u) => u.id);
  }, [aclResource, open]);

  async function handleOrgChange(
    event: React.MouseEvent<HTMLElement>,
    newAcl: string | null
  ) {
    if (!newAcl) {
      return null;
    }

    if (newAcl === RestrictedOrgAcl) {
      newAcl = null;
    }

    await run(async () => {
      await updateOrgAcl(docRef, newAcl as AclType);
      showSnackbar("Access updated");
    });
  }

  async function handleUserSelected(uid: UserId) {
    await run(async () => {
      await setUserAcl(aclResource!, docRef, uid, AclType.READER);
      showSnackbar("User access added");
    });
  }

  async function run(fn: () => Promise<void>): Promise<void> {
    if (working) {
      return;
    }

    setWorking(true);
    setError(null);
    try {
      await fn();
    } catch (e) {
      setError(e as Error);
    } finally {
      setWorking(false);
    }
  }

  const options = users
    .filter((u) => !uniqueUsers.includes(u.id))
    .map((u) => ({ label: u.name ?? "Unknown", ...u }));

  let orgAccessDescription = "";

  switch (orgAcl) {
    case AclType.READER:
      orgAccessDescription =
        "All users in your organization can view this resource but only users with explicitly granted edit access can edit it.";
      break;
    case AclType.WRITER:
      orgAccessDescription =
        "All users in your organization can view and edit this resource";
      break;
    case RestrictedOrgAcl:
      orgAccessDescription =
        "Only users with explicitly granted access can view and edit this resource.";
      break;
  }

  let disabledReason = "";
  if (aclResource && !canWrite(aclResource, uid)) {
    disabledReason = "You don't have permission to change access.";
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>Share &quot;{resourceName}&quot;</DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error ?? docError} />
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          sx={{ mb: 1 }}
        >
          <Typography variant={"h6"}>Organization Access</Typography>
          <Tooltip
            title={
              "Default access that people in your organization get. Use this if you want everyone in your org to view or edit the resource."
            }
          >
            <InfoIcon color={"disabled"} sx={{ fontSize: "14pt" }} />
          </Tooltip>
        </Stack>
        <ToggleButtonGroup
          color="primary"
          value={orgAcl}
          exclusive
          onChange={handleOrgChange}
          aria-label="Org ACL"
        >
          <Tooltip
            title={
              disabledReason ??
              "Only users with explicit access can view or edit this resource."
            }
          >
            <span>
              <ToggleButton
                value={RestrictedOrgAcl}
                disabled={working || disabledReason !== ""}
              >
                Restricted
              </ToggleButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              disabledReason ??
              "All users in this org can view but only users with explicit access can edit this resource."
            }
          >
            <span>
              <ToggleButton
                value={AclType.READER}
                disabled={working || disabledReason !== ""}
              >
                View
              </ToggleButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              disabledReason ??
              "All users in this org can view and edit this resource."
            }
          >
            <span>
              <ToggleButton
                value={AclType.WRITER}
                disabled={working || disabledReason !== ""}
              >
                Editors
              </ToggleButton>
            </span>
          </Tooltip>
        </ToggleButtonGroup>
        <Typography variant={"body2"} color={"text.secondary"}>
          {orgAccessDescription}
        </Typography>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          sx={{ mb: 1, mt: 2 }}
        >
          <Typography variant={"h6"}>User Access</Typography>
          <Tooltip
            title={
              "Explicitly control each user's access level. If you want to share this resource with a lot of people, consider using Organization Access controls."
            }
          >
            <InfoIcon color={"disabled"} sx={{ fontSize: "14pt" }} />
          </Tooltip>
        </Stack>
        <Autocomplete
          disablePortal
          options={options}
          sx={{ mb: 2 }}
          value={null}
          onChange={async (event, newValue) => {
            await handleUserSelected(makeUserId(newValue?.id));
          }}
          renderOption={(props, option) => {
            const { ...optionProps } = props;
            return (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...optionProps}
              >
                <Avatar
                  src={option.avatar_url!}
                  alt=""
                  sx={{ mr: 1, width: "20px", height: "20px" }}
                />
                {option.label} ({option.email})
              </Box>
            );
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Choose a User"
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                  },
                }}
              />
            );
          }}
        />
        <List>
          {uniqueUsers.map((uid) => {
            return (
              <Box key={uid} sx={{ mb: 1 }}>
                <UserAclDisplay
                  aclResource={aclResource!}
                  uid={uid}
                  firestorePath={docRef}
                  disabled={working}
                />
              </Box>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={working}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SharingDialog;

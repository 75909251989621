import React from "react";

import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Property } from "csstype";

function RoundedBar(props: Props) {
  return (
    <Box
      sx={{
        borderRadius: "32px",
        pl: 2,
        backgroundColor: "#c0eae4",
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
}

interface Props {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export default RoundedBar;

import * as React from "react";
import { useContext, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import { Project } from "../../models/Models";
import { whereReadAccessible } from "../../utils/AclUtils";
import FavoriteableListItemButton from "../FavoriteableListItemButton";
import RoundedBar from "../RoundedBar";

function KnowledgeBoostPickerDialog(props: Props) {
  const { selectedOrg, uid } = useContext(OrgContext);

  const [selected, setSelected] = React.useState<string[]>([]);

  useEffect(() => {
    setSelected(props.currentSelected);
  }, [props.open]);

  function handleClose() {
    props.close();
  }

  const projectsCol = organization(selectedOrg).projects();

  const [projects, , error] = useFsCol(projectsCol, {
    where: whereReadAccessible(uid),
    orderBy: "created_at",
    orderDir: "desc",
  });

  function handleApply() {
    // dedupe
    const set = new Set(selected);
    props.callback(Array.from(set));
    handleClose();
  }

  function toggleSelected(project: Project) {
    setSelected((prev) => {
      const checked = selected.includes(project.id);
      if (checked) {
        return prev.filter((p) => p !== project.id);
      }
      return prev.concat([project.id]);
    });
  }

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle>Knowledge Boost</DialogTitle>
      <DialogContent>
        <Alert severity={"info"} sx={{ mb: 2 }}>
          Boost your AI&apos;s knowledge by adding Knowledge Hubs to it&apos;s
          context. For best results, only add relevant hubs.
        </Alert>
        <RoundedBar sx={{ mx: 2, mb: 2 }}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography>{`${selected.length} Boosts Selected`}</Typography>
            <Button onClick={() => setSelected([])}>Clear</Button>
          </Stack>
        </RoundedBar>
        <List>
          {projects.map((project) => {
            const checked = selected.includes(project.id);
            return (
              <FavoriteableListItemButton
                key={project.id}
                value={project}
                onClick={() => toggleSelected(project)}
                collection={projectsCol}
                primaryText={project.name}
                secondaryText={project.description}
                endContent={
                  <Switch
                    checked={checked}
                    onChange={(e) => {
                      // no-op because parent item handles click
                    }}
                  />
                }
              />
            );
          })}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.close}>Cancel</Button>
        <Button onClick={handleApply}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  readonly open: boolean;
  readonly close: () => void;
  readonly currentSelected: string[];
  readonly callback: (hubs: string[]) => void;
}

export default KnowledgeBoostPickerDialog;

import * as React from "react";

import { Chip } from "@mui/material";

import { ChatAgentType } from "../../models/Models";
import { getChatConfig } from "./AiChatType";

function ChatAgentChip(props: Props) {
  const chatAgentConfig = getChatConfig(props.chatAgentType);

  return (
    <Chip
      variant="outlined"
      size={"small"}
      icon={chatAgentConfig.icon}
      label={chatAgentConfig.name}
      color={chatAgentConfig.color}
    />
  );
}

interface Props {
  chatAgentType: ChatAgentType;
}

export default ChatAgentChip;

import * as React from "react";

import { Alert, Box, Typography } from "@mui/material";

import AppPage from "../../components/navbar/AppPage";
import AnalyzersTable from "./AnalyzersTable";

function AnalyzersPage() {
  return (
    <AppPage pageType={"flat"}>
      <Typography variant="h4" gutterBottom>
        Analyzers
      </Typography>
      <Alert severity={"info"} sx={{ maxWidth: "800px" }}>
        Analyzers provide a repeatable, managed method of analyzing your
        documents.
      </Alert>
      <Box sx={{ mt: 2 }} />
      <AnalyzersTable />
    </AppPage>
  );
}

export default AnalyzersPage;

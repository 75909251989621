import React, { useContext, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import UserAvatar from "../../components/UserAvatar";
import { sendApiRequest } from "../../components/utils/FetchUtils";
import { OrgContext } from "../../contexts/OrgContext";
import { OrgUserProfilesContext } from "../../contexts/OrgUserProfilesContext";
import { UserId, UserRole } from "../../models/Models";
import { OrgUser } from "../../models/OrgUser";
import ConfirmDeleteUserDialog from "./ConfirmDeleteUserDialog";

interface OrgUserRowProps {
  uid: UserId;
  role: UserRole;
}

function OrgUserRow({ uid, role }: OrgUserRowProps) {
  const { selectedOrg } = useContext(OrgContext);

  const { users } = useContext(OrgUserProfilesContext);

  const userProfile: OrgUser | undefined = users.find((u) => u.id === uid);

  const [currentRole, setCurrentRole] = useState<UserRole>(role);
  const [updatingRole, setUpdatingRole] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);

  async function handleChangeRole(event: SelectChangeEvent<UserRole>) {
    const newRole = event.target.value as UserRole;
    setUpdatingRole(true);
    setCurrentRole(newRole);

    try {
      await sendApiRequest("/admin/update-user-role", {
        org_id: selectedOrg,
        user_id: uid,
        role: newRole,
      });
    } catch (error) {
      console.error("Error updating role:", error);
      setCurrentRole(role);
    } finally {
      setUpdatingRole(false);
    }
  }

  return (
    <TableRow key={uid}>
      <TableCell>
        <UserAvatar uid={uid} />
      </TableCell>
      <TableCell>{userProfile?.name ?? "Unknown"}</TableCell>
      <TableCell>{userProfile?.email}</TableCell>
      <TableCell>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor={`${uid}-role-select`}>Role</InputLabel>
          <Select
            value={currentRole}
            onChange={handleChangeRole}
            disabled={updatingRole}
            inputProps={{ id: `${uid}-role-select` }}
          >
            <MenuItem value="USER">USER</MenuItem>
            <MenuItem value="ADMIN">ADMIN</MenuItem>
          </Select>
          {updatingRole && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </FormControl>
      </TableCell>
      <TableCell>
        <IconButton onClick={() => setDeletingUser(true)} color="error">
          <DeleteIcon />
        </IconButton>
        <ConfirmDeleteUserDialog
          uid={deletingUser ? uid : null}
          onClose={() => setDeletingUser(false)}
        />
      </TableCell>
    </TableRow>
  );
}

export default OrgUserRow;

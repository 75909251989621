import React, { useEffect } from "react";

import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../configs/firebase";

// blank page that logs out and then redirects
function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    signOut(auth).then(() => navigate("/login"));
  }, []);

  return <div></div>;
}

export default LogoutPage;

import { config } from "../../configs/base";
import { auth } from "../../configs/firebase";

/**
 * Sends an API request.
 *
 * @param {string} path - The API endpoint path.
 * @param {Object} body - The request body.
 * @param {("POST")} method - The HTTP method.
 * @returns {Promise<Response>} - The response from the API.
 */
export async function sendApiRequest(
  path: string,
  body: Record<string, any>,
  method: "POST" = "POST",
  forceRefreshToken = false
): Promise<Response> {
  // should only happen in dev
  if (path.charAt(0) !== "/") {
    return Promise.reject(
      new Error(`Invalid path: '${path}'. Must start with '/'`)
    );
  }

  const user = auth.currentUser;

  if (!user) {
    return Promise.reject(new Error("Not logged in."));
  }

  const token = await user.getIdToken(forceRefreshToken);

  try {
    const response = await fetch(`${config.apiUrl}${path}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      const error = new Error(`Failed to send ${path}: ${response.status}`);
      (error as any).code = `api/${response.status}`;
      return Promise.reject(error);
    }
    return response;
  } catch (e) {
    if ((e as Error).message === "Failed to fetch") {
      (e as any).code = "api/error";
    }
    return Promise.reject(e);
  }
}

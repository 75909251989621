import React, { useContext, useRef, useState } from "react";

import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  Typography,
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";

import ProjectCard from "../components/ProjectCard";
import AppPage from "../components/navbar/AppPage";
import DocumentsTable, {
  DOCUMENT_TABLE_COL_NAME,
  DOCUMENT_TABLE_COL_UPLOADED_AT,
} from "../components/tables/DocumentsTable";
import { TABLE_COL_OWNER } from "../components/tables/FsTable";
import UploadFileButton from "../components/upload/UploadFileButton";
import { fixHref } from "../components/utils/Utils";
import { auth } from "../configs/firebase";
import { OrgContext } from "../contexts/OrgContext";
import { organization } from "../fs/FirestoreUtils";
import useFsCol from "../fs/useFsCol";
import { whereReadAccessible } from "../utils/AclUtils";
import AiChatBar from "./AiChatBar";
import CreateProjectDialog from "./projects/CreateProjectDialog";

function HomePage() {
  const [user] = useAuthState(auth);
  const { selectedOrg, uid } = useContext(OrgContext);

  const [projects, , ,] = useFsCol(organization(selectedOrg).projects(), {
    where: whereReadAccessible(uid),
    orderBy: "created_at",
    orderDir: "desc",
    limit: 4,
  });
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const otherDocumentInputRef = useRef<HTMLInputElement>(null);
  return (
    <AppPage pageType={"flat"} bottomBar={<AiChatBar />}>
      <Box sx={{ mb: "64px", mr: 3 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4" gutterBottom data-test-id={"welcome-banner"}>
            Welcome {user && user.displayName}!
          </Typography>
        </Box>
        {projects.length > 0 && (
          <>
            <Stack direction={"row"} alignItems="center" sx={{ mt: 4 }}>
              <Typography variant="h5" sx={{ mr: 3 }}>
                Recent Knowledge Hubs
              </Typography>
              <Link to={fixHref("/projects")}>View All</Link>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              {projects.map((p) => {
                return <ProjectCard key={p.id} project={p} />;
              })}
            </Box>
          </>
        )}
        <Stack direction={"row"} alignItems="center" sx={{ mt: 4 }}>
          <Typography variant="h5" sx={{ mr: 3 }}>
            Recent Documents
          </Typography>
          <Link to={fixHref("/documents")}>View All</Link>
        </Stack>
        <DocumentsTable
          columns={[
            DOCUMENT_TABLE_COL_NAME,
            TABLE_COL_OWNER,
            DOCUMENT_TABLE_COL_UPLOADED_AT,
          ]}
          enableSelection={false}
          pageSizeOptions={[5]}
          defaultPageSize={5}
          enableControls={false}
          dataGridProps={{
            hideFooterPagination: true,
            hideFooter: true,
          }}
        />
        <Box sx={{ height: "60px" }} />
        <SpeedDial
          ariaLabel="SpeedDial"
          sx={{ position: "fixed", bottom: "116px", right: "16px" }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            tooltipTitle={"Upload Documents"}
            icon={<DescriptionIcon />}
            onClick={() => {
              otherDocumentInputRef.current?.click();
            }}
          />
          <SpeedDialAction
            tooltipTitle={"New Project"}
            icon={<FolderIcon />}
            onClick={() => setShowCreateProjectModal(true)}
          />
        </SpeedDial>
        <CreateProjectDialog
          isOpen={showCreateProjectModal}
          onClose={() => setShowCreateProjectModal(false)}
        />
        <UploadFileButton inputRef={otherDocumentInputRef} visible={false} />
      </Box>
    </AppPage>
  );
}

export default HomePage;

import React, { ChangeEvent, useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { User, updateProfile } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";

import ErrorDisplay from "../../components/utils/ErrorDisplay";
import { auth, storage } from "../../configs/firebase";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ChangeProfileInfoForm(): React.JSX.Element {
  const [user] = useAuthState(auth);
  const [name, setName] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (user) {
      setName(user.displayName || "");
    }
  }, [user?.displayName]);

  async function saveProfile() {
    if (working) {
      return;
    }

    setError(null);
    setWorking(true);
    setSuccess(false);

    try {
      let photoURL: string | undefined = undefined;

      if (file) {
        const storageRef = ref(storage, `${user!.uid}/profile-picture`);
        // Create file metadata to update
        const metadata = {
          // 1 day cache
          cacheControl: "private,max-age=86400",
        };
        await uploadBytes(storageRef, file, metadata);
        photoURL = await getDownloadURL(storageRef);
      }

      await updateProfile(user as User, {
        displayName: name,
        photoURL: photoURL,
      });
      setSuccess(true);
    } catch (e) {
      console.error(e);
      setError(e as Error);
    } finally {
      setWorking(false);
    }
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
  }

  return (
    <Stack spacing={3} sx={{ width: "100%", maxWidth: 600 }}>
      <Typography variant="h6">User Info</Typography>
      <Typography variant="subtitle1">{`Make it easy for your co-workers to identify you. No information is shared outside of your Organizations.`}</Typography>
      <Stack direction={"row"} spacing={3}>
        <Avatar
          alt={user?.displayName || ""}
          src={file ? URL.createObjectURL(file) : user?.photoURL || ""}
          sx={{ width: 56, height: 56 }}
        />
        <Box sx={{ pt: 1 }}>
          <Button
            disabled={working}
            variant={"outlined"}
            color="primary"
            component="label"
            role={undefined}
          >
            <VisuallyHiddenInput
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleFileChange}
              multiple
            />
            Change
          </Button>
        </Box>
      </Stack>
      <Box />
      <TextField
        label="Email"
        value={user?.email || ""}
        type={"email"}
        sx={{ maxWidth: 600 }}
        disabled={true}
        fullWidth
      />
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ maxWidth: 600 }}
        fullWidth
      />
      <ErrorDisplay error={error} />
      <Button
        disabled={working}
        onClick={saveProfile}
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
      >
        Save Profile
      </Button>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        message={"Profile saved!"}
      />
    </Stack>
  );
}

export default ChangeProfileInfoForm;

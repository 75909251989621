import React, { useContext, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";

import AppPage from "../components/navbar/AppPage";
import ErrorDisplay from "../components/utils/ErrorDisplay";
import { sendApiRequest } from "../components/utils/FetchUtils";
import { OrgContext } from "../contexts/OrgContext";

function AcceptInvitePage() {
  const { orgId: inviteOrgId } = useParams();
  const { userPermissions } = useContext(OrgContext);
  const navigate = useNavigate();
  const [error, setError] = useState<Error | string | null>(null);
  const [loading, setLoading] = useState(false);

  async function joinOrg() {
    if (inviteOrgId && userPermissions.includes(inviteOrgId)) {
      console.log(`User is already a member of '${inviteOrgId}'`);
      navigate(`/?orgId=${inviteOrgId}`);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await sendApiRequest(
        "/users/accept-invite",
        {
          org_id: inviteOrgId,
        },
        "POST",
        true
      ); // force token refresh

      if (response.ok) {
        navigate(`/?orgId=${inviteOrgId}`);
      } else {
        if (response.status === 404) {
          setError("Invite not found.");
        } else {
          setError(new Error("Failed to accept invite"));
        }
      }
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <AppPage pageType={"minimalist"}>
      <Stack
        sx={{ justifyContent: "center", alignItems: "center", mt: 8 }}
        spacing={3}
      >
        <Typography variant={"h3"}>Accept Invitation</Typography>
        {!inviteOrgId && (
          <Alert severity={"error"}>Invalid invitation link.</Alert>
        )}
        <ErrorDisplay error={error} />
        <Typography>
          Do you want to join the organization <strong>{inviteOrgId}</strong>?
        </Typography>
        <Button
          variant="contained"
          color={"success"}
          onClick={joinOrg}
          startIcon={loading ? <CircularProgress /> : <AddCircleOutlineIcon />}
          disabled={loading}
        >
          Join Org
        </Button>
      </Stack>
    </AppPage>
  );
}

export default AcceptInvitePage;

import React, { ReactNode, createContext, useContext } from "react";

import { useParams } from "react-router-dom";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import useFsDoc from "../../fs/useFsDoc";
import { Project, ProjectDocument } from "../../models/Models";

interface ProjectsProps {
  projectId: string | null;
  project: Project | null;
  documents: ProjectDocument[];
  loading: boolean;
  loadingDocs: boolean;
  error: Error | null;
}

interface CurrentProjectProviderProps {
  children: ReactNode;
}

export const CurrentProjectContext = createContext<ProjectsProps>({
  projectId: null,
  project: null,
  documents: [],
  error: null,
  loading: true,
  loadingDocs: true,
});

export function CurrentProjectProvider({
  children,
}: CurrentProjectProviderProps): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();
  const { selectedOrg } = useContext(OrgContext);

  const [project, loading, error] = useFsDoc(
    organization(selectedOrg).project(projectId)
  );

  const [documents, loadingDocs, errorDocs] = useFsCol(
    organization(selectedOrg).project(projectId).documents(),
    {
      orderBy: "uploaded_at",
      orderDir: "desc",
    }
  );

  console.log(documents?.length);

  return (
    <CurrentProjectContext.Provider
      value={{
        project: project ?? null,
        loading,
        error: error ?? (errorDocs as Error),
        projectId: projectId ?? null,
        documents: documents ?? [],
        loadingDocs,
      }}
    >
      {children}
    </CurrentProjectContext.Provider>
  );
}

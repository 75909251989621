import React, { useContext } from "react";

import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../configs/firebase";
import { OrgContext } from "../../contexts/OrgContext";

interface Props {
  color?: string;
}

function NavBarOrgPicker({ color }: Props) {
  const [user] = useAuthState(auth);

  const { userPermissions, selectedOrg, handleOrgChange } =
    useContext(OrgContext);

  if (!user) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", color: "primary.light" }}>
      {userPermissions.length > 0 && (
        <Tooltip title="Change Organization" placement={"left"}>
          <Select
            value={selectedOrg || ""}
            onChange={(event) => handleOrgChange(event.target.value)}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              mr: 2,
              color: color,
              "& .MuiSvgIcon-root": {
                color: color,
              },
            }}
          >
            {userPermissions.map((orgId) => (
              <MenuItem key={orgId} value={orgId}>
                {orgId}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      )}
    </Box>
  );
}

export default NavBarOrgPicker;

import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import { Button, Stack, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { GlobalSnackbarContext } from "../../../components/GlobalSnackbarContext";
import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";

interface EditProjectInfoFormProps {
  project: {
    id: string;
    name: string;
    description: string;
  };
}

function EditProjectInfoForm({
  project,
}: EditProjectInfoFormProps): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();
  const { selectedOrg } = useContext(OrgContext);
  const { showSnackbar } = useContext(GlobalSnackbarContext);

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);
  const [working, setWorking] = useState<boolean>(false);

  useEffect(() => {
    setName(project?.name ?? "");
    setDescription(project?.description ?? "");
  }, [project?.name, project?.description]);

  async function save() {
    if (working) {
      return;
    }

    setError(null);
    setWorking(true);

    try {
      await organization(selectedOrg)
        .project(projectId)
        .update({ name, description });
      showSnackbar("Project saved!");
    } catch (e) {
      setError(e as Error);
    } finally {
      setWorking(false);
    }
  }

  return (
    <Stack spacing={3} sx={{ width: "100%", maxWidth: 600 }}>
      <Typography variant="h6">Project Info</Typography>
      <TextField
        label="Name"
        value={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        sx={{ maxWidth: 600 }}
        fullWidth
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setDescription(e.target.value)
        }
        multiline
        rows={4}
        sx={{ maxWidth: 600 }}
        fullWidth
      />
      <ErrorDisplay error={error} />
      <Button
        disabled={working}
        onClick={save}
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
      >
        Save Project
      </Button>
    </Stack>
  );
}

export default EditProjectInfoForm;

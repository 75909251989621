import React, { useContext } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

import { GlobalSnackbarContext } from "./GlobalSnackbarContext";
import { IconMenuItem, renderMenuItems } from "./IconMenu";

function RightClickable(props: Props) {
  const { showSnackbar } = useContext(GlobalSnackbarContext);

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [hasSelectedText, setHasSelectedText] = React.useState(false);

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  function onContextMenu(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  }

  async function handleCopySelection() {
    handleCloseMenu();
    const text = window.getSelection()?.toString() ?? "";
    await navigator.clipboard.writeText(text);
    showSnackbar("Copied!");
  }

  function onMouseUp() {
    const text = window.getSelection()?.toString() ?? "";
    setHasSelectedText(text.length > 0);
  }

  return (
    <Box onContextMenu={onContextMenu} onMouseUp={onMouseUp}>
      {props.children}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleCopySelection} disabled={!hasSelectedText}>
          <ListItemIcon>
            <ContentCopyIcon />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>
        {renderMenuItems(props.menuItems, handleCloseMenu)}
      </Menu>
    </Box>
  );
}

interface Props {
  readonly menuItems: (IconMenuItem | null)[];
  readonly children: React.ReactNode;
}

export default RightClickable;

import React, { useContext } from "react";

import { Box, Divider, Stack, Typography } from "@mui/material";

import AppPage from "../../../components/navbar/AppPage";
import { Project } from "../../../models/Models";
import { CurrentProjectContext } from "../CurrentProjectContext";
import ProjectNavBarName from "../ProjectNavBarName";
import EditProjectInfoForm from "./EditProjectInfoForm";

function ProjectSettingsPage(): React.JSX.Element {
  const { project } = useContext(CurrentProjectContext);

  return (
    <AppPage pageType={"minimalist"} secondaryHome={<ProjectNavBarName />}>
      <Stack
        spacing={3}
        sx={{ justifyContent: "center", alignItems: "center", mt: 8, mb: 8 }}
      >
        <Box sx={{ justifyContent: "start", width: "100%", maxWidth: 600 }}>
          <Typography variant={"h4"} sx={{ mb: 3 }}>
            Project {project?.name} Settings
          </Typography>
        </Box>
        <EditProjectInfoForm project={project as Project} />
        <Box />
        <Divider sx={{ width: 600 }} />
      </Stack>
    </AppPage>
  );
}

export default ProjectSettingsPage;

import React, { useContext } from "react";

import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import ChatInputBox from "../../../components/chat/ChatInputBox";
import AppPage from "../../../components/navbar/AppPage";
import ErrorPage from "../../../components/utils/ErrorPage";
import { sendApiRequest } from "../../../components/utils/FetchUtils";
import { auth } from "../../../configs/firebase";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import useFsCol from "../../../fs/useFsCol";
import { CurrentProjectContext } from "../CurrentProjectContext";
import ProjectNavBarName from "../ProjectNavBarName";
import ProjectQuestionCard from "../ProjectQuestionCard";
import ProjectDocumentsReferencePanel from "./ProjectDocumentsReferencePanel";

function ProjectAIPage(): React.JSX.Element {
  const { selectedOrg } = useContext(OrgContext);
  const [user] = useAuthState(auth);
  const {
    projectId,
    error: projectError,
    documents,
  } = useContext(CurrentProjectContext);

  const [questions, questionsLoading, questionsError] = useFsCol(
    organization(selectedOrg).project(projectId).questions(),
    {
      where: where("owner", "==", user?.uid),
      orderBy: "created_at",
      orderDir: "desc",
    }
  );

  if (projectError) {
    return <ErrorPage title={"Failed to load Project"} error={projectError} />;
  } else if (questionsError) {
    return (
      <ErrorPage title={"Failed to load Questions"} error={questionsError} />
    );
  }

  const handleAskQuestion = async (msg: string): Promise<void> => {
    try {
      await sendApiRequest("/projects/question", {
        org_id: selectedOrg,
        project_id: projectId,
        question: msg,
      });
    } catch (error) {
      console.error("Error asking question:", error);
      // todo: handle error
    }
  };

  const pendingDocuments = documents.filter(
    (d) => d.datastore_status !== "READY"
  ).length;

  return (
    <AppPage pageType={"minimalist"} secondaryHome={<ProjectNavBarName />}>
      <Box sx={{ padding: { xs: 2, sm: 3 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ProjectDocumentsReferencePanel />
          </Grid>
          <Grid item xs={12} sm={8}>
            {pendingDocuments !== 0 && (
              <Alert
                severity={"warning"}
              >{`${pendingDocuments} of the documents in this project are still processing and won't be available to the AI.`}</Alert>
            )}
            <Card sx={{ p: 2, my: 2 }}>
              <Box sx={{ pt: 1, pb: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Ask a Question
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <ChatInputBox sendMessage={handleAskQuestion} />
              </Box>
            </Card>
            {questionsLoading && <CircularProgress />}
            {(questions ?? []).map((question) => (
              <ProjectQuestionCard key={question.id} question={question} />
            ))}
          </Grid>
        </Grid>
      </Box>
    </AppPage>
  );
}

export default ProjectAIPage;

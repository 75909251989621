import * as React from "react";

import ErrorIcon from "@mui/icons-material/Error";
import { Stack, Typography } from "@mui/material";

import AppPage from "../navbar/AppPage";
import ErrorDisplay from "./ErrorDisplay";

interface ErrorPageProps {
  title: string;
  body?: string | JSX.Element;
  error?: Error | null;
}

function ErrorPage({ title, body, error = null }: ErrorPageProps): JSX.Element {
  return (
    <AppPage pageType={"minimalist"}>
      <Stack
        sx={{ justifyContent: "center", alignItems: "center", mt: 8 }}
        spacing={3}
      >
        <ErrorIcon sx={{ fontSize: 32 }} color="error" />
        <Typography id={"error-title"} variant={"h3"}>
          {title}
        </Typography>
        {body && <Typography variant={"subtitle1"}>{body}</Typography>}
        <ErrorDisplay error={error} />
      </Stack>
    </AppPage>
  );
}

export default ErrorPage;

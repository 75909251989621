import React, { useContext, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
} from "@mui/material";

import { GlobalSnackbarContext } from "../../../../components/GlobalSnackbarContext";
import CopyButton from "../../../../components/utils/CopyButton";
import ErrorDisplay from "../../../../components/utils/ErrorDisplay";
import { OrgContext } from "../../../../contexts/OrgContext";
import { organization } from "../../../../fs/FirestoreUtils";
import { useWorkload } from "../../../../hooks/useWorkload";
import { CustomAnalyzer } from "../../../../models/Models";

interface EditCustomAnalyzerPageProps {
  customAnalyzer: CustomAnalyzer;
}

function EditCustomAnalyzerPage({
  customAnalyzer,
}: EditCustomAnalyzerPageProps): React.JSX.Element {
  const { selectedOrg } = useContext(OrgContext);
  const { showSnackbar } = useContext(GlobalSnackbarContext);
  const [working, error, runWorkload] = useWorkload([customAnalyzer]);

  const [question, setQuestion] = useState<string>(customAnalyzer.question);

  async function handleSave() {
    runWorkload(async () => {
      await organization(selectedOrg)
        .analyzer(customAnalyzer.id)
        .update<CustomAnalyzer>({ question: question });
      showSnackbar("Analyzer saved!");
    });
  }

  return (
    <>
      <Alert severity={"info"} sx={{ maxWidth: "800px" }}>
        Custom Analyzers are used to run user defined analysis instructions on
        your documents.
      </Alert>
      <ErrorDisplay error={error} />
      <Box sx={{ mt: 2 }} />
      <Card sx={{ maxWidth: "800px" }}>
        <CardContent>
          <Box
            component="form"
            onSubmit={async (e) => {
              e.preventDefault();
              await handleSave();
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Analysis Instructions"
              multiline={true}
              minRows={4}
              maxRows={8}
              disabled={working}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              fullWidth
            />
            <Box sx={{ mt: 2 }} />
            <Stack direction={"row"} spacing={2}>
              <CopyButton value={question} />
              <Button
                role={"submit"}
                disabled={working}
                variant={"contained"}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default EditCustomAnalyzerPage;

import React, { useContext } from "react";

import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { and, where } from "firebase/firestore";
import { Link } from "react-router-dom";

import FavoriteableListItemButton from "../../../components/FavoriteableListItemButton";
import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import { fixHref } from "../../../components/utils/Utils";
import { OrgContext } from "../../../contexts/OrgContext";
import {
  OrgUserProfilesContext,
  isUnknownUser,
} from "../../../contexts/OrgUserProfilesContext";
import { organization } from "../../../fs/FirestoreUtils";
import useFsCol from "../../../fs/useFsCol";
import { Analyzer } from "../../../models/Models";
import { whereReadAccessible } from "../../../utils/AclUtils";

interface AnalyzerPickerProps {
  title: string;
  open: boolean;
  analyzerType: string;
  onClose: () => void;
  callback: (analyzer: Analyzer) => void;
}

export default function AnalyzerPicker({
  title,
  open,
  analyzerType,
  onClose,
  callback,
}: AnalyzerPickerProps): React.JSX.Element {
  const { selectedOrg, uid } = useContext(OrgContext);
  const { getUser } = useContext(OrgUserProfilesContext);

  const analyzersCol = organization(selectedOrg).analyzers();
  const [analyzers, loading, error] = useFsCol(analyzersCol, {
    where: and(whereReadAccessible(uid), where("type", "==", analyzerType)),
    orderBy: "created_at",
    orderDir: "desc",
  });

  function pick(analyzer: Analyzer) {
    callback(analyzer);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Alert severity={"info"}>
          Select an Analyzer to run against this document. You can configure or
          add new Analyzers{" "}
          <Link to={fixHref("/analyzers")} target={"_blank"}>
            here
          </Link>
          .
        </Alert>
        <ErrorDisplay error={error} />
        {loading && <CircularProgress />}
        <List>
          {analyzers?.map((analyzer) => {
            const owner = getUser(analyzer.owner);
            return (
              <FavoriteableListItemButton
                key={`${analyzer.id}-analyzer`}
                value={analyzer}
                onClick={(v) => pick(v)}
                collection={analyzersCol}
                primaryText={analyzer.name}
                secondaryText={
                  isUnknownUser(owner) ? "Default" : `Created by ${owner.name}`
                }
              />
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

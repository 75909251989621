import React, { useContext } from "react";

import { Alert } from "@mui/material";

import ErrorDisplay from "../../components/utils/ErrorDisplay";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsDoc from "../../fs/useFsDoc";
import ConfigField from "./ConfigField";

function ConfigPage() {
  const { selectedOrg } = useContext(OrgContext);

  const orgRef = organization(selectedOrg);
  const [orgConfig, , error, organizationDoc] = useFsDoc(
    organization(selectedOrg)
  );

  return (
    <div>
      {organizationDoc && !organizationDoc.exists() && (
        <Alert severity="error">Organization not found.</Alert>
      )}
      {<ErrorDisplay error={error} />}
      {orgConfig && (
        <>
          <ConfigField
            docRef={orgRef.docRef}
            label={"Company Name"}
            obj={orgConfig}
            fieldName={"company_name"}
            multiline={false}
          />
          <ConfigField
            docRef={orgRef.docRef}
            label={"Company Overview"}
            obj={orgConfig}
            fieldName={"company_overview"}
            multiline={true}
          />
          <ConfigField
            docRef={orgRef.docRef}
            label={"Company Services"}
            obj={orgConfig}
            fieldName={"company_services"}
            multiline={true}
          />
          <ConfigField
            docRef={orgRef.docRef}
            label={"Company Products"}
            obj={orgConfig}
            fieldName={"company_products"}
            multiline={true}
          />
          <ConfigField
            docRef={orgRef.docRef}
            label={"Company Leadership"}
            obj={orgConfig}
            fieldName={"company_leadership"}
            multiline={true}
          />
          <ConfigField
            docRef={orgRef.docRef}
            label={"Company Additional Info & Context"}
            obj={orgConfig}
            fieldName={"company_additional"}
            multiline={true}
          />
        </>
      )}
    </div>
  );
}

export default ConfigPage;

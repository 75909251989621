import React, { useContext, useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import UserAvatar from "../../components/UserAvatar";
import SharingDialog from "../../components/acls/SharingDialog";
import AppPage from "../../components/navbar/AppPage";
import ErrorPage from "../../components/utils/ErrorPage";
import NiceDateAgo from "../../components/utils/NiceDateAgo";
import { fixHref } from "../../components/utils/Utils";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { CurrentProjectContext } from "./CurrentProjectContext";
import ProjectDocumentsTable from "./ProjectDocumentsTable";
import ProjectNavBarName from "./ProjectNavBarName";

function ProjectPage() {
  const { selectedOrg } = useContext(OrgContext);
  const { project, projectId, error } = useContext(CurrentProjectContext);
  const [showSharingDialog, setShowSharingDialog] = useState(false);

  if (error) {
    return (
      <ErrorPage
        title={"Unable to Load Knowledge Hub"}
        body={""}
        error={error}
      />
    );
  }

  return (
    <AppPage pageType={"minimalist"} secondaryHome={<ProjectNavBarName />}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Box sx={{ mb: 2 }}>
              <Link to={fixHref("/projects")}>
                <Button
                  startIcon={
                    <ArrowBackIosIcon fontSize={"small"} color={"secondary"} />
                  }
                >
                  All Hubs
                </Button>
              </Link>
            </Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ mb: 3 }}
            >
              <Tooltip title={`${project?.name} Hub`}>
                <Typography
                  variant={"h4"}
                  sx={{ textOverflow: "ellipsis" }}
                  noWrap
                >
                  {project?.name} Hub
                </Typography>
              </Tooltip>
              <Link to={fixHref(`/projects/${projectId}/ai`)}>
                <Button startIcon={<AutoFixHighIcon />} variant={"contained"}>
                  Launch AI
                </Button>
              </Link>
            </Stack>
            <Box mt={2} />
            <Divider />
            <Box mt={2} />
            <Typography variant={"h5"}>Documents</Typography>
            <ProjectDocumentsTable />
          </Grid>
          <Grid item xs={3}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Stack direction={"column"} spacing={1}>
                <Box sx={{ mt: 3 }}>
                  <Typography variant={"subtitle2"}>About</Typography>
                </Box>
                <Typography variant={"body2"}>
                  {project?.description || "No description provided."}
                </Typography>
                <Box>
                  <Typography variant={"subtitle2"}>Created</Typography>
                </Box>
                <Typography variant={"subtitle1"}>
                  <NiceDateAgo date={project?.created_at} />
                </Typography>
                <Box>
                  <Typography variant={"subtitle2"}>Owner</Typography>
                </Box>
                <UserAvatar uid={project?.owner} />
                <Box>
                  <Typography variant={"subtitle2"}>Contributors</Typography>
                </Box>
                <Box />
                <Button
                  startIcon={<PersonAddIcon />}
                  variant={"outlined"}
                  fullWidth
                  onClick={() => setShowSharingDialog(true)}
                >
                  Manage Access
                </Button>
                <Link to={fixHref(`/projects/${projectId}/settings`)}>
                  <Button
                    startIcon={<SettingsIcon />}
                    variant={"outlined"}
                    fullWidth
                  >
                    Settings
                  </Button>
                </Link>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <SharingDialog
        open={showSharingDialog}
        onClose={() => setShowSharingDialog(false)}
        resourceName={project?.name}
        docRef={organization(selectedOrg).project(projectId)}
      />
    </AppPage>
  );
}

export default ProjectPage;

import * as React from "react";

import { Box, Stack, Tooltip } from "@mui/material";

import { AclResource } from "../../models/AclResource";
import UserAvatar from "../UserAvatar";

interface WritersAvatarListProps {
  project: AclResource;
  size?: number;
  limit?: number;
}

function WritersAvatarList({
  project: aclResource,
  size = 40,
  limit = 4,
}: WritersAvatarListProps): JSX.Element {
  const writers = aclResource?.writers ?? [];
  let contributors =
    writers.filter((u) => u !== aclResource.owner).reverse() ?? [];
  const tooManyContributors = contributors.length > limit;
  if (tooManyContributors) {
    contributors = contributors.slice(0, limit - 1);
  }
  const otherCount = (writers.length ?? 0) - contributors.length - 1;
  return (
    <>
      <Stack spacing={-3} direction={"row"}>
        {contributors.map((u) => (
          <UserAvatar key={u} uid={u} size={size} />
        ))}
        {tooManyContributors && (
          <Tooltip title={`+${otherCount} other contributors`}>
            <Box
              sx={{
                height: "40px",
                width: "40px",
                backgroundColor: "#bbb",
                borderRadius: "50%",
                display: "block",
                lineHeight: "40px",
                zIndex: 1,
                textAlign: "center",
              }}
            >
              {`+ ${otherCount}`}
            </Box>
          </Tooltip>
        )}
      </Stack>
    </>
  );
}

export default WritersAvatarList;

import React, { useContext, useState } from "react";

import { Box, CircularProgress, Paper, Typography } from "@mui/material";

import { CurrentProjectContext } from "../CurrentProjectContext";
import ProjectDocumentAccordian from "./ProjectDocumentAccordian";

function ProjectDocumentsReferencePanel(): JSX.Element {
  const [expanded, setExpanded] = useState<string | null>(null);
  const { documents, loadingDocs } = useContext(CurrentProjectContext);

  function toggle(id: string) {
    if (expanded === id) {
      setExpanded(null);
    } else {
      setExpanded(id);
    }
  }

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Documents
      </Typography>
      <Box sx={{ maxHeight: "50vh", overflow: "auto" }}>
        {loadingDocs && <CircularProgress />}
        {documents.map((doc) => (
          <ProjectDocumentAccordian
            key={doc.id}
            document={doc}
            expanded={doc.id === expanded}
            toggle={toggle}
          />
        ))}
      </Box>
    </Paper>
  );
}

export default ProjectDocumentsReferencePanel;

import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";

import Branding from "../Branding";
import { Props } from "./AppPage";
import NavBarAvatar from "./NavBarAvatar";
import NavBarItems from "./NavBarItems";
import NavBarOrgPicker from "./NavBarOrgPicker";

const drawerWidth = 240;
const navbarHeight = 65;

function FlatPage({ children, secondaryHome, bottomBar }: Props) {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const secondaryHomeJsx = useMemo(() => {
    if (typeof secondaryHome === "string") {
      return <Typography variant="h5">{secondaryHome}</Typography>;
    }
    return secondaryHome;
  }, [secondaryHome]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          height: `${navbarHeight}px`,
          ml: `${drawerWidth}px`,
          backgroundColor: "background.default",
          borderBottom: hasScrolled ? 1 : 0,
          borderBottomColor: "rgba(0, 0, 0, 0.12)",
          zIndex: 1,
        }}
      >
        <Toolbar>
          {secondaryHomeJsx}
          <Box component="div" sx={{ flexGrow: 1 }} />
          <NavBarOrgPicker />
          <NavBarAvatar />
        </Toolbar>
      </Box>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
          },
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          border: "none",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderWidth: 0,
          },
          borderWidth: 0,
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <Branding />
        </Toolbar>
        <NavBarItems />
      </Drawer>
      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          px: 2,
          pb: 2,
          ml: `${drawerWidth}px`,
          mt: `${navbarHeight}px`,
        }}
      >
        {children}
      </Box>
      <Box
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          bottom: 0,
          // height: `${navbarHeight}px`,
          ml: `${drawerWidth}px`,
          // backgroundColor: "background.default",
          borderBottom: hasScrolled ? 1 : 0,
          borderBottomColor: "rgba(0, 0, 0, 0.12)",
          zIndex: 1,
        }}
      >
        {bottomBar}
      </Box>
    </Box>
  );
}

export default FlatPage;

import React, { useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import { ColorType } from "../../utils/AclUtils";
import ErrorDisplay from "../utils/ErrorDisplay";

interface Props {
  readonly sendMessage: (msg: string) => Promise<void>;
  readonly label?: string;
  readonly color?: ColorType | undefined;
  readonly isBusy?: boolean | undefined;
  readonly sx?: any;
}

function ChatInputBox({
  sendMessage,
  label = "Type your message",
  color = undefined,
  isBusy = undefined,
  sx = {},
}: Props) {
  const [message, setMessage] = useState<string>("");
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function handleSendMessage(message: string): Promise<void> {
    if (sending) {
      return;
    }

    setError(null);
    setSending(true);
    try {
      await sendMessage(message);
      setMessage("");
    } catch (e: any) {
      setError(e);
    } finally {
      setSending(false);
    }
  }

  // Function to handle key presses in the input field
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent default Enter behavior
      handleSendMessage(message);
    }
  }

  const working = sending || isBusy;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        ...sx,
      }}
    >
      <ErrorDisplay error={error} />
      <TextField
        label={label}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        fullWidth
        multiline
        minRows={1}
        maxRows={4}
        color={color}
        autoFocus
        disabled={working}
        sx={{ flexGrow: 1, backgroundColor: "white" }}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleSendMessage(message)}
                color={color}
                disabled={!message.trim() || working}
              >
                {!working && <SendIcon />}
                {working && <CircularProgress size={18} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default ChatInputBox;

import React, { ReactNode } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../configs/firebase";

interface AuthLoadBlockerProps {
  children: ReactNode;
}

// Used to block rendering of child elements until the user is loaded. This helps prevent stutter/flicker rendering caused by guards.
function AuthLoadBlocker({ children }: AuthLoadBlockerProps): JSX.Element {
  const [, userLoading] = useAuthState(auth);

  if (userLoading) {
    return <></>;
  }

  return <>{children}</>;
}

export default AuthLoadBlocker;

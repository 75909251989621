import * as React from "react";
import { useState } from "react";

import {
  Button,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../configs/firebase";
import ErrorDisplay from "../utils/ErrorDisplay";

interface PasswordSignInFormProps {
  setShowResetPassword: (show: boolean) => void;
  email: string;
  setEmail: (email: string) => void;
  setShowSignUp: (show: boolean) => void;
}

function PasswordSignInForm({
  setShowResetPassword,
  email,
  setEmail,
  setShowSignUp,
}: PasswordSignInFormProps): JSX.Element {
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function signin() {
    if (loading) {
      return;
    }
    setError(null);
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      component="form"
      sx={{ "& .MuiTextField-root": { m: 1, width: "50ch" } }}
      onSubmit={async (e) => {
        e.preventDefault();
        await signin();
      }}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        <ErrorDisplay error={error} />
        <TextField
          name={"email"}
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          name={"password"}
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {loading && <CircularProgress />}
        {!loading && (
          <Button variant="contained" type="submit">
            Sign In
          </Button>
        )}
        <Typography variant={"body2"}>
          <Link onClick={() => setShowResetPassword(true)}>
            Forgot your password?
          </Link>
        </Typography>
        <Typography variant={"body2"}>
          Don&apos;t have an account?{" "}
          <Link onClick={() => setShowSignUp(true)}>Sign up here.</Link>
        </Typography>
      </Stack>
    </Box>
  );
}

export default PasswordSignInForm;

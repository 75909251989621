import * as React from "react";

import { Box, Typography } from "@mui/material";

import AppPage from "../../../components/navbar/AppPage";
import DocumentsTable, {
  DOCUMENT_TABLE_COL_NAME,
  DOCUMENT_TABLE_COL_UPLOADED_AT,
} from "../../../components/tables/DocumentsTable";
import { TABLE_COL_OWNER } from "../../../components/tables/FsTable";
import UploadFileButton from "../../../components/upload/UploadFileButton";

function DocumentListPage(): JSX.Element {
  return (
    <AppPage pageType={"flat"}>
      <Typography variant="h4" gutterBottom>
        Documents
      </Typography>
      <Box sx={{ my: 1 }} />
      <DocumentsTable
        columns={[
          DOCUMENT_TABLE_COL_NAME,
          TABLE_COL_OWNER,
          DOCUMENT_TABLE_COL_UPLOADED_AT,
        ]}
        enableSelection={true}
        enableControls={true}
        extraControls={<UploadFileButton />}
      />
    </AppPage>
  );
}

export default DocumentListPage;

import React, { useContext, useEffect, useMemo, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { ThreeDots } from "react-loader-spinner";
import ReactMarkdown from "react-markdown";

import { auth } from "../../configs/firebase";
import { IFsModel } from "../../fs/FirestoreUtils";
import {
  ChatAgentType,
  Citation,
  CitationSource,
  StatusEnum,
} from "../../models/Models";
import { GlobalSnackbarContext } from "../GlobalSnackbarContext";
import { IconMenuItem } from "../IconMenu";
import RightClickable from "../RightClickable";
import useTextSmooth from "../useTextSmooth";
import NiceDateAgo from "../utils/NiceDateAgo";
import { getChatConfig } from "./AiChatType";
import ChatCitationChip from "./ChatCitationChip";

export interface ChatMessagePart extends IFsModel {
  text: string;
  role: "model" | "user";
  status: StatusEnum;
  citation_sources: CitationSource[];
  citations: Citation[];
  sent_at: Timestamp;
}

interface ChatMessageDisplayProps {
  message: ChatMessagePart;
  chatType: ChatAgentType;
}

function ChatMessageDisplay({
  message,
  chatType,
}: ChatMessageDisplayProps): React.JSX.Element {
  const [user] = useAuthState(auth);
  const { showSnackbar } = useContext(GlobalSnackbarContext);
  const { role, text, citation_sources, citations } = message;
  const [hoverCitation, setHoverCitation] = useState<number>(-1);

  const avatarSrc =
    role === "user" ? (user?.photoURL ?? "") : "/whale-logo.jpg";
  const avatarAlt = role === "user" ? (user?.displayName ?? "User") : "Model";

  const config = getChatConfig(chatType);

  const isLoading = role === "model" && message.status !== StatusEnum.COMPLETE;

  function insertCitations(text: string, citations: Citation[]): string {
    if (!citations) return text;
    let result = text;
    let offset = 0;

    citations.forEach((citation) => {
      const citationText = citation.source_indices
        .map((sourceIndex) => {
          if (sourceIndex === hoverCitation) {
            return `**[${sourceIndex + 1}]**`;
          }
          return `[${sourceIndex + 1}]`;
        })
        .join("");
      const insertIndex = citation.end_index + offset;
      result =
        result.slice(0, insertIndex) + citationText + result.slice(insertIndex);
      offset += citationText.length;
    });
    return result;
  }

  const smoothText = useTextSmooth(text, 10, 2, role === "model" && isLoading);

  const menuItems = useMemo<(IconMenuItem | null)[]>(() => {
    const result: (IconMenuItem | null)[] = [];

    result.push({
      name: "Copy Message",
      action: async () => {
        await navigator.clipboard.writeText(text);
        showSnackbar("Copied!");
      },
      icon: <ContentCopyIcon />,
    });

    return result;
  }, [text]);

  const chatBubble = (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        spacing={2}
        alignItems={"center"}
      >
        <Typography
          variant={"overline"}
          sx={{
            fontWeight: 800,
            visibility: role === "user" ? "hidden" : undefined,
            color: `${config.color}.main`,
          }}
        >
          {config.name}
        </Typography>
        <NiceDateAgo
          date={message.sent_at}
          sx={{
            pb: 1,
            fontSize: 12,
            fontWeight: 500,
            color: "text.secondary",
            display: "inline-block",
          }}
        />
      </Stack>

      <RightClickable menuItems={menuItems}>
        <Card
          variant={"outlined"}
          sx={{
            px: 2,
            backgroundColor: role === "user" ? "#c1f6f1" : undefined,
          }}
        >
          <ReactMarkdown>
            {insertCitations(smoothText, citations)}
          </ReactMarkdown>
          {isLoading && (
            <Box display="flex" alignItems="center" sx={{ my: 2 }}>
              {[StatusEnum.PROCESSING, StatusEnum.PENDING].includes(
                message.status
              ) && (
                <ThreeDots
                  height="20"
                  width="30"
                  color="#000"
                  ariaLabel="loading"
                />
              )}
            </Box>
          )}
          {citation_sources && citation_sources.length > 0 && (
            <Stack direction={"row"} spacing={1} sx={{ pb: 2 }}>
              {citation_sources.map((source, index) => (
                <ChatCitationChip
                  key={index}
                  citationSource={source}
                  index={index}
                  onHover={(i) => setHoverCitation(i)}
                />
              ))}
            </Stack>
          )}
        </Card>
      </RightClickable>
    </Box>
  );

  const avatar = (
    <Box
      sx={{
        mr: 2,
        alignSelf: "flex-start",
        pt: "32px",
      }}
    >
      <Avatar
        alt={avatarAlt}
        src={avatarSrc}
        sx={{ border: "0.1px solid lightgray" }}
      />
    </Box>
  );

  if (role === "user") {
    return (
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"right"}
        sx={{ width: "100%" }}
      >
        <Box sx={{ flex: 1, minWidth: "80px" }} />
        {chatBubble}
        {avatar}
      </Stack>
    );
  } else {
    return (
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"left"}
        sx={{ width: "100%" }}
      >
        {avatar}
        {chatBubble}
        <Box sx={{ flex: 1, minWidth: "80px" }} />
      </Stack>
    );
  }
}

export default ChatMessageDisplay;

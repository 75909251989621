import { Functions } from "@firebase/functions";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { FirebaseStorage, getStorage } from "firebase/storage";

const dev: FirebaseOptions = {
  apiKey: "AIzaSyDqyz2a8NVBLsxW19Wga7GA_MIUjrlxFp0",
  authDomain: "maix-dev.firebaseapp.com",
  projectId: "maix-dev",
  storageBucket: "maix-dev.firebasestorage.app",
  messagingSenderId: "663896403931",
  appId: "1:663896403931:web:ffb58cfb98bd1c31e941fe",
  measurementId: "G-CG33J2LQT2",
};

const staging: FirebaseOptions = {
  apiKey: "AIzaSyD3iS4U0s6rGwbPt-ZSSiapmfxyQ3zNqfQ",
  authDomain: "maix-staging.firebaseapp.com",
  projectId: "maix-staging",
  storageBucket: "maix-staging.firebasestorage.app",
  messagingSenderId: "162371902297",
  appId: "1:162371902297:web:e6db6e214d6667f230ca48",
  measurementId: "G-WQYLW1CZSY",
};

const prod: FirebaseOptions = {
  apiKey: "AIzaSyBiOe4s46S09LZgBDzrJ2vzV69nKoGLgaQ",
  authDomain: "maix-prod.firebaseapp.com",
  projectId: "maix-prod",
  storageBucket: "maix-prod.firebasestorage.app",
  messagingSenderId: "117160743997",
  appId: "1:117160743997:web:0b9d21203673f905864654",
  measurementId: "G-0GXR6PMYRK",
};

export enum EnvType {
  DEV = "DEV",
  STAGING = "STAGING",
  PROD = "PROD",
}

export function getEnv(): EnvType {
  if (window.location.hostname === "localhost") {
    return EnvType.DEV;
  } else if (
    window.location.hostname === "maix-staging.web.app" ||
    window.location.hostname === "maix-staging.firebaseapp.com"
  ) {
    return EnvType.STAGING;
  } else {
    return EnvType.PROD;
  }
}

function getFirebaseEnvOptions(): FirebaseOptions {
  switch (getEnv()) {
    case EnvType.DEV:
      return dev;
    case EnvType.STAGING:
      return staging;
    case EnvType.PROD:
      return prod;
  }
}

const app: FirebaseApp = initializeApp(getFirebaseEnvOptions());
export const auth: Auth = getAuth(app);
export const storage: FirebaseStorage = getStorage(app);
export const db: Firestore = getFirestore(app);
export const functions: Functions = getFunctions(app);

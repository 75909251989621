import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DocumentReference, updateDoc } from "firebase/firestore";

interface ConfigFieldProps {
  label: string;
  fieldName: string;
  obj: Record<string, any>;
  docRef: DocumentReference;
  multiline?: boolean;
}

function ConfigField({
  label,
  fieldName,
  obj,
  docRef,
  multiline = false,
}: ConfigFieldProps): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
    setValue(obj[fieldName] || "");
  };

  const handleSaveClick = async () => {
    try {
      await updateDoc(docRef, { [fieldName]: value });
      setIsEditing(false);
      setError("");
    } catch (error) {
      console.error(`Error updating ${fieldName}:`, error);
      setError(`Failed to update ${label}. Please try again.`);
    }
  };

  if (obj != null && obj[fieldName] === undefined) {
    console.warn(`Object field '${fieldName}' is undefined. Bad field name?`);
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        {label}
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isEditing ? (
        <TextField
          label={label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          multiline={multiline}
          rows={multiline ? 4 : 1}
          variant="outlined"
          id={fieldName}
        />
      ) : (
        <Box display="flex" alignItems="center">
          <Typography variant="body1">{obj[fieldName] || ""}</Typography>
          <IconButton onClick={handleEditClick} sx={{ ml: 1 }}>
            <EditIcon />
          </IconButton>
        </Box>
      )}

      {isEditing && (
        <Stack direction={"row"} sx={{ mt: 1 }} spacing={2}>
          <Button variant="text" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
          <Button variant="text" onClick={handleSaveClick}>
            Save
          </Button>
        </Stack>
      )}
    </Box>
  );
}

export default ConfigField;

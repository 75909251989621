import {
  QueryCompositeFilterConstraint,
  arrayRemove,
  arrayUnion,
  or,
  where,
} from "firebase/firestore";

import { FsDocRef } from "../fs/FsDocRef";
import { AclResource } from "../models/AclResource";
import { UserId } from "../models/Models";

export enum AclType {
  READER = "READER",
  WRITER = "WRITER",
}

export type ColorType =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

export function whereReadAccessible(
  uid: UserId
): QueryCompositeFilterConstraint {
  return or(
    where("readers", "array-contains", uid),
    where("allowOrgRead", "==", true)
  );
}

export function canWrite(resource: AclResource, uid: UserId): boolean {
  return resource.allowOrgWrite || (resource.writers ?? []).includes(uid);
}

export function canWriteOrDie(resource: AclResource, uid: UserId): void {
  if (!canWrite(resource, uid)) {
    throw new Error("You do not have permission to write");
  }
}

/**
 *
 * @param {AclResource} aclResource
 * @param {string} firestorePath
 * @param {string} uid
 * @param {AclType|null} aclType
 * @returns {Promise<void>}
 */
export async function setUserAcl(
  aclResource: AclResource,
  firestorePath: FsDocRef<AclResource>,
  uid: UserId,
  aclType: AclType | null
): Promise<void> {
  if (aclResource.owner === uid) {
    return Promise.reject("Cannot change owner permissions");
  }

  const currentAcl = getExplicitAcl(aclResource, uid);

  if (aclType === currentAcl) {
    return Promise.resolve();
  }

  let op = {};

  // remove old acl
  if (currentAcl === AclType.READER) {
    op = {
      ...op,
      readers: arrayRemove(uid),
    };
  } else if (currentAcl === AclType.WRITER) {
    op = {
      ...op,
      readers: arrayRemove(uid),
      writers: arrayRemove(uid),
    };
  }

  // add acl
  if (aclType === AclType.READER) {
    op = {
      ...op,
      readers: arrayUnion(uid),
    };
  } else if (aclType === AclType.WRITER) {
    op = {
      ...op,
      readers: arrayUnion(uid),
      writers: arrayUnion(uid),
    };
  }

  await firestorePath.update(op);
}

export async function updateOrgAcl(
  firestorePath: FsDocRef<AclResource>,
  aclType: AclType | null
): Promise<void> {
  await firestorePath.update({
    allowOrgRead: aclType === AclType.READER || aclType === AclType.WRITER,
    allowOrgWrite: aclType === AclType.WRITER,
  });
}

export function getExplicitAcl(
  aclResource: AclResource,
  uid: UserId
): AclType | null {
  if ((aclResource.writers ?? []).includes(uid)) {
    return AclType.WRITER;
  }

  if ((aclResource.readers ?? []).includes(uid)) {
    return AclType.READER;
  }

  return null;
}

export function getOrgAcl(aclResource: AclResource): AclType | null {
  if (aclResource.allowOrgWrite) {
    return AclType.WRITER;
  }

  if (aclResource.allowOrgRead) {
    return AclType.READER;
  }

  return null;
}

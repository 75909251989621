import * as React from "react";

import { Box } from "@mui/material";

interface PdfViewerProps {
  pdfUrl: string;
}

function PdfViewer({ pdfUrl }: PdfViewerProps): JSX.Element {
  // Function to display the PDF (you'll need to implement this based on your PDF viewer library)
  return (
    <Box
      sx={{
        overflow: "auto",
        height: "100%",
        width: "100%",
      }}
    >
      <object
        data={pdfUrl}
        type="application/pdf"
        width="100%"
        height="100%"
        style={{ flex: 1, padding: 0, margin: 0 }}
      >
        <p>
          Your browser does not support PDF embedding. You can download the PDF{" "}
          <a href={pdfUrl}>here</a>.
        </p>
      </object>
    </Box>
  );
}

export default PdfViewer;

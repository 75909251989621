import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";

import IconMenu, { IconMenuItem } from "../../../../components/IconMenu";
import { LegalAnalyzer, RiskFactor } from "../../../../models/Models";
import DeleteRiskFactorDialog from "./DeleteRiskFactorDialog";
import EditRiskFactorDialog from "./EditRiskFactorDialog";

interface RiskFactorCardProps {
  legalAnalyzer: LegalAnalyzer;
  riskFactor: RiskFactor;
}

function RiskFactorCard({
  legalAnalyzer,
  riskFactor,
}: RiskFactorCardProps): JSX.Element {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  function handleEdit() {
    setShowEdit(true);
  }

  function handleDelete() {
    setShowDelete(true);
  }

  const menuItems: IconMenuItem[] = [
    {
      name: "Edit",
      action: handleEdit,
      icon: <EditIcon />,
    },
    {
      name: "Delete",
      action: handleDelete,
      icon: <DeleteIcon />,
    },
  ];

  return (
    <>
      <Card id={riskFactor.name}>
        <CardContent sx={{ position: "relative" }}>
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14 }}
          >
            Risk Factor
          </Typography>
          <Box sx={{ position: "absolute", right: "16px", top: "16px" }}>
            <IconMenu menuItems={menuItems} id={`${riskFactor.name}-menu`} />
          </Box>
          <Typography variant="h5" component="div">
            {riskFactor.name}
          </Typography>

          <Box sx={{ mb: 2 }} />
          <Stack
            spacing={1}
            alignItems={"center"}
            direction={"row"}
            sx={{ color: "info.main" }}
          >
            <PrivacyTipIcon sx={{ fontSize: 22 }} />
            <Typography variant={"overline"} component={"div"}>
              Low Severity
            </Typography>
          </Stack>
          <Typography variant={"body1"}>
            {riskFactor.low_severity_if}
          </Typography>
          <Box sx={{ mb: 2 }} />
          <Stack
            spacing={1}
            alignItems={"center"}
            direction={"row"}
            sx={{ color: "warning.text" }}
          >
            <GppMaybeIcon sx={{ fontSize: 22 }} />
            <Typography variant={"overline"} component={"div"}>
              Medium Severity
            </Typography>
          </Stack>
          <Typography variant={"body1"}>
            {riskFactor.medium_severity_if}
          </Typography>
          <Box sx={{ mb: 2 }} />
          <Stack
            spacing={1}
            alignItems={"center"}
            direction={"row"}
            sx={{ color: "error.text" }}
          >
            <GppBadIcon sx={{ fontSize: 22 }} />
            <Typography variant={"overline"} component={"div"}>
              High Severity
            </Typography>
          </Stack>
          <Typography variant={"body1"}>
            {riskFactor.high_severity_if}
          </Typography>
          <Box sx={{ mb: 2 }} />
        </CardContent>
        <CardActions>
          <Button onClick={handleEdit}>Edit</Button>
          <Button onClick={handleDelete} color={"error"}>
            Delete
          </Button>
        </CardActions>
      </Card>
      <EditRiskFactorDialog
        isAdd={false}
        riskFactor={riskFactor}
        open={showEdit}
        onClose={() => setShowEdit(false)}
        legalAnalyzer={legalAnalyzer}
      />
      <DeleteRiskFactorDialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        legalAnalyzer={legalAnalyzer}
        riskFactor={riskFactor}
      />
    </>
  );
}

export default RiskFactorCard;

import * as React from "react";
import { useState } from "react";

import { Button, Link, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { createUserWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../configs/firebase";
import ErrorDisplay from "../utils/ErrorDisplay";

interface PasswordSignUpFormProps {
  email: string;
  setEmail: (email: string) => void;
  setShowSignUp: (show: boolean) => void;
}

function PasswordSignUpForm({
  email,
  setEmail,
  setShowSignUp,
}: PasswordSignUpFormProps): JSX.Element {
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function doSignUp() {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      component="form"
      sx={{ "& .MuiTextField-root": { m: 1, width: "50ch" } }}
      onSubmit={async (e) => {
        e.preventDefault();
        await doSignUp();
      }}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        <ErrorDisplay error={error} />
        <TextField
          name={"email"}
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          name={"password"}
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" type="submit">
          Sign Up
        </Button>
        <Link onClick={() => setShowSignUp(false)}>
          Already have an account?
        </Link>
      </Stack>
    </Box>
  );
}

export default PasswordSignUpForm;

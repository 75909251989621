import React, { ReactNode, useMemo, useState } from "react";

import { Box } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid";

import { IFsModel } from "../../fs/FirestoreUtils";
import DocumentGridControls, {
  TableAction,
} from "../../pages/documents/list/DocumentGridControls";
import ErrorDisplay from "../utils/ErrorDisplay";

interface Props<T extends IFsModel> {
  readonly items: T[];
  readonly enableSelection?: boolean;
  readonly enableControls?: boolean;
  readonly error: Error | string | null | undefined;
  readonly extraControls?: ReactNode;
  readonly onCellDoubleClick?: (row: T) => void;
  readonly dataGridProps: any;
  readonly actions: TableAction<T>[];
}

function BaseTable<T extends IFsModel>({
  items,
  enableSelection,
  enableControls,
  error,
  extraControls,
  onCellDoubleClick,
  actions = [],
  dataGridProps,
}: Props<T>) {
  const [selectedRowsIds, setSelectedRowsIds] = useState<readonly GridRowId[]>(
    []
  );

  function onRowSelectionModelChange(model: GridRowSelectionModel) {
    if (enableSelection) {
      setSelectedRowsIds(model);
    }
  }

  const selectedValues = items.filter((d) => selectedRowsIds.includes(d.id));

  const actionsColumn = useMemo(() => {
    if (actions.length === 0) {
      return [];
    }

    const col: GridColDef = {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) =>
        actions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.name}
              label={action.name}
              showInMenu
              icon={action.icon}
              closeMenuOnClick={true}
              onClick={() => action.action([params.row])}
            />
          );
        }),
    };
    return [col];
  }, []);

  return (
    <Box sx={{ mt: 3 }}>
      {(enableSelection || extraControls) && (
        <Box sx={{ minHeight: 45 }}>
          {selectedValues.length > 0 && actions.length > 0 && (
            <DocumentGridControls
              selectedValues={selectedValues}
              clearSelection={() => setSelectedRowsIds([])}
              actions={actions}
            />
          )}
          {(selectedRowsIds.length === 0 || actions.length === 0) &&
            extraControls}
        </Box>
      )}
      <ErrorDisplay error={error} />
      <DataGrid
        rows={items}
        autoHeight
        checkboxSelection={enableSelection}
        disableColumnMenu={!enableControls}
        disableColumnFilter={true}
        disableColumnSelector={!enableControls}
        disableColumnSorting={true}
        {...dataGridProps}
        columns={[...dataGridProps.columns, ...actionsColumn]}
        rowSelectionModel={selectedRowsIds}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onCellDoubleClick={(
          params: GridCellParams,
          event: MuiEvent<HTMLElement>
        ) => {
          event.defaultMuiPrevented = true;
          // confirm the click happened on a cell - otherwise this will trigger with modals in the foreground or on other controls
          if (
            // @ts-ignore
            event.target.className?.includes("MuiDataGrid-cell") &&
            onCellDoubleClick
          ) {
            onCellDoubleClick(params.row);
          }
        }}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
      />
    </Box>
  );
}

export default BaseTable;

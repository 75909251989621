import React, { useContext } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../../configs/firebase";
import { OrgContext } from "../../contexts/OrgContext";
import IconMenu, { IconMenuItem } from "../IconMenu";

function NavBarAvatar() {
  const [user] = useAuthState(auth);
  const { userRole } = useContext(OrgContext);

  const navigate = useNavigate();

  const settings: IconMenuItem[] = [
    ...(userRole === "ADMIN"
      ? [
          {
            name: "Admin",
            action: () => {
              navigate("/admin");
            },
            icon: <SettingsIcon />,
          },
        ]
      : []),
    {
      name: "Profile",
      action: () => {
        navigate("/profile");
      },
      icon: <AccountCircleIcon />,
    },
    {
      name: "Sign Out",
      action: () => {
        signOut(auth).then(() => navigate("/login"));
      },
      icon: <LogoutIcon />,
    },
  ];

  if (!user) {
    return <></>;
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconMenu id={"account-menu"} menuItems={settings}>
        <Avatar
          alt={user.displayName!}
          src={user.photoURL!}
          sx={{
            border: "0.1px solid lightgray",
          }}
        />
      </IconMenu>
    </Box>
  );
}

export default NavBarAvatar;

import * as React from "react";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import RuleIcon from "@mui/icons-material/Rule";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import { ChatAgentType } from "../../models/Models";
import { ColorType } from "../../utils/AclUtils";

export interface AiChatConfig {
  name: string;
  useCase: string;
  description: string;
  color: ColorType;
  icon: JSX.Element;
}

export const DefaultChatConfig: AiChatConfig = {
  name: "Mysticetus AI",
  description:
    "Your tailored business AI is a powerful tool designed to assist your company's specific needs. It can help generate innovative business ideas, draft persuasive proposals, compose professional emails, and more. For instance, you can use it to brainstorm new product concepts, help write effective marketing copy, or generate reports.",
  useCase: "Great for most use cases.",
  icon: (
    <AutoAwesomeIcon
      sx={{ fontSize: 18, color: "primary.main" } as SxProps<Theme>}
    />
  ),
  color: "primary",
};

export const ComplianceChatConfig: AiChatConfig = {
  name: "Compliance AI",
  description:
    "The Offshore Regulations AI is an expert in offshore regulations and compliance. It has been trained with thousands of current regulatory documents and can answer questions and even provide guidance on various regulatory matters.",
  useCase: "Your legal expert.",
  icon: (
    <RuleIcon
      sx={{ fontSize: 18, color: "secondary.main" } as SxProps<Theme>}
    />
  ),
  color: "secondary",
};

export const MarketingChatConfig: AiChatConfig = {
  name: "Marketing AI",
  description:
    "This AI marketing assistant crafts compelling content, generates strategies, and automates tasks, empowering businesses to reach their target audience effectively and efficiently. From email campaigns and social media posts to targeted ads and SEO optimization, this AI is your all-in-one marketing solution.",
  useCase: "",
  icon: (
    <AutoAwesomeIcon
      sx={{ fontSize: 18, color: "secondary.main" } as SxProps<Theme>}
    />
  ),
  color: "secondary",
};

export const ProposalChatConfig: AiChatConfig = {
  name: "Proposal AI",
  description:
    "This AI-powered proposal writing assistant streamlines the creation of winning proposals. It helps you develop compelling narratives, incorporate persuasive language, and structure your proposals effectively, increasing your chances of securing deals and driving business growth.",
  useCase: "",
  icon: (
    <AutoAwesomeIcon
      sx={{ fontSize: 18, color: "secondary.main" } as SxProps<Theme>}
    />
  ),
  color: "secondary",
};

export const ReportChatConfig: AiChatConfig = {
  name: "Report AI",
  description:
    "This AI reporting assistant transforms raw data into actionable insights. It automates report generation, visualizes key metrics, and identifies trends, empowering data-driven decision-making. From financial reports to performance dashboards, this AI simplifies reporting processes and provides clear, concise, and insightful summaries.",
  useCase: "",
  icon: (
    <AutoAwesomeIcon
      sx={{ fontSize: 18, color: "secondary.main" } as SxProps<Theme>}
    />
  ),
  color: "secondary",
};

export function getChatConfig(chatType: ChatAgentType): AiChatConfig {
  switch (chatType) {
    case ChatAgentType.REGULATIONS:
      return ComplianceChatConfig;
    case ChatAgentType.MARKETING:
      return MarketingChatConfig;
    case ChatAgentType.PROPOSAL:
      return ProposalChatConfig;
    case ChatAgentType.REPORT:
      return ReportChatConfig;
    default:
      return DefaultChatConfig;
  }
}

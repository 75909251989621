import React, { FormEvent, useContext, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";

import { GlobalSnackbarContext } from "../../../components/GlobalSnackbarContext";
import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import { isEmailValid } from "../../../components/utils/Utils";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import { useWorkload } from "../../../hooks/useWorkload";
import { UserRole } from "../../../models/Models";
import { sendInviteEmail } from "../../../utils/FunctionsClient";

function InviteUserDialog(props: Props) {
  const { selectedOrg } = useContext(OrgContext);
  const [inviteRole, setInviteRole] = useState<UserRole>(UserRole.USER);
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [sending, error, runWorkload] = useWorkload([]);
  const { showSnackbar } = useContext(GlobalSnackbarContext);

  const handleInvite = async () => {
    runWorkload(async () => {
      if (!isEmailValid(inviteEmail)) {
        const e = new Error("Invalid email address");
        e.safeMessage = "Invalid email address";
        throw e;
      }

      const result = await organization(selectedOrg).invites().create({
        id: "",
        email: inviteEmail,
        role: inviteRole,
        created_at: Timestamp.now(),
      });

      await sendInviteEmail({ org_id: selectedOrg, invite_id: result.id });

      showSnackbar("Invite sent!");

      handleClose();
    });
  };

  function handleClose() {
    setInviteEmail("");
    setInviteRole(UserRole.USER);
    props.close();
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <Alert severity="info" sx={{ mb: 1 }}>
          Invite your coworkers so they can use Mysticetus AI. We recommend only
          giving the <strong>ADMIN</strong> role to a limited number of trusted
          users.
        </Alert>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          onSubmit={async (e: FormEvent) => {
            e.preventDefault();
            await handleInvite();
          }}
          autoComplete="off"
        >
          <Grid container alignItems="center" spacing={2} sx={{ my: 2 }}>
            <Grid item xs={12} md={8}>
              <TextField
                label="Email"
                type="email"
                required
                data-lpignore="true"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="invite-role-label">Role</InputLabel>
                <Select
                  labelId="invite-role-label"
                  required
                  value={inviteRole}
                  data-lpignore="true"
                  onChange={(e) => setInviteRole(e.target.value as UserRole)}
                  label="Role"
                >
                  <MenuItem value={UserRole.USER}>USER</MenuItem>
                  <MenuItem value={UserRole.ADMIN}>ADMIN</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={sending}>
          Cancel
        </Button>
        <Button onClick={handleInvite} disabled={sending}>
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  readonly open: boolean;
  readonly close: () => void;
}

export default InviteUserDialog;

import React from "react";

import { Navigate } from "react-router-dom";

import SignInGuard from "../components/signin/SignInGuard";

function SignInPage() {
  return (
    <SignInGuard>
      {/* navigates to home when rendered. */}
      <Navigate to="/" replace={true} />
    </SignInGuard>
  );
}

export default SignInPage;

import React, { ReactNode, useContext } from "react";

import { OrgContext } from "../../contexts/OrgContext";
import ErrorPage from "../utils/ErrorPage";

interface IsAdminGuardProps {
  children: ReactNode;
}

function IsAdminGuard({ children }: IsAdminGuardProps): JSX.Element {
  const { loading, userRole } = useContext(OrgContext);

  if (loading) {
    return <></>;
  }

  if (userRole !== "ADMIN") {
    return (
      <ErrorPage
        title={"Access Denied"}
        body={"You must be an admin for your organization to access this page."}
      />
    );
  }

  return <>{children}</>;
}

export default IsAdminGuard;

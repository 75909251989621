import React, { useState } from "react";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { UserId, UserRole } from "../../models/Models";
import ConfirmDeleteUserDialog from "./ConfirmDeleteUserDialog";
import OrgUserRow from "./OrgUserRow";

interface ManageUsersTabProps {
  readonly roles: Record<UserId, UserRole>;
}

function ManageUsersTab({ roles }: ManageUsersTabProps): JSX.Element {
  const [deleteUserId, setDeleteUserId] = useState<UserId | null>(null);

  return (
    <>
      <Box sx={{ padding: 4 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(roles)
                .sort()
                .map(([userId, role]) => (
                  <OrgUserRow key={userId} uid={userId as UserId} role={role} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ConfirmDeleteUserDialog
        uid={deleteUserId}
        onClose={() => setDeleteUserId(null)}
      />
    </>
  );
}

export default ManageUsersTab;

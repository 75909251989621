import React, { useContext, useState } from "react";

import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { Box, IconButton } from "@mui/material";
import { arrayRemove, arrayUnion } from "firebase/firestore";

import { OrgContext } from "../contexts/OrgContext";
import { OrgUserProfilesContext } from "../contexts/OrgUserProfilesContext";
import { organization } from "../fs/FirestoreUtils";
import { FavoriteId } from "../models/OrgUser";
import { GlobalSnackbarContext } from "./GlobalSnackbarContext";

function ToggleFavoriteButton({ id }: Props) {
  const { showSnackbar } = useContext(GlobalSnackbarContext);
  const { selectedOrg, uid } = useContext(OrgContext);
  const { user } = useContext(OrgUserProfilesContext);

  const [working, setWorking] = useState(false);

  const isFavorited = user?.favorites?.includes(id) ?? false;

  async function toggle(e: React.MouseEvent) {
    e.stopPropagation();

    if (working) {
      return;
    }
    setWorking(true);
    try {
      await organization(selectedOrg)
        .user(uid)
        .update({
          favorites: isFavorited ? arrayRemove(id) : arrayUnion(id),
        });
    } catch (e) {
      showSnackbar("Something went wrong");
    } finally {
      setWorking(false);
    }
  }

  return (
    <Box>
      <IconButton onClick={toggle} sx={{ p: 0 }}>
        {isFavorited && <StarIcon color={"warning"} />}
        {!isFavorited && <StarOutlineIcon color={"disabled"} />}
      </IconButton>
    </Box>
  );
}

interface Props {
  readonly id: FavoriteId;
}

export default ToggleFavoriteButton;

import * as React from "react";
import { useContext, useState } from "react";

import { Avatar } from "@mui/material";
import Popover from "@mui/material/Popover";

import { OrgUserProfilesContext } from "../contexts/OrgUserProfilesContext";
import { UserId } from "../models/Models";
import UserMiniCard from "./utils/UserMiniCard";

interface Props {
  readonly uid: UserId | undefined;
  readonly size?: number;
}

function UserAvatar({ uid, size }: Props) {
  const { getUser } = useContext(OrgUserProfilesContext);

  size = size ?? 40;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const u = getUser(uid);

  // Avatar will fall back to using child element as picture if src can't be loaded
  const initials = u?.name
    ?.split(" ")
    .map((s) => s.trim())
    .filter((s) => s !== "")
    .map((s) => s.charAt(0).toUpperCase())
    .join("");

  return (
    <>
      <Avatar
        alt={`${u.name} profile picture`}
        src={u.avatar_url}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        sx={{
          border: "0.1px solid lightgray",
          width: `${size}px`,
          height: `${size}px`,
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {initials}
      </Avatar>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <UserMiniCard user={u} />
      </Popover>
    </>
  );
}

export default UserAvatar;

import * as React from "react";

import { Props } from "./AppPage";
import NavBar from "./NavBar";

function MinimalistPage({ children, secondaryHome }: Props) {
  return (
    <>
      <NavBar secondaryHome={secondaryHome} />
      {children}
    </>
  );
}

export default MinimalistPage;

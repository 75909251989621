import * as React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import ErrorDisplay from "../utils/ErrorDisplay";
import { FileUploaderState, UploadState } from "./useFileUploader";

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileUploaderState: FileUploaderState;
}

function UploadFileModal({
  isOpen,
  onClose,
  fileUploaderState,
}: UploadModalProps): JSX.Element {
  const { setFiles, upload, working, fileStates, error } = fileUploaderState;

  function handleClose(event: React.SyntheticEvent, reason?: string): void {
    // can't cancel upload
    if (working) {
      return;
    }
    // this modal shouldn't be carelessly dismissed
    if (reason && reason === "backdropClick") {
      return;
    }
    console.log("Closing modal");
    setFiles([]);
    onClose();
  }

  const uploadedFileCount = fileStates.filter(
    (fs) => fs.state === UploadState.DONE
  ).length;

  const isDone = uploadedFileCount === fileStates.length;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>File Upload</DialogTitle>
      <DialogContent>
        {!isDone && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              Please keep this page open until all uploads are complete.
            </Typography>
          </Box>
        )}
        {isDone && (
          <Alert severity={"success"} sx={{ mb: 2 }}>
            All files uploaded!
          </Alert>
        )}
        <ErrorDisplay error={error} />
        {fileStates.map((fs) => {
          let icon: JSX.Element | null = null;
          if (fs.state === UploadState.IDLE) {
            icon = <PendingIcon color="disabled" />;
          } else if (fs.state === UploadState.UPLOADING) {
            icon = error ? (
              <ErrorIcon color="error" />
            ) : (
              <CircularProgress size={18} />
            );
          } else {
            icon = <CheckCircleIcon color="success" />;
          }
          const name =
            isDone && !fs.projectId ? (
              <Link to={`/documents/${fs.id}`}>{fs.file.name}</Link>
            ) : (
              <Typography>{fs.file.name}</Typography>
            );

          return (
            <Box key={fs.id} sx={{ pb: 1 }}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                {name}
                {icon}
              </Stack>
              <LinearProgress variant="determinate" value={fs.progress} />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={working || isDone}>
          Cancel
        </Button>
        {isDone && <Button onClick={handleClose}>Done</Button>}
        {!isDone && (
          <Button onClick={upload} disabled={working}>
            Retry
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default UploadFileModal;

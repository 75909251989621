import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { LegalAnalyzer, RiskFactor } from "../../../../models/Models";
import EditRiskFactorDialog from "./EditRiskFactorDialog";
import RiskFactorCard from "./RiskFactorCard";

interface EditLegalAnalyzerProps {
  legalAnalyzer: LegalAnalyzer;
}

function EditLegalAnalyzer({
  legalAnalyzer,
}: EditLegalAnalyzerProps): JSX.Element {
  const [showRiskFactorModal, setShowRiskFactorModal] =
    useState<boolean>(false);
  const [selectedRiskFactorName, setSelectedRiskFactorName] = useState<
    string | null
  >(null);

  // always keep something selected
  useEffect(() => {
    if (!legalAnalyzer) {
      return;
    }

    const selected = legalAnalyzer?.risk_factors.find(
      (riskFactor) => riskFactor.name === selectedRiskFactorName
    );

    if (!selected && legalAnalyzer?.risk_factors.length > 0) {
      setSelectedRiskFactorName(legalAnalyzer?.risk_factors[0].name);
    }
  }, [legalAnalyzer.name, legalAnalyzer?.risk_factors.length]);

  const riskFactor = legalAnalyzer?.risk_factors.find(
    (riskFactor) => riskFactor.name === selectedRiskFactorName
  );

  return (
    <>
      <Alert severity={"info"} sx={{ maxWidth: "800px" }}>
        Tailor the AI&#39;s Legal Review Service to your exact needs. These
        configurations empower you to define granular legal document types and
        corresponding risk factors for each.
      </Alert>
      <Box sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Button
            variant={"outlined"}
            startIcon={<AddIcon />}
            fullWidth={true}
            onClick={() => setShowRiskFactorModal(true)}
          >
            Add Risk Factor
          </Button>
          <Box sx={{ mb: 3 }} />
          <Typography variant={"subtitle2"}>Risk Factors</Typography>
          {legalAnalyzer?.risk_factors.length === 0 && (
            <Typography
              variant={"subtitle1"}
              sx={{ mt: 3, fontSize: "10pt" }}
              color={"text.disabled"}
              align={"center"}
            >
              No Risk Factors
            </Typography>
          )}
          <List>
            {legalAnalyzer?.risk_factors.map((riskFactor) => (
              <ListItemButton
                key={riskFactor.name}
                selected={riskFactor.name === selectedRiskFactorName}
                onClick={() => setSelectedRiskFactorName(riskFactor.name)}
              >
                <ListItemText primary={riskFactor.name} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item xs={9}>
          {riskFactor && (
            <RiskFactorCard
              key={selectedRiskFactorName}
              legalAnalyzer={legalAnalyzer}
              riskFactor={riskFactor}
            />
          )}
        </Grid>
      </Grid>
      <EditRiskFactorDialog
        isAdd={true}
        riskFactor={{} as RiskFactor}
        open={showRiskFactorModal}
        onClose={() => setShowRiskFactorModal(false)}
        legalAnalyzer={legalAnalyzer}
      />
    </>
  );
}

export default EditLegalAnalyzer;

import { getFunctions, httpsCallable } from "firebase/functions";

import { functions } from "../configs/firebase";

export interface SendInviteEmailRequest {
  readonly org_id: string;
  readonly invite_id: string;
}

export async function sendInviteEmail(req: SendInviteEmailRequest) {
  const sendInviteEmail = httpsCallable(functions, "sendInviteEmail");
  const result = await sendInviteEmail(req);
  console.log(result.data);
}

import * as React from "react";

import { Box, Tooltip } from "@mui/material";
import { Timestamp } from "firebase/firestore";

import { dateToNiceTimeAgo } from "./Utils";

interface Props {
  readonly date: Date | Timestamp | string | undefined;
  readonly prefix?: string;
  readonly sx?: any;
}

function NiceDateAgo({ date, prefix, sx }: Props) {
  if (!date) {
    return <></>;
  }

  if (typeof date === "string") {
    date = new Date(date);
  } else if ("toDate" in date) {
    date = date.toDate();
  }

  return (
    <Tooltip title={date.toLocaleString()}>
      <Box sx={sx}>
        {prefix}
        {dateToNiceTimeAgo(date)}
      </Box>
    </Tooltip>
  );
}

export default NiceDateAgo;

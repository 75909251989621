import React from "react";

import { IFsModel } from "../../fs/FirestoreUtils";
import BaseTable from "./BaseTable";

interface MemTableProps<T> {
  items: T[];
  columns: any[];
  enableSelection: boolean;
  extraControls?: React.ReactNode;
  enableControls: boolean;
  onCellDoubleClick?: (row: T) => void;
  error?: Error | string | null;
  loading: boolean;
  pageSizeOptions?: number[];
}

function MemTable<T extends IFsModel>({
  items,
  columns,
  enableSelection,
  extraControls,
  enableControls,
  onCellDoubleClick,
  error,
  loading,
  pageSizeOptions = [5, 10, 25],
}: MemTableProps<T>): JSX.Element {
  return (
    <BaseTable<T>
      items={items}
      enableSelection={enableSelection}
      extraControls={extraControls}
      enableControls={enableControls}
      onCellDoubleClick={onCellDoubleClick}
      error={error}
      actions={[]}
      dataGridProps={{
        columns: columns,
        loading: loading,
        pageSizeOptions: pageSizeOptions,
      }}
    />
  );
}

export default MemTable;

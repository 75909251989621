import { IFsModel } from "../fs/FirestoreUtils";
import { ResourceType, UserId } from "./Models";

export interface OrgUser extends IFsModel<UserId> {
  readonly name: string;
  readonly email: string;
  readonly avatar_url: string;
  readonly favorites?: string[];
}

export type FavoriteId = string & { __brand: "favorite_id" };

export function createFavoriteId(
  id: string,
  resourceType: ResourceType
): FavoriteId {
  return `${resourceType}#${id}` as FavoriteId;
}

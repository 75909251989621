import React, { ReactNode, useState } from "react";

import { Box, Link, Modal, Typography } from "@mui/material";
import { getDownloadURL, ref } from "firebase/storage";

import QuestionAndResponseCard from "../../components/QuestionAndResponseCard";
import { storage } from "../../configs/firebase";
import {
  ChatAgentType,
  Citation,
  CitationSource,
  ProjectQuestion,
  StatusEnum,
} from "../../models/Models";

interface ProjectQuestionCardProps {
  readonly question: ProjectQuestion;
}

const ProjectQuestionCard: React.FC<ProjectQuestionCardProps> = ({
  question,
}) => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  const handleOpen = (uri: string) => {
    const thirdSlashIndex = uri.indexOf("/", 5);
    const storagePath = uri.substring(thirdSlashIndex + 1);

    const storageRef = ref(storage, storagePath);
    getDownloadURL(storageRef)
      .then((url) => {
        setModalContent(<iframe src={url} width="100%" height="600px" />);
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
        setModalContent("Error loading PDF");
        setOpen(true);
      });
  };

  const handleClose = () => setOpen(false);

  const addCitations = (
    text: string,
    citations: Citation[],
    citation_sources: CitationSource[]
  ): ReactNode[] => {
    if (!citations || citations.length === 0) {
      return [text];
    }

    const result: ReactNode[] = [];
    let lastIndex = 0;
    citations.forEach((citation, citationIndex) => {
      result.push(text.substring(lastIndex, citation.end_index));

      const citationLinks = citation.source_indices.map((sourceIndex) => (
        <Link
          key={`${citationIndex}_${sourceIndex}`}
          onClick={() => handleOpen(citation_sources[sourceIndex].uri)}
          style={{ cursor: "pointer" }}
        >
          &lt;citation&gt;
        </Link>
      ));

      result.push(...citationLinks);
      lastIndex = citation.end_index;
    });
    result.push(text.substring(lastIndex));

    return result;
  };

  const parsedCitations = addCitations(
    question.answer,
    question.citations,
    question.citation_sources
  );

  return (
    <>
      <QuestionAndResponseCard
        title="Question"
        agentType={ChatAgentType.DEFAULT}
        owner={question.owner}
        created_at={question.created_at}
        question={question.question}
        loading={
          question.status === StatusEnum.PENDING ||
          question.status === StatusEnum.PROCESSING
        }
        error={
          question.status === StatusEnum.ERROR
            ? new Error("Something went wrong.")
            : null
        }
        response_text={question.answer ?? ""}
        response={<Typography variant="body1">{parsedCitations}</Typography>}
        menuItems={[]}
      />

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            height: "66vh",
            width: "80vw",
          }}
        >
          <Typography variant="body1">{modalContent}</Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ProjectQuestionCard;

import React, { ReactNode, useContext } from "react";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { OrgContext } from "../../contexts/OrgContext";

interface OverrideUidBannerProps {
  children: ReactNode;
}

function OverrideUidBanner({ children }: OverrideUidBannerProps): JSX.Element {
  const { uid, isOverrideUid } = useContext(OrgContext);

  return (
    <>
      {isOverrideUid && (
        <Box
          sx={{
            backgroundColor: "red",
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            zIndex: 999999,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          Overriding UID: {uid} <Link to={window.location.pathname}>Clear</Link>
        </Box>
      )}
      {children}
    </>
  );
}

export default OverrideUidBanner;

import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import { GlobalSnackBarProvider } from "./components/GlobalSnackbarContext";
import AuthLoadBlocker from "./components/guards/AuthLoadBlocker";
import IsAdminGuard from "./components/guards/IsAdminGuard";
import OrgRequiredGuard from "./components/guards/OrgRequiredGuard";
import SignInGuard from "./components/signin/SignInGuard";
import VerifyEmailGuard from "./components/signin/VerifyEmailGuard";
import ErrorPage from "./components/utils/ErrorPage";
import { OrgProvider } from "./contexts/OrgContext";
import { OrgUserProfilesProvider } from "./contexts/OrgUserProfilesContext";
import "./main.css";
import AnalyzersPage from "./pages/./analyzers/AnalyzersPage";
import AcceptInvitePage from "./pages/AcceptInvitePage";
import HomePage from "./pages/HomePage";
import LogoutPage from "./pages/LogoutPage";
import SignInPage from "./pages/SignInPage";
import AdminPage from "./pages/admin/AdminPage";
import EditAnalyzerPage from "./pages/analyzers/edit/EditAnalyzerPage";
import ChatPageV2 from "./pages/chat/ChatPageV2";
import ConfigPage from "./pages/config/ConfigPage";
import AnalyzePage from "./pages/documents/analyze/AnalyzePage";
import DocumentListPage from "./pages/documents/list/DocumentListPage";
import OverrideUidBanner from "./pages/profile/OverrideUidBanner";
import ProfilePage from "./pages/profile/ProfilePage";
import { CurrentProjectProvider } from "./pages/projects/CurrentProjectContext";
import ProjectListPage from "./pages/projects/ProjectListPage";
import ProjectPage from "./pages/projects/ProjectPage";
import ProjectAIPage from "./pages/projects/ai/ProjectAIPage";
import ProjectSettingsPage from "./pages/projects/settings/ProjectSettingsPage";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <GlobalSnackBarProvider>
          <AuthLoadBlocker>
            <OrgProvider>
              <OrgUserProfilesProvider>
                <OverrideUidBanner>
                  <Routes>
                    <Route path="/login" element={<SignInPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route
                      path="/accept-invitation/:orgId"
                      element={
                        <SignInGuard>
                          <VerifyEmailGuard>
                            <AcceptInvitePage />
                          </VerifyEmailGuard>
                        </SignInGuard>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <SignInGuard>
                          <ProfilePage />
                        </SignInGuard>
                      }
                    />
                    <Route
                      element={
                        <SignInGuard>
                          <VerifyEmailGuard>
                            <OrgRequiredGuard>
                              <Outlet />
                            </OrgRequiredGuard>
                          </VerifyEmailGuard>
                        </SignInGuard>
                      }
                    >
                      <Route path="/" element={<HomePage />} />
                      <Route path="/config" element={<ConfigPage />} />
                      <Route path="/chat" element={<ChatPageV2 />} />
                      <Route path="/chat/:sessionId" element={<ChatPageV2 />} />
                      <Route path="/documents" element={<DocumentListPage />} />
                      <Route path="/analyzers" element={<AnalyzersPage />} />
                      <Route
                        path="/analyzers/:configId"
                        element={<EditAnalyzerPage />}
                      />
                      <Route
                        path="/documents/:docId"
                        element={<AnalyzePage />}
                      />
                      <Route path="/projects" element={<ProjectListPage />} />
                      <Route
                        path="/projects/:projectId"
                        element={
                          <CurrentProjectProvider>
                            <Outlet />
                          </CurrentProjectProvider>
                        }
                      >
                        <Route key="base" path="" element={<ProjectPage />} />
                        <Route
                          key="settings"
                          path="settings"
                          element={<ProjectSettingsPage />}
                        />
                        <Route key="ai" path="ai" element={<ProjectAIPage />} />
                        ,
                      </Route>

                      <Route
                        path="/admin"
                        element={
                          <IsAdminGuard>
                            <AdminPage />
                          </IsAdminGuard>
                        }
                      />
                    </Route>
                    <Route
                      path="*"
                      element={
                        <ErrorPage
                          title={"Page Not Found"}
                          body={"This page cannot be found."}
                        />
                      }
                    />
                  </Routes>
                </OverrideUidBanner>
              </OrgUserProfilesProvider>
            </OrgProvider>
          </AuthLoadBlocker>
        </GlobalSnackBarProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React, { useContext, useState } from "react";

import GppBadIcon from "@mui/icons-material/GppBad";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { arrayRemove, arrayUnion } from "firebase/firestore";

import ErrorDisplay from "../../../../components/utils/ErrorDisplay";
import { OrgContext } from "../../../../contexts/OrgContext";
import { organization } from "../../../../fs/FirestoreUtils";
import { LegalAnalyzer, RiskFactor } from "../../../../models/Models";

interface EditRiskFactorDialogProps {
  open: boolean;
  onClose: () => void;
  isAdd: boolean;
  riskFactor: RiskFactor;
  legalAnalyzer: LegalAnalyzer;
}

function EditRiskFactorDialog({
  open,
  onClose,
  isAdd,
  riskFactor,
  legalAnalyzer,
}: EditRiskFactorDialogProps): JSX.Element {
  const { selectedOrg } = useContext(OrgContext);
  const [editedRiskFactor, setEditedRiskFactor] = useState<RiskFactor>({
    ...riskFactor,
  });
  const [error, setError] = useState<Error | null>(null);
  const [working, setWorking] = useState<boolean>(false);

  async function handleSubmit() {
    if (working) {
      return;
    }

    if (isAdd) {
      const existingRiskFactor = legalAnalyzer.risk_factors.find(
        (rf) => rf.name === editedRiskFactor.name
      );
      if (existingRiskFactor) {
        setError(new Error("Risk Factor with name already exists"));
        return;
      }
    }

    setError(null);
    setWorking(true);

    try {
      const docRef = organization(selectedOrg).analyzer(legalAnalyzer.id);

      if (!isAdd) {
        // Remove the old risk factor from the array
        await docRef.update<LegalAnalyzer>({
          risk_factors: arrayRemove(riskFactor),
        });
      }
      // Add the updated risk factor to the array
      await docRef.update<LegalAnalyzer>({
        risk_factors: arrayUnion(editedRiskFactor),
      });

      if (isAdd) {
        // reset the form to clear the fields
        setEditedRiskFactor(riskFactor);
      }

      onClose();
    } catch (e) {
      setError(e as Error);
    } finally {
      setWorking(false);
    }
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="span">
            {isAdd ? "Add Risk Factor" : "Edit Risk Factor"}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          noValidate
          onSubmit={async (event) => {
            event.preventDefault();
            await handleSubmit();
          }}
          autoComplete="off"
        >
          <TextField
            label="Name"
            disabled={working}
            required={true}
            value={editedRiskFactor.name ?? ""}
            onChange={(e) =>
              setEditedRiskFactor({
                ...editedRiskFactor,
                name: e.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <Stack
            spacing={1}
            alignItems={"center"}
            direction={"row"}
            sx={{ color: "info.main" }}
          >
            <PrivacyTipIcon sx={{ fontSize: 18 }} />
            <Typography variant={"overline"} component={"div"}>
              Low Severity
            </Typography>
          </Stack>
          <TextField
            label="Low Severity If"
            disabled={working}
            required={true}
            value={editedRiskFactor.low_severity_if ?? ""}
            onChange={(e) =>
              setEditedRiskFactor({
                ...editedRiskFactor,
                low_severity_if: e.target.value,
              })
            }
            fullWidth
            margin="normal"
            multiline
          />
          <Stack
            spacing={1}
            alignItems={"center"}
            direction={"row"}
            sx={{ color: "warning.text" }}
          >
            <GppMaybeIcon sx={{ fontSize: 18 }} />
            <Typography variant={"overline"} component={"div"}>
              Medium Severity
            </Typography>
          </Stack>
          <TextField
            label="Medium Severity If"
            required={true}
            disabled={working}
            value={editedRiskFactor.medium_severity_if ?? ""}
            onChange={(e) =>
              setEditedRiskFactor({
                ...editedRiskFactor,
                medium_severity_if: e.target.value,
              })
            }
            fullWidth
            margin="normal"
            multiline
          />
          <Stack
            spacing={1}
            alignItems={"center"}
            direction={"row"}
            sx={{ color: "error.text" }}
          >
            <GppBadIcon sx={{ fontSize: 18 }} />
            <Typography variant={"overline"} component={"div"}>
              High Severity
            </Typography>
          </Stack>
          <TextField
            label="High Severity If"
            required={true}
            disabled={working}
            value={editedRiskFactor.high_severity_if ?? ""}
            onChange={(e) =>
              setEditedRiskFactor({
                ...editedRiskFactor,
                high_severity_if: e.target.value,
              })
            }
            fullWidth
            margin="normal"
            multiline
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSubmit} color="primary">
          {isAdd ? "Add" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditRiskFactorDialog;

import { useEffect, useState } from "react";

export type WorkloadRunner = (fn: () => Promise<void>) => void;

export function useWorkload(
  deps: any[]
): [boolean, Error | null, WorkloadRunner] {
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setError(null);
    setIsWorking(false);
  }, deps);

  async function handleApiRequest(fn: () => Promise<void>): Promise<void> {
    setIsWorking(true);
    setError(null);

    try {
      await fn();
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsWorking(false);
    }
  }

  return [isWorking, error, handleApiRequest];
}
